import { futura } from '../fonts/futura.js';
import { noto } from '../fonts/notoRegular.js';
import * as Excel from 'exceljs';
import pdf from 'pdfmake/build/pdfmake';

import { downloadExcelFile, PAPER_SIZES, setupWorkSheet } from '.';
import { transformOrderLineToSections } from '../ifs';

//* PDFMake setup
pdf.vfs = { 'futura.otf': futura, 'noto.otf': noto };
/** Include if you want to specify your own fonts
 * https://pdfmake.github.io/docs/0.1/fonts/custom-fonts-client-side/vfs/
 */
pdf.fonts = {
  futura: {
    normal: 'futura.otf',
    bold: 'futura.otf',
  },
  chinese: {
    normal: 'noto.otf',
    bold: 'noto.otf',
  },
};

const COLUMN_DEFAULTS = {
  // First row is for the order header, while 2nd row is for the line headers (Factory/Sales)
  ORDER_LINE_HEADER_ROW: 2,

  // Column default widths
  KEY_COLUMN: {
    WIDTH: 10,
    ALLOWANCE: 1.15,
  },
  VALUE_COLUMN: {
    WIDTH: 10,
    ALLOWANCE: 1.05,
  },
};

export function generateUniqueWorksheetName(name, existingWorksheetsNames) {
  const formattedName = name.replace(/[/\\?%*:|"<>]/g, '');
  const truncatedName =
    formattedName.length > 25
      ? formattedName.slice(0, 25) + '...'
      : formattedName;
  let uniqueName = truncatedName,
    count = 1;
  while (existingWorksheetsNames.includes(uniqueName)) {
    uniqueName = truncatedName + count;
    count += 1;
  }

  existingWorksheetsNames.push(uniqueName);
  return uniqueName;
}

export function addWorkSheet(workbook, catalogDescr, existingWorksheetsNames) {
  const uniqueName = generateUniqueWorksheetName(
    catalogDescr,
    existingWorksheetsNames
  );
  const sheet = workbook.addWorksheet(uniqueName);
  return sheet;
}

export const exportOrderToExcel = async (order) => {
  const workbook = new Excel.Workbook();

  const orderNumber = order?.ORDER_NO;
  const orderLines = order?.ORDER_LINES;

  const existingWorksheetsNames = [];
  orderLines.forEach((orderLine, index) => {
    const catalogDescr =
      orderLine?.FACTORY?.CATALOG_DESC ||
      orderLine?.SALES?.CATALOG_DESC ||
      `order_line_${index + 1}`;

    const sheet = addWorkSheet(workbook, catalogDescr, existingWorksheetsNames);

    generateOrderHeader(sheet, catalogDescr);

    const entries = Object.entries(orderLine);
    const getNextLetter = (letter, offset = 1) =>
      String.fromCharCode(letter.charCodeAt(0) + offset);
    for (let i = 0, startColumn = 'A'; i < entries.length; i++) {
      const [key, value] = entries[i];

      let nextColumn = getNextLetter(startColumn);
      const config = {
        START_COLUMN: startColumn,
        END_COLUMN: nextColumn,
        TITLE: key,
      };

      plotData(config, value, sheet);
      startColumn = getNextLetter(nextColumn, 2);
    }

    setupWorkSheet(sheet, {
      paperSize: PAPER_SIZES.A4,
      fitToPage: true,
      numOfPagesInWidth: 1,
      numOfPagesInHeight: 1,
    });
  });

  const filename = `order-no-${orderNumber}.xlsx`;

  return downloadExcelFile(filename, workbook);
};

const customSortForOrderLine = (orderLine) => {
  if (!orderLine) return;

  return transformOrderLineToSections(orderLine).flat();
};

const plotData = (config, line, sheet) => {
  const { START_COLUMN: keyColumn, END_COLUMN: valueColumn } = config;
  generateOrderLineHeader(sheet, config);

  const data = customSortForOrderLine(line);

  const { ORDER_LINE_HEADER_ROW: HEADER_ROW_NUMBER } = COLUMN_DEFAULTS;

  const columnWidths = {
    keyColumn: COLUMN_DEFAULTS.KEY_COLUMN.WIDTH,
    valueColumn: COLUMN_DEFAULTS.VALUE_COLUMN.WIDTH,
  };

  Object.entries(data).forEach(([rowIndex, [key, value]]) => {
    // Adding 1 because excel sheet count starts at 1.
    const excelRowNumber = parseInt(rowIndex) + 1;
    // Adding HEADER_ROW_NUMBER to allot row 1 for the header.
    const currentRowNumber = excelRowNumber + HEADER_ROW_NUMBER;

    // Plotting of data
    sheet.getCell(`${keyColumn}${currentRowNumber}`).value = key;
    sheet.getCell(`${valueColumn}${currentRowNumber}`).value = value || '-';

    getMaxColumnWidths(key, value || '-', columnWidths);
  });

  adjustColumnWidths({ sheet, widths: columnWidths, keyColumn, valueColumn });
};

const generateOrderHeader = (sheet, catalogDescr) => {
  const cell = sheet.getCell('A1');
  cell.value = catalogDescr;
  cell.style = {
    font: {
      size: 14,
      bold: true,
    },
  };
  cell.alignment = {
    vertical: 'middle',
    horizontal: 'left',
  };

  sheet.getRow(1).height = 33;
};

const generateOrderLineHeader = (sheet, config) => {
  const { ORDER_LINE_HEADER_ROW: HEADER_ROW_NUMBER } = COLUMN_DEFAULTS;

  const headerColumnStart = `${config.START_COLUMN}${HEADER_ROW_NUMBER}`;
  const headerColumnEnd = `${config.END_COLUMN}${HEADER_ROW_NUMBER}`;

  sheet.mergeCells(`${headerColumnStart}:${headerColumnEnd}`);
  sheet.getCell(`${headerColumnEnd}`).value = config.TITLE;
  sheet.getCell(`${headerColumnEnd}`).font = {
    bold: true,
  };
};

/**
 * NOTE: exceljs sets the column width by number of characters.
 */
const getMaxColumnWidths = (currentKey, currentValue, currentWidths) => {
  const { keyColumn: keyCellWidth, valueColumn: valueCellWidth } =
    currentWidths;

  // Adding allowances because uppercase letters take more space. And the sheet seem to base it with lowercase letters.
  const KEY_COLUMN_ALLOWANCE = COLUMN_DEFAULTS.KEY_COLUMN.ALLOWANCE;
  const VALUE_COLUMN_ALLOWANCE = COLUMN_DEFAULTS.VALUE_COLUMN.ALLOWANCE;

  const keyLength = currentKey.length * KEY_COLUMN_ALLOWANCE;
  const valueLength = currentValue.length * VALUE_COLUMN_ALLOWANCE;

  if (keyLength > keyCellWidth) currentWidths.keyColumn = keyLength;
  if (valueLength > valueCellWidth) currentWidths.valueColumn = valueLength;
};

const adjustColumnWidths = ({ sheet, widths, keyColumn, valueColumn }) => {
  // Setting column widths
  sheet.getColumn(keyColumn).width = widths.keyColumn;
  sheet.getColumn(valueColumn).width = widths.valueColumn;
};

export function createThePDF(order) {
  const pdfContent = {
    content: [createPDFContent(order)],
    /** Include to specify custom font (see line 10 as well) */
    defaultStyle: {
      font: 'futura',
    },
    header: headerAndFooterText(order),
    footer: headerAndFooterText(order),
  };
  return pdfContent;
}

function headerAndFooterText(order) {
  const text = {
    text: `Order lines for order: ${order.ORDER_NO}\t\t\tOrder lines for order: ${order.ORDER_NO}\t\t\tOrder lines for order: ${order.ORDER_NO}`,
    alignment: 'center',
    color: 'gray',
  };
  return text;
}

function createPDFContent(order) {
  const theContent = [];
  for (let i = 0; i < order.ORDER_LINES.length; i++) {
    const ol = order.ORDER_LINES[i];
    const pB = i === order.ORDER_LINES.length - 1 ? 'none' : 'after';
    theContent.push({
      text: ol.SALES?.CATALOG_DESC || ol.FACTORY?.CATALOG_DESC,
      fontSize: 20,
      bold: true,
    });
    const content = {
      style: 'tableExample',
      pageBreak: pB,
      table: {
        widths: [125, 125, 125, 125],
        body: [
          [
            { text: 'Factory', fontSize: 12, bold: true },
            { text: 'Values', fontSize: 12, bold: true },
            { text: 'Sales', fontSize: 12, bold: true },
            { text: 'Values', fontSize: 12, bold: true },
          ],
          ...createPDFRows(order.ORDER_LINES[i]),
        ],
      },
      layout: {
        fillColor: function (rowIndex) {
          return rowIndex % 2 === 0 ? '#f2f2f2' : null;
        },
        hLineColor: function (i, node) {
          const sectionEnd = [8, 16, 24, 29, 37, 42, 47];
          if (sectionEnd.includes(i)) return '#242424';

          return i === 0 || i === node.table.body.length || i === 1
            ? 'black'
            : 'white';
        },
        hLineWidth: function (i, node) {
          return i === 0 || i === 1 || i === node.table.body.length ? 2 : 1;
        },
        vLineColor: function (i) {
          return i === 2 ? 'white' : '';
        },
        vLineWidth: function (i) {
          return i === 2 ? 10 : 0;
        },
      },
    };
    theContent.push(content);
  }
  return theContent;
}

function createPDFRows(line) {
  const pdfRows = [];

  const noTable = { NO_TABLE: 'No table available' };

  const factoryEntries = line.FACTORY
    ? transformOrderLineToSections(line.FACTORY).flat()
    : Object.entries(noTable);
  const salesEntries = line.SALES
    ? transformOrderLineToSections(line.SALES).flat()
    : Object.entries(noTable);
  const rowCount = Math.max(salesEntries.length, factoryEntries.length);

  for (let i = 0; i < rowCount; i++) {
    const factoryEntry = factoryEntries?.[i];
    const factoryKey = factoryEntry?.[0];
    const factoryValue = factoryEntry?.[1] ?? noTable[factoryKey];

    const salesEntry = salesEntries?.[i];
    const salesKey = salesEntry?.[0];
    const salesValue = salesEntry?.[1] ?? noTable[salesKey];

    const text = [
      { text: factoryKey || '', bold: true, fontSize: 8 },
      {
        text: factoryValue || '-',
        fontSize: 8,
        font: detectFont(factoryValue),
      },
      { text: salesKey || '', bold: true, fontSize: 8 },
      { text: salesValue || '-', fontSize: 8, font: detectFont(salesValue) },
    ];
    pdfRows.push(text);
  }

  return pdfRows;
}

export function detectFont(string) {
  if (typeof string === 'string' && string?.match(/[\u3400-\u9FBF]/))
    return 'chinese';
  return 'futura';
}

export function savePDF(docDefinition, orderNum) {
  pdf.createPdf(docDefinition).download(`Order-${orderNum}.pdf`); //INSTANT DOWNLOAD
}
