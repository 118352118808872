<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.027"
    height="17.027"
    viewBox="0 0 17.027 17.027"
    fill="currentColor">
    <path
      d="M16.228,0H.8A.8.8,0,0,0,.235,1.362l6.15,6.151v6.853a.8.8,0,0,0,.34.654l2.66,1.862a.8.8,0,0,0,1.256-.654V7.514l6.15-6.151A.8.8,0,0,0,16.228,0Z" />
  </svg>
</template>

<script>
export default {
  name: 'FilterIcon',
};
</script>
