<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none">
    <path
      d="M17.9121 6.48984L16.1332 1.15312C15.9047 0.464062 15.2613 0 14.5336 0H9.5625V6.75H17.9543C17.9402 6.66211 17.9402 6.57422 17.9121 6.48984ZM8.4375 0H3.46641C2.73867 0 2.09531 0.464062 1.8668 1.15312L0.0878906 6.48984C0.0597656 6.57422 0.0597656 6.66211 0.0457031 6.75H8.4375V0ZM0 7.875V16.3125C0 17.2441 0.755859 18 1.6875 18H16.3125C17.2441 18 18 17.2441 18 16.3125V7.875H0Z" />
  </svg>
</template>
