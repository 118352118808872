<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="13"
    viewBox="0 0 12 12"
    fill="none">
    <path
      d="M6 0.500074C13.3333 0.630424 13.3333 11.3696 6 11.4999C-1.33333 11.3696 -1.33333 0.630424 6 0.500074Z"
      :stroke="circleStrokeColor"
      :fill="bgColor"
      stroke-miterlimit="10" />
    <path
      d="M5.99999 4.50667C6.41973 4.50667 6.75999 4.16939 6.75999 3.75333C6.75999 3.33728 6.41973 3 5.99999 3C5.58025 3 5.23999 3.33728 5.23999 3.75333C5.23999 4.16939 5.58025 4.50667 5.99999 4.50667Z"
      :stroke="txtStrokeColor"
      :fill="txtColor" />
    <path
      d="M6.95331 8.99977H5.05331C4.73998 8.99977 4.47998 8.74643 4.47998 8.43977C4.47998 8.1331 4.73331 7.87977 5.05331 7.87977H5.43331V6.37977H5.23998C4.92665 6.37977 4.66665 6.12643 4.66665 5.81977C4.66665 5.5131 4.92665 5.25977 5.23998 5.25977H5.99998C6.31331 5.25977 6.57331 5.5131 6.57331 5.81977V7.87977H6.95331C7.26665 7.87977 7.52665 8.1331 7.52665 8.43977C7.52665 8.74643 7.27331 8.99977 6.95331 8.99977Z"
      :stroke="txtStrokeColor"
      :fill="txtColor" />
  </svg>
</template>

<script>
export default {
  name: 'InformationIcon',
  props: {
    circleStrokeColor: {
      type: String,
      default: 'white',
    },
    txtStrokeColor: {
      type: String,
      default: 'unset',
    },
    bgColor: {
      type: String,
      default: 'unset',
    },
    txtColor: {
      type: String,
      default: 'white',
    },
  },
};
</script>
