import { createRouter, createWebHistory } from 'vue-router';

import { useOrdersStore } from '@/store/ordersStore';
import { useUserStore } from '@/store/userStore';
import { useStatisticsStore } from '@/store/statisticsStore';
import { useUiStore } from '@/store/uiStore';
import { formatDate, dateIntervals } from '@/utils/datetime';
import { waitForElement } from '@/utils/topbar';
import { DELAYED_TAB_STATUS, ORDER_STATUS } from '@/utils/orders';
import { clearEmptyProperties } from '@/utils/utils';
import {
  deleteQueryParam,
  replaceQueryParam,
  setQueryParams,
} from '@/utils/router-utils';
import { useCustomersStore } from '../store/customers/customersStore';
import { mobileMediaWatcher } from '../utils/mediaQueryPlugin';

export const ROUTE_NAMES = {
  DASHBOARD: 'Dashboard',
  ALL_ORDERS: 'My orders',
  DELAYED: 'Delayed',
  ON_TIME: 'On Time',
  DELIVERED: 'Delivered',
  CANCELLED: 'Cancelled',
  FLAGGED: 'Flagged',
  MONITORED: 'Monitored',
  ARCHIVED: 'Archived',
  CUSTOMERS: 'Customers',
  CUSTOMER_DASHBOARD: 'Customer dashboard',
};

export const INTERNAL_USER_ALL_ORDERS_LABEL = 'All orders';

const Dashboard = () =>
  import(
    /* webpackChunkName: "js/dashboard", webpackPrefetch: true */ '@/views/Dashboard.vue'
  );

const Customers = () =>
  import(
    /* webpackChunkName: "js/customers", webpackPrefetch: true */ '@/views/Customers.vue'
  );

const CustomerDashboard = () =>
  import(
    /* webpackChunkName: "js/customers", webpackPrefetch: true */ '@/views/CustomerDashboard.vue'
  );

const Orders = () =>
  import(
    /* webpackChunkName: "js/orders", webpackPrefetch: true */ '@/views/Orders.vue'
  );
const Monitored = () =>
  import(
    /* webpackChunkName: "js/monitored", webpackPrefetch: true */ '@/views/Monitored.vue'
  );
const ArchivedOrders = () =>
  import(
    /* webpackChunkName: "js/archived", webpackPrefetch: true */ '@/views/ArchivedOrders.vue'
  );

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
  },
  {
    path: '/dashboard',
    name: ROUTE_NAMES.DASHBOARD,
    component: Dashboard,
    beforeEnter: [redirectExternalUsers, closeFilterState],
  },
  {
    path: '/customers',
    name: ROUTE_NAMES.CUSTOMERS,
    component: Customers,
    beforeEnter: [redirectExternalUsers, closeFilterState],
  },
  {
    path: '/customers/:customerNo',
    name: ROUTE_NAMES.CUSTOMER_DASHBOARD,
    component: CustomerDashboard,
    beforeEnter: [redirectExternalUsers, closeFilterState],
    props: { customer: true },
  },
  {
    path: '/orders',
    name: ROUTE_NAMES.ALL_ORDERS,
    component: Orders,
    props: { title: 'My orders', status: undefined, flagged: undefined },
  },

  {
    path: '/flagged',
    name: ROUTE_NAMES.FLAGGED,
    component: Orders,
    props: { title: 'Flagged orders', status: undefined, flagged: true },
  },
  {
    path: '/monitored',
    name: ROUTE_NAMES.MONITORED,
    component: Monitored,
  },
  {
    path: '/archived',
    name: ROUTE_NAMES.ARCHIVED,
    component: ArchivedOrders,
    props: {
      title: 'Archived orders',
    },
  },
];

async function redirectExternalUsers() {
  const userStore = useUserStore();

  const isUserLoaded = Object.entries(userStore.user).length;
  if (!isUserLoaded) {
    await userStore.setUser();
  }

  if (userStore.isExternal) {
    return '/orders';
  }
}

async function closeFilterState() {
  const uiStore = useUiStore();
  uiStore.filtersOpen = false;
}

async function removeQueryParams(to) {
  if (Object.keys(to.query).length) {
    to.query = {};
    to.fullPath = to.fullPath.replace(/\?.+/, '');
  }
  return true;
}

export const pathQueryMap = {
  Dashboard: {},
  Orders: {},
  Delayed: { status: DELAYED_TAB_STATUS },
  'On Time': { status: ORDER_STATUS.ON_TIME },
  Delivered: { status: ORDER_STATUS.DELIVERED },
  Cancelled: { status: ORDER_STATUS.CANCELLED },
  Flagged: { flagged: true },
  Monitored: {},
  Archive: { archive: true },
  undefined: {},
};

const isMobile = mobileMediaWatcher.matches;
async function orderHandler(to) {
  // Always reset the pagination to 1 on mobile for infinite loading
  if (isMobile && to.query?.page > 1) {
    replaceQueryParam(to, 'page', 1);
  }

  const ordersStore = useOrdersStore();

  deleteQueryParam(to, 'personal', 'openCommentOnLoad');

  // If dateInterval exists in the query param update the dateFrom and dateTo query params
  if (to.query?.dateInterval) {
    const dateFrom = dateIntervals.find(
      (interval) => interval.key === to.query.dateInterval
    ).value;
    const dateTo = formatDate(new Date(), 'YYYY-MMo-DDo');

    replaceQueryParam(to, 'dateFrom', dateFrom);
    replaceQueryParam(to, 'dateTo', dateTo);
  }

  const queryParams = {
    page: 1,
    ...to.query,
    ...pathQueryMap[to.name],
  };

  fixDelayedTabStatusHandling(to, queryParams);

  // Catches the noFetch query parameter to skip fetching of new orders
  if (to.query?.noFetch) {
    deleteQueryParam(to, 'noFetch');
    return;
  }

  ordersStore.fillOrders(queryParams);
}

/**
 * Status query param in Delayed tab is being overwritten by pathQueryMap
 */
function fixDelayedTabStatusHandling(to, params) {
  if (to.name === ROUTE_NAMES.DELAYED) {
    const isValidStatusForDelayedTab =
      to.query?.status &&
      [ORDER_STATUS.ATTENTION, ORDER_STATUS.DELAYED].includes(to.query.status);

    params.status = isValidStatusForDelayedTab
      ? to.query.status
      : DELAYED_TAB_STATUS;
  }
}

async function monitoredOrdersHandler(to) {
  const ordersStore = useOrdersStore();

  const query = to.query || {};
  setQueryParams(to, {
    sortKey: query.sortKey,
    sortOrder: query.sortOrder,
    search: query.search,
  });

  ordersStore.fillMonitoredOrders(to.query);
  return;
}

async function dashboardHandler(to) {
  const statsStore = useStatisticsStore();
  const ordersStore = useOrdersStore();

  const personal =
    to.query?.personal === 'true' || statsStore.isPersonalStatistics;
  await removeQueryParams(to);
  statsStore.isPersonalStatistics = personal;
  replaceQueryParam(to, 'personal', personal);

  if (!statsStore.isFinishInitialize) {
    statsStore.init();
  }

  if (!ordersStore.isFinishedInitialize) {
    ordersStore.fillOrders();
  }
}

async function customerHandler(to) {
  const customerStore = useCustomersStore();
  const query = to.query;

  if (isMobile && query?.page > 1 && !query.noFetch) {
    replaceQueryParam(to, 'page', 1);
  }

  // Note: no need to add await since we have a loading state
  customerStore.fillCustomers(query, query.noFetch);

  deleteQueryParam(to, 'type', 'getOptionValues', 'noFetch');
}

async function customerDashboardHandler(to) {
  const ordersStore = useOrdersStore();
  const orderParams = { customers: to.params.customerNo };
  const customersStore = useCustomersStore();
  const customerParams = {
    CUSTOMER_NO: to.params.customerNo,
    includeOrderCounts: true,
  };

  Promise.all([
    ordersStore.fillOrders(orderParams),
    customersStore.getSingleCustomer(customerParams, true),
  ]);
}

async function archivedOrdersHandler(to) {
  // Always reset the pagination to 1 on mobile for infinite loading
  if (isMobile && to.query?.page > 1) {
    replaceQueryParam(to, 'page', 1);
  }

  const { user, setUser } = useUserStore();
  if (Object.keys(user) <= 0) {
    await setUser();
  }

  const { fillOrders } = useOrdersStore();

  const query = to.query || {};

  // We won't add filter params since we do not have the filter feature for Archived orders
  const archivedQueryParams = [
    'sortKey',
    'sortOrder',
    'search',
    'page',
    'show',
    'noFetch',
  ];

  if (query.includeCustomer) {
    archivedQueryParams.push(...['customers', 'includeCustomer']);
  }

  const newQueryParams = {};
  archivedQueryParams.forEach((param) => {
    newQueryParams[param] = query[param];
  });

  setQueryParams(to, newQueryParams);

  // Catches the noFetch query parameter to skip fetching of new orders used for mobile load more
  if (to.query?.noFetch) {
    deleteQueryParam(to, 'noFetch');
    return;
  }

  fillOrders({
    ...newQueryParams,
    archive: true,
    includeCustomer: undefined,
  });
}

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Navigational guard to keep query between status pages
router.beforeEach(async (to, from) => {
  //Ensures that topbar is hydrated before doing any requests
  await waitForElement('mymunters-topbar');

  const hasQueryParams = (route) => {
    const cleanedQuery = clearEmptyProperties(route.query);
    return Object.keys(cleanedQuery).length;
  };
  if (to.path !== from.path && !hasQueryParams(to) && hasQueryParams(from)) {
    to.query = from.query;
  }

  // Get the page title from the route meta data that we have defined
  let title = to.name;
  // If the route has a title, set it as the page title of the document/page
  if (title) {
    document.title = `${title} - ETA | My Munters`;
  } else {
    document.title = `ETA | My Munters`;
  }

  const pathName = to.name;
  switch (pathName) {
    case ROUTE_NAMES.ALL_ORDERS:
    case ROUTE_NAMES.DELAYED:
    case ROUTE_NAMES.ON_TIME:
    case ROUTE_NAMES.DELIVERED:
    case ROUTE_NAMES.CANCELLED:
    case ROUTE_NAMES.FLAGGED:
      return await orderHandler(to);
    case ROUTE_NAMES.MONITORED:
      return await monitoredOrdersHandler(to);
    case ROUTE_NAMES.DASHBOARD:
      return await dashboardHandler(to);
    case ROUTE_NAMES.ARCHIVED:
      return await archivedOrdersHandler(to);
    case ROUTE_NAMES.CUSTOMERS:
      return await customerHandler(to);
    case ROUTE_NAMES.CUSTOMER_DASHBOARD:
      return await customerDashboardHandler(to);
    default:
      return;
  }
});

router.afterEach(async () => {
  // Rerender the navmenu highlights
  const navmenu = document.querySelector('mymunters-navigation-menu');
  await navmenu.applyHighlight();
});

export default router;
