<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template>
  <mm-modal
    ref="ifsModal"
    :header-text="`Order ${order?.ORDER_NO}`"
    max-width="1000px"
    prevent-body-scroll
    close-on-esc-key
    use-custom-buttons
    @cancel-clicked="$emit('onClose')">
    <div :key="getOrderNumber" slot="content">
      <IFSOrderLineList :order="order" />
    </div>
    <div slot="custom-buttons" class="ifs-modal-buttons">
      <IFSOrderDownload :order="order" />

      <mymunters-button class="ml-auto" @click="close">
        Close
      </mymunters-button>
    </div>
  </mm-modal>
</template>

<script>
import { defineComponent } from 'vue';
import IFSOrderLineList from '@/components/Orders/OrdersSummary/IFSOrder/IFSOrderLineList.vue';
import IFSOrderDownload from '@/components/Orders/OrdersSummary/IFSOrder/IFSOrderDownload.vue';

export default defineComponent({
  name: 'IFSModal',
  components: { IFSOrderDownload, IFSOrderLineList },
  emits: ['onClose'],
  data() {
    return {
      order: {},
    };
  },
  computed: {
    getModal() {
      return this.$refs?.ifsModal;
    },
    getOrderNumber() {
      return this.order?.ORDER_NO;
    },
  },
  methods: {
    open(order) {
      if (!order) throw new Error('Order is required!');

      this.order = order;
      this.getModal.open();
    },
    close() {
      this.getModal.close();
      this.order = {};
    },
  },
});
</script>

<style lang="scss" scoped>
mm-modal {
  &::part(header) {
    font-size: 24px;
    color: #1a1a1a;
    font-weight: 450;
    line-height: 32px;
  }

  &::part(content) {
    padding: 0;
  }
}

.ifs-modal-buttons {
  display: flex;
  width: 100%;
}
</style>

<!-- Global stylings for the mymuinters-button since component does not use shadow dom  -->
<style lang="scss">
.ifs-modal-buttons {
  mymunters-button button {
    padding: 0 1rem;
  }

  mymunters-button.ml-auto {
    margin-left: auto;
  }
}
</style>
