import { fetchJSON } from '.';
import { clearEmptyProperties, isEmptyObject, range } from '../utils/utils';
import { inMemoryCache } from '@/utils/cacheing/inMemoryCache.js';

export const DATE_TYPES = [
  { title: 'Date entered', value: 'DATE_ENTERED' },
  { title: 'Last updated', value: 'LAST_UPDATED' },
  { title: 'Desired delivery', value: 'WANTED_DELIVERY_DATE' },
  { title: 'Factory ETA', value: 'PLANNED_DELIVERY_DATE' },
  { title: 'Courier ETA', value: 'VCHAIN_DELIVERY_DATE' },
  { title: 'Planned shipped date', value: 'PLANNED_SHIP_DATE' },
];

// We want to cache order page 1 without freetext searches or filters selected
// mainly because its the most popular request and its sometimes sent twice on page load
// we don't cache all responses because it could become a memory issue
function isCachable(params) {
  const isPageOne = params.page === '1' || params.page === 1;
  if (!params || !isPageOne || params.search) return false;
  return Object.keys(params).length < 5;
}

export async function getOrders(params) {
  const url = new URL(process.env.VUE_APP_API_URL + '/orders');
  if (params) {
    params = clearEmptyProperties(params);
    if (!isEmptyObject(params)) url.search = new URLSearchParams(params);
  }

  const cachable = isCachable(params);
  const cacheKey = url.toString();
  const cacheValue = inMemoryCache.get(cacheKey);

  if (cachable && cacheValue) {
    return cacheValue;
  }

  try {
    const result = await fetchJSON(url, 'get');

    if (cachable) inMemoryCache.set(cacheKey, result, 120); // 2 minutes

    return result;
  } catch (ex) {
    console.error(ex.message);
  }

  return { orders: [], hits: {} };
}
export async function getCustomerArchive(params) {
  const url = new URL(process.env.VUE_APP_API_URL + '/orders');
  if (params) {
    params = clearEmptyProperties(params);
    if (!isEmptyObject(params)) url.search = new URLSearchParams(params);
  }

  try {
    const result = await fetchJSON(url, 'get');
    return result;
  } catch (ex) {
    console.error(ex.message);
  }

  return { orders: [], hits: {} };
}

export async function getAllOrders(params) {
  const url = new URL(process.env.VUE_APP_API_URL + '/orders');
  if (params) {
    params = clearEmptyProperties(params);
    params.show = 50;
    if (!isEmptyObject(params)) url.search = new URLSearchParams(params);
  }

  try {
    let currentPage = 1;
    const result = await fetchJSON(url, 'get');
    if (!result?.hits?.total) {
      return { orders: [], hits: 0, totalPages: 0 };
    }

    const totalPages = Math.ceil(result.hits.total / 50);

    // Create promises of the pages to retrieve
    const arrayOfPromises = range(currentPage + 1, totalPages).map((page) => {
      const newUrl = new URL(url);
      newUrl.search = new URLSearchParams({
        ...params,
        page: page,
      });
      return fetchJSON(newUrl, 'get');
    });

    const arrResult = await Promise.all(arrayOfPromises);
    const orders = [
      ...result.orders,
      ...arrResult.map((res) => res.orders).flat(),
    ];

    return { orders, hits: result.hits, totalPages: totalPages };
  } catch (ex) {
    console.error(ex.message);
  }

  return { orders: [], hits: {} };
}

export async function getOrder(ORDER_NO, params) {
  if (!ORDER_NO) return [];
  const url = new URL(process.env.VUE_APP_API_URL + '/orders/' + ORDER_NO);

  if (params) {
    params = clearEmptyProperties(params);
    if (!isEmptyObject(params)) url.search = new URLSearchParams(params);
  }

  try {
    const result = await fetchJSON(url, 'get');
    return result;
  } catch (err) {
    console.error(err.message);
  }
}

export async function getMonitoredOrdersByUser(
  query = undefined,
  fillCommentedUsers = true
) {
  const url = new URL(process.env.VUE_APP_API_URL + '/monitored');

  if (query) {
    url.search = new URLSearchParams(query);
  }
  if (fillCommentedUsers) {
    url.searchParams.set('fill[commented-users]', true);
  }

  try {
    const result = await fetchJSON(url, 'get');
    return result;
  } catch (err) {
    console.error(err.message);
  }

  return {
    orders: [],
    countries: [],
    destination: [],
  };
}

export async function setMonitoredOrder(orderNo) {
  if (!orderNo) return;

  const body = JSON.stringify({ ORDER_NO: orderNo });

  const url = new URL(process.env.VUE_APP_API_URL + '/monitor');

  try {
    const result = await fetchJSON(url, 'put', body);
    return result;
  } catch (err) {
    console.error(err.message);
  }
}

export async function editTheComment(comment, newMessage, item) {
  comment.EDITED = true;
  const input = {
    CONTRACT: item.CONTRACT,
    ORDER_NO: item.ORDER_NO,
    comment: comment,
    newMessage: newMessage,
    edit: true,
    COMMENT_ID: comment?.COMMENT_ID,
  };

  const url = new URL(process.env.VUE_APP_API_URL + '/comment');
  const body = JSON.stringify(input);

  try {
    const result = await fetchJSON(url, 'post', body);
    if (result) {
      return result;
    }
  } catch (err) {
    console.error(err.message);
  }
}

/**
 * Calls the api endpoint of the backend to set whether an order is flagged or not
 * @param {String} CONTRACT Customer number
 * @param {String} ORDER_NO Order number
 * @param {Boolean} FLAGGED Flag value to update
 */
export async function postFlagged(CONTRACT, ORDER_NO, FLAGGED) {
  if (!CONTRACT || !ORDER_NO || FLAGGED === undefined) return [];
  const url = new URL(process.env.VUE_APP_API_URL + '/flag');
  const body = JSON.stringify({
    CONTRACT,
    ORDER_NO,
    FLAGGED,
  });

  try {
    const result = await fetchJSON(url, 'put', body);
    return result;
  } catch (err) {
    console.error(err.message);
  }
}

export async function getMappings(params) {
  const url = new URL(process.env.VUE_APP_API_URL + '/mappings');
  if (!isEmptyObject(params)) url.search = new URLSearchParams(params);

  try {
    const result = await fetchJSON(url, 'get');
    return result;
  } catch (err) {
    console.error(err.message);
  }
}
