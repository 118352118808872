<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none">
    <path
      d="M11.9945 20.1513C9.87335 20.1513 7.94897 19.3312 6.50569 18.0082L4.03462 20.9494C6.15581 22.841 8.94396 24 11.9945 24C15.0451 24 17.8333 22.841 19.9544 20.9494L17.4834 18.0082C16.0292 19.3312 14.1048 20.1513 11.9945 20.1513ZM19.4187 2.60228L16.9476 5.55444L11.9836 11.4697L4.55945 2.59134C3.5426 3.40046 2.65695 4.36264 1.94624 5.45604C0.72164 7.33667 0 9.57813 0 11.9945C0 15.0779 1.18087 17.8879 3.10524 20.02L5.60911 17.0351C4.51572 15.6465 3.84875 13.897 3.84875 11.9945C3.84875 10.7809 4.1221 9.64374 4.59225 8.60501L11.9945 17.4287L19.3968 8.60501C19.867 9.6328 20.1403 10.7809 20.1403 11.9945C20.1403 13.897 19.4843 15.6465 18.38 17.0351L20.8838 20.02C22.8082 17.8879 23.9891 15.0779 23.9891 11.9945C23.9891 9.57813 23.2674 7.33667 22.0428 5.45604C21.3321 4.36264 20.4465 3.40046 19.4296 2.59134L19.4187 2.60228ZM11.9836 3.84875C13.3831 3.84875 14.6952 4.20957 15.8433 4.8328L18.3472 1.84784C16.4993 0.688838 14.3235 0 11.9945 0C9.6656 0 7.47881 0.688838 5.63098 1.84784L8.13485 4.8328C9.28291 4.20957 10.595 3.84875 11.9945 3.84875H11.9836Z"
      fill="#2b89ed" />
  </svg>
</template>

<script>
export default {
  name: 'Munters',
};
</script>

<style scoped></style>
