<template>
  <AirCNavBar
    :navigation-items="navigationItems"
    :function-items="functionItems"
    class="airc-navbar-container" />
</template>

<script setup>
import { ref, shallowRef, onBeforeMount } from 'vue';
import AirCNavBar from './AirCNavBar.vue';
import Home from './icons/Home.vue';
import CustomerService from './icons/CustomerService.vue';
import Administration from './icons/Administration.vue';
import UserGroup from './icons/UserGroup.vue';
import Eta from './icons/Eta.vue';
import PricingTool from './icons/PricingTool.vue';
import router from '@/router';
import { fetchJSON } from '@/api';
//import Add from './icons/Add.vue';

const navigationItems = ref([]);
const functionItems = ref([]);
const accessRights = ref({});

const isProd = process.env.VUE_APP_STAGE === 'prod';
const baseUrl = `https://airc${!isProd ? '.test' : ''}.mymunters.com/#`;

onBeforeMount(async () => {
  const path = window.location.pathname;
  if (path.startsWith('/dashboard') || path === '/') {
    router.push('/orders');
  }

  await setAccessRights();

  navigationItems.value = [
    { icon: shallowRef(Home), title: 'Home', href: baseUrl + '/' },
    {
      icon: shallowRef(Administration),
      title: 'Administration',
      href: baseUrl + '/administration',
      access: !!accessRights.value['landing.shortcut.admin'],
    },
    {
      icon: shallowRef(UserGroup),
      title: 'User management',
      href: baseUrl + '/iam/groups',
      access: !!accessRights.value['landing.shortcut.iam'],
    },
    {
      icon: shallowRef(CustomerService),
      title: 'Manuals',
      href: baseUrl + '/manuals',
    },
    {
      icon: shallowRef(Eta),
      title: 'ETA',
      href: '/orders',
    },
    {
      icon: shallowRef(PricingTool),
      title: 'Pricing Tool',
      href: `https://pricingtool.airc${!isProd ? '.test' : ''}.mymunters.com`,
      access: !!accessRights.value['landing.shortcut.pricingtool'],
    },
  ];
  functionItems.value = [
    /*
    Can't copy this menu item from airc because clicking it should prompt a registration flow inside airc.
    Keeping it here for visibility so we can let them know about the issue and find a way forward.
    {
      icon: shallowRef(Add),
      title: 'Add Unit',
      //onClick: openRegisterUnitModal,
      access: accessRights.value['landing.shortcut.register'],
    }*/
  ];
});

async function setAccessRights() {
  const url = `${process.env.VUE_APP_AIRC_URL}/rest/access?pagePath=landing&pagePath=landing.shortcut.admin&pagePath=landing.shortcut.register&pagePath=landing.unit.filter&pagePath=landing.shortcut.eta&pagePath=landing.shortcut.pricingtool&pagePath=landing.shortcut.pricingtool`;
  const response = await fetchJSON(url, 'GET');
  accessRights.value = response.pageSummary;
}
</script>

<style lang="scss">
.airc-navbar-container {
  display: none;
}

body.airc-navbar .airc-navbar-container {
  display: initial;
}
</style>
