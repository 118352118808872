export default {
  mounted: function (el, binding) {
    const handler = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        if (binding.value instanceof Function) {
          binding.value(event);
        }
      }
    };
    el.__clickOutsideHandler__ = handler;
    document.body.addEventListener('click', el.__clickOutsideHandler__);
  },
  unmounted: function (el) {
    document.body.removeEventListener('click', el.__clickOutsideHandler__);
  },
};
