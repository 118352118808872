<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="13"
    viewBox="0 0 18 13"
    fill="none">
    <path
      d="M13.5947 6.48543C13.5947 9.019 11.5323 11.0798 8.99878 11.0798C6.46522 11.079 4.40524 9.01738 4.40524 6.48382C4.40524 6.06277 4.46423 5.64253 4.57899 5.23441L4.7487 4.63234L5.14146 5.11966C5.43644 5.48494 5.87365 5.69425 6.34157 5.69425C7.19256 5.69425 7.88434 5.00247 7.88434 4.1523C7.88514 3.61083 7.60876 3.11786 7.14568 2.83501L6.61311 2.50932L7.1869 2.26202C7.7615 2.01554 8.37165 1.89027 9.0004 1.89027C11.5348 1.89027 13.5956 3.95187 13.5947 6.48543Z" />
    <path
      d="M17.9604 6.33269C17.4464 4.34302 16.2544 2.69438 14.5144 1.56378C12.962 0.555201 11.0046 0 9.00283 0H8.99556C6.99133 0.000808153 5.03156 0.559242 3.47668 1.57105C1.73834 2.70246 0.549544 4.34948 0.0387914 6.3335L0 6.48543L0.0395995 6.63656C0.553585 8.62623 1.74561 10.2749 3.48557 11.4055C5.03884 12.414 6.99538 12.9701 8.99717 12.9701H9.00445C11.0087 12.9684 12.9684 12.41 14.5233 11.399C16.2617 10.2668 17.4505 8.61976 17.9612 6.63494L18 6.48382L17.9604 6.33269ZM13.7297 10.1787C12.4084 11.0394 10.7299 11.5138 9.00364 11.5154H8.99717C7.27419 11.5154 5.59808 11.0426 4.27756 10.1852C2.91339 9.29861 1.95573 8.02011 1.50559 6.48462C1.95169 4.95398 2.90612 3.67791 4.27028 2.78975C5.59161 1.92987 7.27015 1.45548 8.99636 1.45468H9.00283C10.7266 1.45468 12.4027 1.92664 13.7216 2.78409C15.0866 3.66982 16.0443 4.94913 16.4944 6.48462C16.0483 8.01527 15.0939 9.29134 13.7297 10.1787Z" />
  </svg>
</template>
<script>
export default {
  name: 'MonitoredIcon',
};
</script>
