<template>
  <div>
    <h1>Unauthorized</h1>
    <p>You are not authorized to view this module.</p>
  </div>
</template>

<script>
export default {
  name: 'Vue401',
};
</script>

<style lang="scss" scoped>
div {
  padding: 3rem;
}

h1 {
  margin: 0;
}
</style>
