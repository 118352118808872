<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="18"
    viewBox="0 0 15 18"
    fill="none">
    <path
      d="M14.7112 1.59736V11.4679C14.7112 11.6736 14.6295 11.8701 14.4837 12.0159C14.3762 12.1234 13.3558 13.0652 10.646 13.0652C9.26261 13.0652 8.14704 12.6186 7.06766 12.1875C6.08133 11.7925 5.1498 11.4203 4.06628 11.4203C2.68191 11.4203 1.90649 11.7005 1.55083 11.8825V17.2246C1.55083 17.6536 1.20448 18 0.775416 18C0.346353 18 0 17.6536 0 17.2246V1.59736C0 1.39161 0.0816772 1.19414 0.227455 1.0494C0.33498 0.941872 1.35543 0 4.06628 0C5.44859 0 6.56519 0.445606 7.64354 0.877771C8.62987 1.27168 9.5614 1.64492 10.646 1.64492C12.5535 1.64492 13.3041 1.1104 13.3961 1.03906C13.6091 0.804365 13.9285 0.741298 14.2263 0.852958C14.5282 0.966686 14.7112 1.27478 14.7112 1.59736Z" />
  </svg>
</template>
