import { defineStore } from 'pinia';

import { getMappings } from '@/api/orders';
import { getCustomers } from '@/api/customers';
import { getProducts } from '@/api/products';

import { useUserStore } from '@/store/userStore';
import { useOrdersStore } from '@/store/ordersStore';

import { getHeaders, transformColumnsAsOptions } from '@/utils/orders';
import { cloneDeep, isEmptyObject } from '@/utils/utils';
import { OPTIONAL_FILTERS } from '@/utils/optionalFilterUtils';

export const useUiStore = defineStore('UiStore', {
  state: () => {
    return {
      //#region Searchbar
      searchOpen: false,
      //#endregion

      //#region Filters
      filtersOpen: false,
      filters: {
        ascending: false,
        currentDateInterval: null,
        coordinator: [],
        countries: [],
        currentPage: 1,
        customers: [],
        VIP: undefined,
        destination: [],
        dateFrom: '',
        dateTo: '',
        dateType: '',
        updatedETA: [],
        processFilter: [],
        products: [],
        salesman: [],
        search: '',
        show: 25,
        sortKey: null,
        statusFilter: [],
      },
      //#endregion

      //#region Optional Filters
      displayedOptionalFilters: [],
      //#endregion

      //#region Edit Columns
      displayedColumns: [],
      //#endregion
    };
  },
  getters: {
    //#region Filters
    getCurrentFilterEntries() {
      const entry = {
        SAVED_FILTERS: {
          CUSTOMER_COUNTRY: this.filters.countries || undefined,
          VIP: this.filters.VIP || undefined,
          DESTINATION: this.filters.destination || undefined,
          ORDER_PROCESS: this.filters.processFilter || undefined,
          ORDER_STATUS: this.filters.statusFilter || undefined,
          DATE_FROM: this.filters.dateFrom || undefined,
          DATE_TO: this.filters.dateTo || undefined,
          DATE_TYPE: this.filters.dateType || undefined,
          SEARCH: this.filters.search || undefined,
          DATE_INTERVAL: this.filters.currentDateInterval || undefined,
          CUSTOMER: this.filters.customers || undefined,
          PRODUCT: this.filters.products || undefined,
          COORDINATOR: this.filters.coordinator || undefined,
          SALESMAN: this.filters.salesman || undefined,
          CHANGED_DATE: this.filters.updatedETA || undefined,
        },
      };
      return entry;
    },

    isFilterActive() {
      const { getCountries } = useOrdersStore();
      const isCountrySelected = getCountries?.some(
        (country) => country.checked
      );

      return !!(
        this.filters.search ||
        this.filters.currentDateInterval ||
        this.filters.countries?.length ||
        this.filters.destination?.length ||
        this.filters.dateFrom ||
        this.filters.dateTo ||
        this.filters.dateType ||
        this.filters.processFilter?.length ||
        this.filters.statusFilter?.length ||
        this.filters.updatedETA?.length ||
        this.filters.salesman?.length ||
        this.filters.coordinator?.length ||
        this.filters.customers?.length ||
        this.filters.VIP ||
        this.filters.products?.length ||
        isCountrySelected
      );
    },

    isFilterActiveForMonitored() {
      const { getMonitoredCountries } = useOrdersStore();
      const isCountrySelected = getMonitoredCountries?.some(
        (country) => country.checked
      );

      return !!(
        this.filters.search ||
        this.filters.currentDateInterval ||
        this.filters.countries?.length ||
        this.filters.destination?.length ||
        this.filters.dateFrom ||
        this.filters.dateTo ||
        this.filters.dateType ||
        this.filters.processFilter?.length ||
        this.filters.statusFilter?.length ||
        this.filters.updatedETA?.length ||
        this.filters.salesman?.length ||
        this.filters.coordinator?.length ||
        this.filters.customers?.length ||
        this.filters.VIP ||
        this.filters.products?.length ||
        isCountrySelected
      );
    },

    isFilterOpen() {
      return this.filtersOpen;
    },
    //#endregion

    //#region Orders Table Headers
    getEnabledHeaders() {
      const { isInternal, isEndCustomer, isReseller } = useUserStore();

      return getHeaders({
        internal: isInternal,
        endCustomer: isEndCustomer,
        reseller: isReseller,
      }).filter((header) => header.enabled);
    },

    getDisplayedHeaders() {
      return this.getEnabledHeaders
        .map((header) => {
          if (!header.title) {
            return header;
          }

          const found = this.displayedColumns.find(
            (col) => col.sortKey === header.sortKey
          );
          if (found) {
            return found;
          }
        })
        .filter(Boolean);
    },
    //#endregion

    //#region Orders Table Columns
    /**
     * Gets the existing headers that are enabled for
     * the specific user (e.g. internal, endCustomer, and reseller)
     * @returns { ReturnType<getHeaders>[] } Array of column records
     */
    getEnabledColumns() {
      const { isInternal, isEndCustomer, isReseller } = useUserStore();

      return getHeaders({
        internal: isInternal,
        endCustomer: isEndCustomer,
        reseller: isReseller,
      }).filter((header) => header.enabled && header.title);
    },

    /**
     * Filters the enabled columns and Returns
     * the initial default columns
     * @returns { ReturnType<getHeaders>[] } Default array of column records
     */
    getDefaultColumns() {
      return this.getEnabledColumns.filter((col) => col.default);
    },

    /**
     * Transforms the list of columns (@see ReturnType<getHeaders>[])
     * into an array of string values (@see Pick<ReturnType<getHeaders>, 'sortKey'>)
     * @returns { string[] } String of keys
     */
    getDisplayedColumnValues() {
      return this.displayedColumns?.flatMap((v) => {
        return v.sortKey;
      });
    },

    /**
     * Checks whether the current displayed columns are the default columns.
     * @returns { Boolean }
     */
    isDefaultColumns() {
      const hasSameLength =
        this.getDefaultColumns.length === this.displayedColumns.length;
      const containsSameKeys = this.getDefaultColumns.every(
        (col) =>
          !!this.displayedColumns.find(
            (defCol) => col.sortKey === defCol.sortKey
          )
      );
      return hasSameLength && containsSameKeys;
    },
    //#endregion

    //#region Edit Columns
    /**
     * Returns an array of transformed columns into options
     * to populate the edit column dropdown
     */
    getColumnsAsOptions() {
      return transformColumnsAsOptions(...this.getEnabledColumns);
    },
    //#endregion
  },
  actions: {
    //#region Utils
    async updateQueryParams(query, replace = true) {
      if (replace) await this.$router.replace({ query });
      else await this.$router.push({ query });
    },
    async getSalesman(name) {
      if (!name) return [];
      const result = await getMappings({ search: name, salesman: true });
      return result?.map((c) => ({
        title: c.NAME,
        subtitle: c.CODE,
        value: c.CODE,
        type: c.CODE_TYPE,
      }));
    },
    async getCoordinator(name) {
      if (!name) return [];
      const result = await getMappings({ search: name, coordinator: true });
      return result?.map((c) => ({
        title: c.NAME,
        subtitle: c.CODE,
        value: c.CODE,
        type: c.CODE_TYPE,
      }));
    },
    async getCustomer(name) {
      if (!name) return [];
      const result = await getCustomers({ customer: name });
      if (!result) return [];

      return result?.map((c) => ({
        title: c.CUSTOMER_NAME,
        subtitle: `IFS no. ${c.CUSTOMER_NO}`,
        optionSubtitleIcon: c.IS_VIP ? 'mm-star-icon' : null,
        value: c.CUSTOMER_NO,
      }));
    },
    async getProduct(product) {
      if (!product) return [];
      const result = await getProducts({ product: product });

      if (isEmptyObject(result)) return [];
      return result?.map((p) => ({
        title: p.CATALOG_DESC,
        subtitle: `Catalog no. ${p.CATALOG_NO}`,
        value: p.CATALOG_NO,
      }));
    },
    //#endregion

    //#region Filter State
    toggleFilterState(state = !this.filtersOpen) {
      this.filtersOpen = state;
    },
    updateFilters(filter) {
      this.filters = {
        ...this.filters,
        ...filter,
      };
    },
    setFilters(newFilters) {
      this.filters = newFilters;
    },
    //#endregion

    //#region Optional Filters
    removeOptionalFilters(...key) {
      this.displayedOptionalFilters = this.displayedOptionalFilters.filter(
        (filter) => !key.includes(filter)
      );
    },

    updateOptionalFilters(...additionalFilters) {
      this.displayedOptionalFilters.push(...additionalFilters);
    },

    setOptionalFilters(...newFilters) {
      this.displayedOptionalFilters = newFilters;
    },

    clearOptionalFilters() {
      this.displayedOptionalFilters = [];
    },

    updateOptionalFiltersBasedOnAppliedFilters(filters) {
      const { updateOptionalFilters, removeOptionalFilters } = useUiStore();

      if (filters.countries?.length) {
        updateOptionalFilters(OPTIONAL_FILTERS.CUSTOMER_COUNTRIES.key);
      } else {
        removeOptionalFilters(OPTIONAL_FILTERS.CUSTOMER_COUNTRIES.key);
      }

      if (filters.VIP)
        updateOptionalFilters(OPTIONAL_FILTERS.CUSTOMER_TYPE.key);
      else removeOptionalFilters(OPTIONAL_FILTERS.CUSTOMER_TYPE.key);

      if (filters.destination?.length)
        updateOptionalFilters(OPTIONAL_FILTERS.DESTINATION.key);
      else removeOptionalFilters(OPTIONAL_FILTERS.DESTINATION.key);

      if (filters.processFilter?.length)
        updateOptionalFilters(OPTIONAL_FILTERS.PROCESS.key);
      else removeOptionalFilters(OPTIONAL_FILTERS.PROCESS.key);

      if (filters.updatedETA?.length)
        updateOptionalFilters(OPTIONAL_FILTERS.CHANGED_DATE.key);
      else removeOptionalFilters(OPTIONAL_FILTERS.CHANGED_DATE.key);

      if (filters.products?.length)
        updateOptionalFilters(OPTIONAL_FILTERS.PRODUCTS.key);
      else removeOptionalFilters(OPTIONAL_FILTERS.PRODUCTS.key);
    },
    //#endregion

    //#region Edit Columns
    /**
     * Checks the localStorage if there exists, if it exist then
     * use the existing selected columns, else use the default columns.
     */
    initializeColumns() {
      const { getUserType } = useUserStore();

      const savedColumns = JSON.parse(
        localStorage.getItem('orderList:columnFilter')
      );
      const previousUserType = localStorage.getItem('cachedUserType');
      const userTypeChanged = previousUserType !== getUserType;

      if (savedColumns?.length && !userTypeChanged) {
        // Check if stored cols in local storage are of key-value type
        if ('value' in savedColumns[0]) {
          const transformedCols = savedColumns.map((col) =>
            this.getEnabledColumns.find((c) => c.sortKey === col.value)
          );
          Object.assign(savedColumns, transformedCols);
        }

        this.displayedColumns = this.getEnabledColumns
          .map((col) => {
            const found = savedColumns.find(
              (cached) => cached.sortKey === col.sortKey
            );
            if (!found) return null;

            return {
              ...found,
              minWidth: col.minWidth || col.width,
            };
          })
          .filter(Boolean);
      } else {
        // Set default columns as initial values
        this.displayedColumns = this.getDefaultColumns;
        // Clear cached values
        localStorage.removeItem('orderList:columnFilter');
        localStorage.removeItem('cachedUserType');
      }
    },

    /**
     * Updates the selected order column
     * @param {{ detail: string[] }} payload
     */
    updateOrderColumns({ detail }) {
      const { getUserType } = useUserStore();
      const selectedCol = this.getEnabledColumns.filter((col) =>
        detail.includes(col.sortKey)
      );
      this.displayedColumns = selectedCol;

      localStorage.setItem(
        'orderList:columnFilter',
        JSON.stringify(this.displayedColumns)
      );
      localStorage.setItem('cachedUserType', getUserType);
    },

    resetDisplayedColumns() {
      this.displayedColumns = cloneDeep(this.getDefaultColumns);
      localStorage.setItem(
        'orderList:columnFilter',
        JSON.stringify(this.displayedColumns)
      );
    },

    updateColumnWidth(columnSortKey, width) {
      const clonedColumns = cloneDeep(this.displayedColumns);
      const column = clonedColumns.find((col) => col.sortKey === columnSortKey);
      if (column) column.width = width;

      this.displayedColumns = clonedColumns;
    },

    saveColumns() {
      const { getUserType } = useUserStore();
      localStorage.setItem(
        'orderList:columnFilter',
        JSON.stringify(this.displayedColumns)
      );
      localStorage.setItem('cachedUserType', getUserType);
    },
    //#endregion
  },
});
