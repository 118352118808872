<template>
  <svg
    width="14"
    height="18"
    viewBox="0 0 14 18"
    fill="inehrit"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.64492 9.00352C6.46914 8.44102 6.47266 7.35469 6.57461 7.35469C6.86992 7.35469 6.8418 8.65195 6.64492 9.00352ZM6.58516 10.6629C6.31445 11.373 5.97695 12.1852 5.58672 12.8672C6.23008 12.6211 6.95781 12.2625 7.79805 12.0973C7.35156 11.7598 6.92266 11.2746 6.58516 10.6629ZM3.27695 15.0504C3.27695 15.0785 3.74102 14.8605 4.50391 13.6371C4.26836 13.8586 3.48086 14.4984 3.27695 15.0504ZM8.96875 5.625H13.75V17.1562C13.75 17.6238 13.3738 18 12.9062 18H1.09375C0.626172 18 0.25 17.6238 0.25 17.1562V0.84375C0.25 0.376172 0.626172 0 1.09375 0H8.125V4.78125C8.125 5.24531 8.50469 5.625 8.96875 5.625ZM8.6875 11.6648C7.98438 11.2359 7.5168 10.6453 7.18633 9.77344C7.34453 9.12305 7.59414 8.13516 7.4043 7.51641C7.23906 6.48281 5.91367 6.58477 5.72383 7.27734C5.54805 7.9207 5.70977 8.82773 6.00859 9.98438C5.60078 10.9547 4.99961 12.2555 4.57422 13.0008C4.5707 13.0008 4.5707 13.0043 4.56719 13.0043C3.61445 13.493 1.97969 14.5687 2.65117 15.3949C2.84805 15.6375 3.21367 15.7465 3.40703 15.7465C4.03633 15.7465 4.66211 15.1137 5.55508 13.5738C6.46211 13.275 7.45703 12.9023 8.33242 12.7582C9.09531 13.173 9.98828 13.4437 10.5824 13.4437C11.609 13.4437 11.6793 12.3188 11.275 11.918C10.7863 11.4398 9.36602 11.577 8.6875 11.6648ZM13.5039 3.69141L10.0586 0.246094C9.90039 0.0878906 9.68594 0 9.46094 0H9.25V4.5H13.75V4.28555C13.75 4.06406 13.6621 3.84961 13.5039 3.69141ZM10.8988 12.6668C11.043 12.5719 10.8109 12.2484 9.39414 12.3504C10.6984 12.9059 10.8988 12.6668 10.8988 12.6668Z"
      fill="inherit" />
  </svg>
</template>
<script>
export default {
  name: 'PDFIcon',
};
</script>
