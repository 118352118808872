import { orderFilterLabels } from './filters';

function createOptionalFilter(component) {
  const isEnabled = (f) =>
    !!component.displayedOptionalFilters.find((filter) => filter === f);
  const createCancelEvent = (clearMethod, key) => async () => {
    await clearMethod();
    component.onRemoveFilter(key);
  };

  return function ({
    optionalFilter,
    value,
    options,
    multipleSelection,
    searchable,
    onSelectedItem,
    onClearDropdownItems,
    onSearch,
    isLoading,
    asynchronous,
  }) {
    const key = optionalFilter.key;
    const title = optionalFilter.title;
    const external = optionalFilter.external;
    const id = optionalFilter.id;

    return {
      key,
      id,
      title,
      external,
      enabled: isEnabled(key),
      value,
      options,
      label: component.dropdownLabel(title, value),
      multipleSelection,
      searchable,
      onSelectedItem,
      onClearDropdownItems,
      onSearch,
      isLoading,
      asynchronous,
      onCancel: createCancelEvent(onClearDropdownItems, key),
    };
  };
}

export function getOptionalFilters(component) {
  const optionalFilterCreator = createOptionalFilter(component);

  const customerCountryFilter = optionalFilterCreator({
    optionalFilter: OPTIONAL_FILTERS.CUSTOMER_COUNTRIES,
    value: component.filters.countries || [],
    options: component.countries,
    multipleSelection: true,
    searchable: true,
    onSelectedItem: component.updateCountry,
    onClearDropdownItems: component.clearCountry,
  });

  const customerTypeFilter = optionalFilterCreator({
    optionalFilter: OPTIONAL_FILTERS.CUSTOMER_TYPE,
    value: component.filters.VIP || [],
    options: component.customerTypeFilters,
    multipleSelection: false,
    searchable: false,
    onSelectedItem: component.updateCustomerType,
    onClearDropdownItems: component.clearCustomerType,
  });

  const destinationCountryFilter = optionalFilterCreator({
    optionalFilter: OPTIONAL_FILTERS.DESTINATION,
    value: component.filters.destination || [],
    options: component.destination,
    multipleSelection: true,
    searchable: true,
    onSelectedItem: component.updateDestination,
    onClearDropdownItems: component.clearDestination,
  });

  const processFilter = optionalFilterCreator({
    optionalFilter: OPTIONAL_FILTERS.PROCESS,
    value: component.filters.processFilter || [],
    options: component.processFilters,
    onSelectedItem: component.updateProcessFilter,
    onClearDropdownItems: component.clearProcess,
    multipleSelection: true,
  });

  const changedDateFilter = optionalFilterCreator({
    optionalFilter: OPTIONAL_FILTERS.CHANGED_DATE,
    value: component.filters.updatedETA || [],
    options: component.changedDateFilters,
    multipleSelection: true,
    info: component.changedDateFilterInfo,
    onSelectedItem: component.updateChangedDateFilter,
    onClearDropdownItems: component.clearChangedDate,
  });
  const productFilter = optionalFilterCreator({
    optionalFilter: OPTIONAL_FILTERS.PRODUCTS,
    value: component.filters.products || [],
    options: component.productOptions,
    multipleSelection: true,
    isLoading: component.productIsLoading,
    searchable: true,
    onSelectedItem: component.updateProduct,
    onClearDropdownItems: component.clearProduct,
    onSearch: component.debounceProduct,
    asynchronous: true,
  });

  const filters = [
    customerCountryFilter,
    customerTypeFilter,
    destinationCountryFilter,
    processFilter,
    changedDateFilter,
    productFilter,
  ];

  return filters.filter(Boolean);
}

export const OPTIONAL_FILTERS = {
  CUSTOMER_COUNTRIES: {
    key: 'CUSTOMER_COUNTRIES',
    id: 'eta-order-filters__customer-countries',
    title: orderFilterLabels.CUSTOMER_COUNTRY,
    external: true,
  },
  CUSTOMER_TYPE: {
    key: 'VIP',
    id: 'eta-order-filters__customer-type',
    title: orderFilterLabels.VIP,
    external: false,
  },
  DESTINATION: {
    key: 'DESTINATION',
    id: 'eta-order-filters__destination',
    title: orderFilterLabels.DESTINATION,
    external: true,
  },
  PROCESS: {
    key: 'PROCESS',
    id: 'eta-order-filters__process',
    title: orderFilterLabels.ORDER_PROCESS,
    external: true,
  },
  CHANGED_DATE: {
    key: 'CHANGED_DATE',
    id: 'eta-order-filters__changed-date',
    title: orderFilterLabels.CHANGED_DATE,
    external: true,
  },
  PRODUCTS: {
    key: 'PRODUCTS',
    id: 'eta-order-filters__products',
    title: orderFilterLabels.PRODUCT,
    external: false,
  },
};
