import { fetchJSON } from '.';
import { clearEmptyProperties, isEmptyObject } from '../utils/utils';

export async function getStatisticsAsync(params) {
  const url = new URL(process.env.VUE_APP_API_URL + '/statistics');
  if (params) {
    params = clearEmptyProperties(params);
    if (!isEmptyObject(params)) url.search = new URLSearchParams(params);
  }
  try {
    const result = await fetchJSON(url, 'get');

    if (result.message) {
      throw new Error(result.message);
    }

    return result;
  } catch (error) {
    throw new Error(error.message);
  }
}

export async function getCustomerStatisticsAsync(customerId, params) {
  const url = new URL(
    process.env.VUE_APP_API_URL + '/customer-statistics/' + customerId
  );

  if (params) {
    params = clearEmptyProperties(params);
    if (!isEmptyObject(params)) url.search = new URLSearchParams(params);
  }

  try {
    const result = await fetchJSON(url, 'get');

    if (result.message) {
      throw new Error(result.message);
    }

    return result;
  } catch (error) {
    throw new Error(error.message);
  }
}

export async function getPersonalStatisticsAsync(params) {
  const url = new URL(process.env.VUE_APP_API_URL + '/personal-statistics');
  if (params) {
    params = clearEmptyProperties(params);
    if (!isEmptyObject(params)) url.search = new URLSearchParams(params);
  }
  try {
    const result = await fetchJSON(url, 'get');

    if (result.message) {
      throw new Error(result.message);
    }

    return result;
  } catch (error) {
    console.error(error);
    return [];
  }
}
