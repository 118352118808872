import { createApp, markRaw } from 'vue';
import App from './App.vue';
import router from './router';
import clickOutside from './utils/click-outside';
import { createPinia } from 'pinia';
import './styles/main.scss';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  ArcElement,
  BarElement,
  CategoryScale,
  PointElement,
} from 'chart.js';
import mediaQueryPlugin from './utils/mediaQueryPlugin';
import smartPositioning from './utils/smart-positioning';
import mmDimensionsPlugin from './utils/mmDimensionsPlugin';
import mitt from 'mitt';

ChartJS.defaults.color = '#000';
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  ArcElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement
);

const pinia = createPinia();
pinia.use(({ store }) => {
  store.$router = markRaw(router);
  store.$route = markRaw(router.currentRoute);
  store.$emitter = emitter;
});
const emitter = mitt();

const app = createApp(App)
  .directive('click-outside', clickOutside)
  .directive('smart-positioning', smartPositioning)
  .use(mediaQueryPlugin)
  .use(mmDimensionsPlugin)
  .use(router)
  .use(pinia);
app.provide('emitter', emitter);
app.mount('#app');
