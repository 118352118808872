<template>
  <svg
    id="Lager_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 416.7 239.12"
    xml:space="preserve">
    <path
      id="Path_176"
      fill="white"
      d="M391.32,114.56h-27.6c-0.41-5.85-1.38-11.65-2.91-17.31c-2.86-10.84-8.51-20.75-16.37-28.75
	c-12.6-11.82-29.09-18.62-46.36-19.11h-19.49V27.97c-0.05-11.71-9.52-21.18-21.23-21.23H115.93c-11.7,0.03-21.18,9.53-21.18,21.23
	v21.39H60.44c-6.03,0-10.91,4.89-10.91,10.92c0,6.02,4.88,10.9,10.91,10.91h34.35V90.4h-75.9c-6.03,0-10.92,4.88-10.92,10.91
	c0,6.03,4.88,10.92,10.91,10.92c0,0,0.01,0,0.01,0h75.9v19.29H48.14c-6.03,0-10.92,4.88-10.92,10.91s4.88,10.92,10.91,10.92
	c0,0,0.01,0,0.01,0h46.64v16.01c-0.02,11.68,9.44,21.16,21.11,21.18c0.01,0,0.02,0,0.03,0h8.95c-0.01,19.41,15.72,35.15,35.13,35.16
	c19.41,0.01,35.15-15.72,35.16-35.13c0-0.01,0-0.02,0-0.03H302.9c0,19.41,15.74,35.15,35.15,35.15c19.41,0,35.15-15.74,35.15-35.15
	h-2.7c0.92,0.01,1.84-0.16,2.7-0.51v0.51h18.12c6.55-0.01,11.85-5.31,11.86-11.86v-52.25C403.17,119.87,397.86,114.57,391.32,114.56
	 M160.06,203.85c-7.35,0-13.31-5.96-13.31-13.31s5.96-13.31,13.31-13.31c7.35,0,13.31,5.96,13.31,13.31l0,0
	C173.37,197.89,167.41,203.84,160.06,203.85 M278.6,114.56V71.19h19.5c23.97,0.11,43.42,19.41,43.74,43.37H278.6z M338.05,203.85
	c-7.35,0-13.32-5.96-13.32-13.32s5.96-13.32,13.32-13.32c4.08,0.01,7.93,1.92,10.4,5.17c0.96,1.1,1.68,2.39,2.12,3.78
	c0.52,1.4,0.79,2.87,0.8,4.36c-0.01,7.35-5.96,13.31-13.31,13.31" />
  </svg>
</template>

<script>
export default {
  name: 'Eta',
};
</script>

<style scoped></style>
