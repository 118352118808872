<template>
  <article class="ifs-order-line">
    <header v-if="!isMobile">{{ title }}</header>
    <table v-if="!isEmpty">
      <template v-for="(section, i) of sections" :key="i">
        <tr v-for="[key, value] of section" :key="key">
          <td>{{ key }}</td>
          <td>{{ value || '-' }}</td>
        </tr>
        <tr v-if="!isLastSection(i)">
          <td class="section-divider" colspan="100%" />
        </tr>
      </template>
    </table>
    <slot v-else name="empty">
      <main>
        <InformationIcon style="height: 52px; width: 52px" bg-color="#00253E" />
        <span>There is no {{ title }} data</span>
      </main>
    </slot>
  </article>
</template>
<script>
import InformationIcon from '@/components/shared/Icons/InformationIcon.vue';

export default {
  name: 'IFSOrderLineTable',
  components: { InformationIcon },
  inject: ['isMobile'],
  props: {
    title: {
      type: String,
      default: '',
    },
    sections: {
      type: Array,
      default: () => [],
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    isLastSection(i) {
      return i === this.sections.length - 1;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../../../../styles/main.scss';

.ifs-order-line {
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: #000;
  width: 100%;

  header {
    font-size: 24px;
    font-style: normal;
    font-weight: 450;
  }

  table {
    border-collapse: collapse;

    th,
    td {
      padding: 2px;
      word-break: break-word;
      width: 50%;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;
      height: 32px;
      white-space: pre-line;
    }

    tr {
      font-size: small;
    }

    tr:nth-child(even) {
      background-color: #ececec;
    }

    .section-divider {
      height: 2px;
      background-color: #242424;
      padding: 0;
    }
  }

  main {
    display: flex;
    flex-direction: column;
    place-items: center;
    padding-block-start: 64px;
    gap: 16px;
    width: 100%;
    height: 100%;
    background-color: #f8f8f8;
    font-size: 20px;
    font-weight: 450;
    line-height: 24px;
  }
}

@media screen and (max-width: $bp-sm) {
  .ifs-order-line {
    background-color: white;
    border-radius: 8px;
    width: unset;
    margin: 16px;
    padding: 16px;
    min-height: 80%;

    main {
      background-color: unset;
    }
  }
}
</style>
