import { compareDates } from './datetime';

/**
 * Debounce wrapper
 * @param {Function} func Function to debounce
 * @param {Number} wait ms to wait
 * @param {Boolean?} immediate Run immediately
 * @returns Debounced function
 */
export function debounce(func, wait, immediate) {
  let timeout;

  return function () {
    const context = this;
    const args = arguments;

    const later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    if (callNow) func.apply(context, args);
    else timeout = setTimeout(later, wait);
  };
}

/**
 * Removed duplicates from the given list
 * @param {Array<T>} list List of items
 * @param {typeof T} key Unique property of the items
 * @returns {T[]} Unique items
 */
export function unique(list, key) {
  const unique = [];

  for (const item of list) {
    if (!unique.find((u) => u[key] === item[key])) {
      unique.push(item);
    }
  }

  return unique;
}

export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function isEmptyObject(obj) {
  if (!obj) return true;
  return (
    Object.keys(obj).length === 0 &&
    Object.getPrototypeOf(obj) === Object.prototype
  );
}

export function isObject(obj) {
  return typeof obj === 'object' && !Array.isArray(obj) && obj !== null;
}

export function isObjectOrArray(obj) {
  return obj !== null && typeof obj === 'object';
}

/**
 * Helper to retrieve difference between two objects
 * @param {Object} a Object A
 * @param {Object} b Object B
 * @returns An object containing the difference between A & B
 */
export function getDifference(a, b) {
  if (!b) return { ...a };
  if (!a) return { ...b };

  const diff = {};
  for (const [key, value] of Object.entries(a)) {
    const newValue = b[key];
    if (newValue === value) continue;

    if (isObjectOrArray(newValue) && isObjectOrArray(value)) {
      const deepDiff = getDifference(value, newValue);
      if (Object.values(deepDiff).length !== 0) diff[key] = deepDiff;
    } else {
      diff[key] = newValue;
    }
  }

  return diff;
}

/**
 * Returns an object copy with no empty/null/undefined properties.
 * Works recursively.
 */
export function clearEmptyProperties(obj) {
  if (!obj) return;

  const clearedObject = {};

  for (const [key, value] of Object.entries(obj)) {
    if (value == null) continue;

    const hasLength = Object.prototype.hasOwnProperty.call(value, 'length');
    if (hasLength && value.length === 0) continue;

    if (!hasLength && typeof value === 'object') {
      const tempValue = clearEmptyProperties(value);
      if (!isEmptyObject(tempValue)) clearedObject[key] = tempValue;
    } else clearedObject[key] = value;
  }

  return clearedObject;
}

/**
 * Creates an array of number from `start` to `end`.
 * @param {number} start Start number of range
 * @param {number} end End number of range
 * @param {number} step Amount to add per iteration
 * @returns Returns an array of numbers from `start` to `end`
 */
export function range(start, end, step = 1) {
  const newArray = [];
  for (let index = start; index <= end; index += step) {
    newArray.push(index);
  }
  return newArray;
}

/**
 * Group items by the specificied property in parameter `by`
 * @type <T>
 * @param {T[]} items Items to group
 * @param {keyof T | ((item: T) => string)} by Property to be grouped by
 * @returns {{ [k: string]: T[] }} Object
 */
export function groupBy(items, by) {
  if (!items) {
    return;
  }

  const group = items.reduce((acc, next) => {
    const value = typeof by == 'function' ? by(next) : next[by];
    if (acc.has(value)) {
      return acc.set(value, [...acc.get(value), next]);
    }
    return acc.set(value, [next]);
  }, new Map());

  return Object.fromEntries(group);
}

/**
 * Maps the values of an object and transforms the values of each object passed
 * @type <T, L>
 * @param {object} obj Object to map
 * @param {(item: T, key?: string) => L} transform Mapping function to transfer the values to
 * @returns  {{ [k: string]: L }}Transformed object
 */
export function mapValues(obj, transform) {
  const newObj = {};

  for (const keys in obj) {
    newObj[keys] = transform(obj[keys], keys);
  }

  return newObj;
}

/**
 * Compares two items and determines if they are equal. This is recursive
 * and will check every property for equality of the two items.
 * @param {any} item1 First item
 * @param {ant} item2 Ssecond item
 * @returns {boolean}
 */
export function deepEquals(item1, item2) {
  if (Array.isArray(item1) && Array.isArray(item2)) {
    if (item1.length != item2.length) return false;
    return item1.every((item, index) => item === item2[index]);
  } else if (typeof item1 === 'object' && typeof item2 === 'object') {
    let allEquals = true;

    if (Object.keys(item1).length !== Object.keys(item2).length) return false;

    for (const key in item1) {
      allEquals = allEquals && deepEquals(item1[key], item2[key]);
    }
    return allEquals;
  } else if (item1 instanceof Date && item2 instanceof Date) {
    return compareDates(item1, item2) === 0;
  } else {
    return item1 === item2;
  }
}

/**
 * Clones an object deeply
 * @param {any} data
 * @returns {any}
 */
export function cloneDeep(data) {
  return JSON.parse(JSON.stringify(data));
}

/**
 * Merges two items deeply. For objects, it checks whether the key exists and merges the contents deeeply.
 * @param item1
 * @param item2
 * @returns Merge items
 */
export function deepMerge(item1, item2) {
  if (Array.isArray(item1) && Array.isArray(item2)) {
    const newArr = [];
    for (let i = 0; i < Math.max(item1.length, item2.length); i++) {
      const entries1 = item1[i] || [];
      const entries2 = item2[i] || [];
      newArr.push(...entries1, ...entries2);
    }
    return newArr;
  } else if (typeof item1 === 'object' && typeof item2 === 'object') {
    const newObject = {};
    for (const key in item1) {
      const exists = item2[key];
      if (exists) {
        newObject[key] = deepMerge(item1[key], item2[key]);
      } else {
        newObject[key] = item1[key];
      }
    }
    return {
      ...item2,
      ...newObject,
    };
  } else {
    return item2 ?? item1;
  }
}

export const sortObjectEntries = (value) => {
  if (!value) return;

  return Object.entries(value).sort();
};

/**
 * Patches items with updated item if item exists.
 * @param {T[]} items
 * @param {(item: T, updatedItem: T) => boolean | keyof T} comparator
 * @param {T} updatedItem
 * @returns {T[]}
 */
export function patchArray(items, comparator, updatedItem) {
  const clonedItems = cloneDeep(items);
  const itemIndex = clonedItems?.findIndex((item) =>
    typeof comparator === 'function'
      ? comparator(item, updatedItem)
      : item[comparator] === updatedItem[comparator]
  );
  if (itemIndex >= 0) {
    clonedItems[itemIndex] = updatedItem;
    return clonedItems;
  }
  return items;
}
