import { setVipCustomer, getCustomers } from '@/api/customers';
import { defineStore } from 'pinia';
import { sortByProp } from '../utils';

export const useCustomersStore = defineStore('CustomersStore', {
  state: () => {
    return {
      customers: [],
      selectedCustomer: {},
      totalCustomers: 0,
      loading: false,
      isFetchingCustomers: false,
      customerCountries: [],
      customerContracts: [],
    };
  },
  getters: {
    getCustomers() {
      return this.customers;
    },
  },
  actions: {
    //#region VIP Customers Handler
    async toggleVipCustomer(customerNo, isVip) {
      const newVipStatus = !isVip;
      const body = {
        CUSTOMER_NO: customerNo,
        IS_VIP: newVipStatus,
      };
      await setVipCustomer(body);

      return newVipStatus;
    },

    //#endregion

    //#region fill customers
    async fillCustomers(params, appendResult = false) {
      this.loading = appendResult ? false : true;
      this.isFetchingCustomers = true;
      params.type = 'detailed';

      const isOptionsPopulated =
        this.customerCountries.length && this.customerContracts.length;

      if (!isOptionsPopulated) {
        params.getOptionValues = true;
      }

      const response = await getCustomers(params);
      const customers = response?.CUSTOMERS || [];
      if (appendResult) {
        this.customers.push(...customers);
      } else {
        this.customers = customers;
      }

      this.totalCustomers = response?.TOTAL_CUSTOMERS || 0;

      if (!isOptionsPopulated) {
        this.customerContracts =
          response?.OPTION_VALUES?.CONTRACTS?.sort() || [];
        this.customerCountries =
          response?.OPTION_VALUES?.COUNTRIES?.sort(
            sortByProp('NAME')
          ).reverse() || [];
      }

      this.loading = false;
      this.isFetchingCustomers = false;
    },

    async getSingleCustomer(params, fillVipDetails = false) {
      params.type = 'detailed';
      if (fillVipDetails) params['fill[vip-details]'] = true;
      const response = await getCustomers(params);
      const [customer] = response.CUSTOMERS;
      this.selectedCustomer = customer;
    },
  },
});
