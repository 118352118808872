import { getOrders, getAllOrders } from '@/api/orders';
import { getCustomerStatisticsAsync } from '@/api/statistics';
import { formatDate } from '@/utils/datetime';
import { defineStore } from 'pinia';

export const useCustomerDashboardStore = defineStore('CustomerDashboardStore', {
  state: () => ({
    customerNo: null,
    plannedDeliveries: {
      loading: false,
      hits: 0,
      orders: [],
      page: 1,
    },
    ongoingOrders: {
      loading: false,
      hits: 0,
      orders: [],
      page: 1,
    },
    customerStatistics: {
      loading: true,
      statistics: [],
    },
    latestCommentedOrders: {
      loading: false,
      orders: [],
      page: 1,
    },
  }),
  getters: {
    isCustomerStatisticsLoading: (state) => state.customerStatistics.loading,
    getCustomerStatistics: (state) => state.customerStatistics.statistics,

    isOngoingOrdersLoading: (state) => state.ongoingOrders.loading,
    getOngoingOrders: (state) => state.ongoingOrders.orders,
    getOngoingOrdersHits: (state) => state.ongoingOrders.hits,
    getOngoingOrdersPage: (state) => state.ongoingOrders.page,

    isPlannedDeliveriesLoading: (state) => state.plannedDeliveries.loading,
    getPlannedDeliveries: (state) => state.plannedDeliveries.orders,
    getPlannedDeliveriesHits: (state) => state.plannedDeliveries.hits,

    isLatestCommentedOrdersLoading: (state) =>
      state.latestCommentedOrders.loading,
    getLatestCommentedOrders: (state) => state.latestCommentedOrders.orders,
  },
  actions: {
    async initializeCustomerDashboard(customerNo) {
      this.customerNo = customerNo;

      const initOngoingOrdersAsync = async () => {
        this.ongoingOrders.loading = true;
        const ongoingOrders = await this.fetchOngoingOrders();
        this.ongoingOrders.orders = ongoingOrders.orders;
        this.ongoingOrders.hits = ongoingOrders.hits.total;
        this.ongoingOrders.loading = false;
      };

      const initPlannedDeliveriesAsync = async () => {
        this.plannedDeliveries.loading = true;
        const plannedDeliveries = await this.fetchPlannedDeliveries();
        this.plannedDeliveries.orders = plannedDeliveries.orders;
        this.plannedDeliveries.hits = plannedDeliveries.hits.total;
        this.plannedDeliveries.loading = false;
      };

      const initLatestCommentedOrdersAsync = async () => {
        this.latestCommentedOrders.loading = true;
        const latestCommentedOrders = await this.fetchLatestCommentedOrders();
        this.latestCommentedOrders.orders = latestCommentedOrders.orders;
        this.latestCommentedOrders.loading = false;
      };

      await Promise.all([
        initOngoingOrdersAsync(),
        initPlannedDeliveriesAsync(),
        initLatestCommentedOrdersAsync(),
      ]);
    },
    async fetchCustomerStatistic(params) {
      try {
        this.customerStatistics.loading = true;
        this.customerStatistics.statistics = await getCustomerStatisticsAsync(
          this.customerNo,
          {
            dateFrom: formatDate(params?.dateFrom),
            dateTo: formatDate(params?.dateTo),
          }
        );
      } catch (error) {
        console.error(error);
        this.customerStatistics.statistics = [];
      } finally {
        this.customerStatistics.loading = false;
      }
    },

    /**
     * Fetch ongoing orders
     * @param {Object} params
     * @returns {Promise<{ orders: CompleteOrders[], hits: number }>}
     */
    async fetchOngoingOrders(params = {}) {
      try {
        const response = await getOrders({
          'fill[monitored]': true,
          'fill[commented-users]': true,
          show: 20,
          customers: this.customerNo,
          ...params,
        });

        return response;
      } catch (error) {
        console.error(error);
        return [];
      }
    },

    /**
     * Fetch planned deliveries
     * @param {Object} params
     * @returns {Promise<{ orders: CompleteOrders[], hits: number }>}
     */
    async fetchPlannedDeliveries(params = {}) {
      try {
        this.plannedDeliveries.loading = true;

        const defaultDateFrom = new Date().toISOString().substring(0, 10);

        const res = await getAllOrders({
          'fill[monitored]': true,
          'fill[commented-users]': true,
          customers: this.customerNo,
          dateType: 'PLANNED_DELIVERY_DATE',
          dateFrom: defaultDateFrom,
          sortKey: 'PLANNED_DELIVERY_DATE',
          sortOrder: 'asc',
          ...params,
        });

        return res;
      } catch (error) {
        console.error(error);
        return [];
      }
    },

    /**
     * Fetch latest commented orders
     * @param {Object} params
     * @returns {Promise<{ orders: CompleteOrders[], hits: number }>}
     */
    async fetchLatestCommentedOrders(params = {}) {
      try {
        const result = await getOrders({
          customers: this.customerNo,
          'fill[monitored]': true,
          'fill[commented-users]': true,
          latestUpdatedComments: true,
          show: 8,
          ...params,
        });

        const filteredOrders =
          result?.orders?.filter(({ COMMENTS }) => COMMENTS?.length > 0) || [];

        return {
          ...result,
          orders: filteredOrders,
        };
      } catch (error) {
        console.error(error);
        return [];
      }
    },

    async resetStore() {
      this.$reset();
    },
  },
});
