<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none">
    <path
      d="M1.125 14.75C1.125 15.3723 1.62773 15.875 2.25 15.875H15.75C16.3723 15.875 16.875 15.3723 16.875 14.75V4.625H1.125V14.75ZM6.75 7.29688C6.75 7.06484 6.93984 6.875 7.17188 6.875H10.8281C11.0602 6.875 11.25 7.06484 11.25 7.29688V7.57812C11.25 7.81016 11.0602 8 10.8281 8H7.17188C6.93984 8 6.75 7.81016 6.75 7.57812V7.29688ZM16.875 0.125H1.125C0.502734 0.125 0 0.627734 0 1.25V2.9375C0 3.24688 0.253125 3.5 0.5625 3.5H17.4375C17.7469 3.5 18 3.24688 18 2.9375V1.25C18 0.627734 17.4973 0.125 16.875 0.125Z"
      fill="inherit" />
  </svg>
</template>
