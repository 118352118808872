<template>
  <section class="ifs-order-line-list">
    <template
      v-for="orderLine of getIFSOrderLines"
      :key="orderLine.ORDER_LINE_ID">
      <IFSOrderLineListItem
        ref="orderLineListItem"
        :order-line="orderLine"
        @click="$emit('onListItemClick', orderLine)" />
    </template>
  </section>
  <footer v-if="isMobile" class="ifs-order-line-download-btn">
    <IFSOrderDownload :order="order" />
  </footer>
</template>
<script>
import IFSOrderLineListItem from '@/components/Orders/OrdersSummary/IFSOrder/IFSOrderLineListItem.vue';
import IFSOrderDownload from './IFSOrderDownload.vue';

export default {
  name: 'IFSOrderLineList',
  components: { IFSOrderLineListItem, IFSOrderDownload },
  inject: ['isMobile'],
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  emits: ['onListItemClick'],
  data() {
    return {
      isExpanded: false,
      maxHeight: 0,
    };
  },
  computed: {
    getIFSOrderLines() {
      return this.order?.ORDER_LINES;
    },
  },
  mounted() {
    if (this.getIFSOrderLines?.length <= 1) {
      this.$refs.orderLineListItem[0].expandItem();
    }
  },
};
</script>
<style lang="scss" scoped>
@import '../../../../styles/main.scss';

.ifs-order-line-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;
  min-width: 500px;
  height: 634px;
  padding: 16px 24px;
}

@media screen and (max-width: $bp-sm) {
  .ifs-order-line-list {
    min-width: 0;
    padding: 16px;
    max-height: 100%;
    height: unset;
    gap: 16px;
    margin-block-end: 50px;
  }
  .ifs-order-line-download-btn {
    position: fixed;
    bottom: 0;
    width: calc(100% - 32px);
    margin: 16px;
  }
}
</style>
