import { fetchJSON } from '.';
import { inMemoryCache } from '@/utils/cacheing/inMemoryCache.js';

export async function getPersonMapping(code) {
  let result;
  const cachedMapping = inMemoryCache.get(code);
  if (cachedMapping) return cachedMapping;

  const url = new URL(
    process.env.VUE_APP_API_URL + '/person-mapping?code=' + code
  );

  try {
    result = await fetchJSON(url, 'get');

    const fiveMinutesExpiration = new Date(Date.now() + 5 * 60 * 1000);
    inMemoryCache.set(code, result, fiveMinutesExpiration);
    return result;
  } catch (ex) {
    console.error('Unable to fetch person mapping!', {
      code,
      error: ex,
    });

    return;
  }
}
