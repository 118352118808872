import { fetchJSON } from '.';
import { clearEmptyProperties, isEmptyObject } from '../utils/utils';

export async function getCustomers(params) {
  const url = new URL(process.env.VUE_APP_API_URL + '/order-customers');
  if (params) {
    params = clearEmptyProperties(params);
    if (!isEmptyObject(params)) url.search = new URLSearchParams(params);
  }

  try {
    const result = await fetchJSON(url, 'get');
    return result;
  } catch (ex) {
    console.error(ex.message);
  }

  return { orders: [], hits: {} };
}

export async function setVipCustomer(body) {
  const url = new URL(process.env.VUE_APP_API_URL + '/customers/vip');
  try {
    const result = await fetchJSON(url, 'put', JSON.stringify(body));
    return result;
  } catch (err) {
    console.error(err.message);
  }
}
