/**
 * This transforms a order line into an array of sections that can be used to render the order line
 * @param {Object} orderLine order line
 */
export function transformOrderLineToSections(orderLine) {
  if (!orderLine) {
    return [];
  }

  const sections = [
    {
      CONTRACT: orderLine.CONTRACT,
      ORDER_ID: orderLine.ORDER_ID,
      ORDER_DATE_ENTERED: orderLine.ORDER_DATE_ENTERED,
      DATE_ENTERED: orderLine.DATE_ENTERED,
      CUSTOMER_NO: orderLine.CUSTOMER_NO,
      WANTED_DELIVERY_DATE: orderLine.WANTED_DELIVERY_DATE,
      ORDER_WANTED_DELIVERY_DATE: orderLine.ORDER_WANTED_DELIVERY_DATE,
    },
    {
      PLANNED_DUE_DATE: orderLine.PLANNED_DUE_DATE,
      PLANNED_SHIP_DATE: orderLine.PLANNED_SHIP_DATE,
      FIRST_ACTUAL_SHIP_DATE: orderLine.FIRST_ACTUAL_SHIP_DATE,
      REAL_SHIP_DATE: orderLine.REAL_SHIP_DATE,
      PLANNED_DELIVERY_DATE: orderLine.PLANNED_DELIVERY_DATE,
      PROMISED_DELIVERY_DATE: orderLine.PROMISED_DELIVERY_DATE,
      TARGET_DATE: orderLine.TARGET_DATE,
      OBJSTATE: orderLine.OBJSTATE,
    },
    {
      ADDR_COMPANY_NAME: orderLine.ADDR_COMPANY_NAME,
      'ADDRESS 1': orderLine.ADDRESS1,
      'ADDRESS 2': orderLine.ADDRESS2,
      ZIP_CODE: orderLine.ZIP_CODE,
      CITY: orderLine.CITY,
      COUNTRY_DESC: orderLine.COUNTRY_DESC,
      COUNTRY_CODE: orderLine.COUNTRY_CODE,
      COMPANY_CURRENCY_CODE: orderLine.COMPANY_CURRENCY_CODE,
    },
    {
      ORDER_NO: orderLine.ORDER_NO,
      ORDER_TYPE: orderLine.ORDER_TYPE,
      ORDER_TYPE_DESC: orderLine.ORDER_TYPE_DESC,
      SUPPLY_CODE: orderLine.SUPPLY_CODE,
      SUPPLY_CODE_DB: orderLine.SUPPLY_CODE_DB,
    },
    {
      CATALOG_NO: orderLine.CATALOG_NO,
      CATALOG_DESC: orderLine.CATALOG_DESC,
      CATALOGUE_TYPE: orderLine.CATALOGUE_TYPE,
      CATALOG_TYPE_DB: orderLine.CATALOG_TYPE_DB,
      BASE_SALES_UNIT_PRICE: orderLine.BASE_SALES_UNIT_PRICE,
      CURRENCY_CODE: orderLine.CURRENCY_CODE,
      SALES_QTY: orderLine.SALES_QTY,
      SALES_UNIT_MEAS: orderLine.SALES_UNIT_MEAS,
    },
    {
      DISCOUNT: orderLine.DISCOUNT,
      ADDITIONAL_DISCOUNT: orderLine.ADDITIONAL_DISCOUNT,
      SALE_UNIT_PRICE: orderLine.SALE_UNIT_PRICE,
      SALESMAN_CODE: orderLine.SALESMAN_CODE,
      SALESMAN_DESC: orderLine.SALESMAN_DESC,
    },
    {
      LINE_ITEM_NO: orderLine.LINE_ITEM_NO,
      LINE_NO: orderLine.LINE_NO,
      ORDER_LINE_ID: orderLine.ORDER_LINE_ID,
      REL_NO: orderLine.REL_NO,
      ADDR_FLAG_DB: orderLine.ADDR_FLAG_DB,
    },
    {
      LAST_UPDATED: orderLine.LAST_UPDATED,
      UPDATED_BY: orderLine.UPDATED_BY,
    },
  ];

  return sections.map((section) => {
    return Object.entries(section);
  });
}
