<template>
  <section class="dashboard-filter">
    <header class="dashboard-filter__header filter-header">
      <template v-if="isMobileStyle">
        <i @click="onNavigateBack">
          <Dropdown class="rotate-90" style="height: 18px; width: 10.29px" />
        </i>
        <span class="filter-header__title"> Filter </span>
        <button class="clr-btn" @click="onClearAll">Clear all</button>
      </template>
      <div v-else class="flex flex-column">
        <span class="filter-header__title">Dashboard</span>
      </div>
    </header>
    <aside
      v-if="!isMobileStyle && getCode"
      class="dashboard-filter__side-cntrller filter-side-cntrl">
      <mm-segmented-btn-group
        width="322px"
        :is-loading="!isFinishInitialize"
        :selected="selectedView"
        @mm-segmented-btn-change="onToggleSwitch">
        <mm-segmented-btn-item
          v-for="btn in segmentedButtons"
          :key="btn.value"
          :value="btn.value">
          <span>{{ btn.title }}</span>
        </mm-segmented-btn-item>
      </mm-segmented-btn-group>
    </aside>
    <main v-if="isMobileStyle" class="dashboard-filter__body filter-body">
      <article
        class="filter-body__my-orders my-orders"
        :class="{ 'my-orders--disabled': !isPersonalStatistics }">
        <header class="my-orders__title">
          <span>Only show my orders on the dashboard</span>
        </header>
        <main class="my-orders__body">
          <span>
            The dashboard will be filtered to only show orders where you are the
            Sales person or Coordinator.
          </span>
        </main>
        <aside class="my-orders__controller">
          <mymunters-custom-switch
            :aria-checked="isPersonalStatistics"
            :is-checked="isPersonalStatistics"
            @switch-toggled="onToggleSwitch" />
        </aside>
      </article>
    </main>
    <footer v-if="isMobileStyle" class="dashboard-filter__footer filter-footer">
      <button class="close-btn" @click="onNavigateBack">Close</button>
    </footer>
  </section>
</template>
<script>
import { mapState, mapWritableState } from 'pinia';

import { useStatisticsStore } from '@/store/statisticsStore';
import { useUserStore } from '@/store/userStore';

import Dropdown from '@/components/shared/Icons/Dropdown.vue';

export default {
  name: 'DashboardFilter',
  components: { Dropdown },
  inject: ['isMobile', 'isTablet', 'isMidLaptop'],
  emits: ['onNavigateBack', 'onSwitchValueChange'],
  computed: {
    ...mapState(useUserStore, ['getCode']),
    ...mapState(useStatisticsStore, ['isFinishInitialize']),
    ...mapWritableState(useStatisticsStore, ['isPersonalStatistics']),
    switchTransformStyle() {
      return this.isPersonalStatistics
        ? 'translateX(-100%) translateY(-55%)'
        : 'translateY(-55%)';
    },
    segmentedButtons() {
      return [
        {
          value: 'bu',
          title: ' Business Unit view',
        },
        {
          value: 'personal',
          title: 'Personal view',
        },
      ];
    },
    selectedView() {
      return this.isPersonalStatistics ? 'personal' : 'bu';
    },
    isMobileStyle() {
      return this.isMobile || this.isTablet;
    },
  },
  methods: {
    onNavigateBack() {
      this.$emit('onNavigateBack');
    },
    onToggleSwitch({ detail }) {
      let isPersonal = false;

      if (typeof detail === 'boolean') {
        // custom switch
        isPersonal = detail;
      } else {
        // segmented switch
        isPersonal = detail === 'personal';
      }

      this.isPersonalStatistics = isPersonal;
      this.$emit('onSwitchValueChange', isPersonal);
    },
    onClearAll() {
      this.isPersonalStatistics = false;
      this.$emit('onSwitchValueChange', false);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../../../styles/main.scss';

.dashboard-filter {
  display: flex;
  place-items: center;
  place-content: space-between;
  background-color: $white;
  padding: 24px;
  z-index: 9;
}

.filter-header {
  &__title {
    font-weight: 450;
    font-size: 40px;
  }
}

.filter-side-cntrl {
  display: flex;
  gap: 8px;
  font-size: 14px;
  align-items: center;
}

@media (max-width: $bp-md) {
  .dashboard-filter {
    position: absolute;
    display: block;
    top: 0;
    height: 100%;
    width: 100%;
    padding: 0;
    z-index: 1;

    &__header {
      position: relative;
      display: flex;
      height: 64px;
      align-items: center;
      justify-content: space-between;
      padding-inline: 32px;
    }

    &__body {
      padding: 0 24px 16px 24px;
    }

    &__footer {
      display: flex;
      justify-content: center;
    }
  }

  .filter-header {
    .clr-btn {
      background-color: transparent;
      border: 0px;
      font-size: 16px;
    }

    &__title {
      position: absolute;
      left: 0;
      display: flex;
      justify-content: center;
      width: 100%;
      font-weight: 450;
      font-size: 24px;
      z-index: -1;
    }
  }

  .my-orders {
    display: grid;
    grid-template-columns: 1fr auto;
    padding: 16px;
    border: 1px solid #969696;
    border-radius: 8px;

    &--disabled {
      color: #757575;
    }

    &__title {
      grid-column: 1 / 2;
      font-weight: 450;
      font-size: 16px;
    }

    &__body {
      grid-column: 1 / 2;
    }

    &__controller {
      grid-column: 2 / 3;
      grid-row: 1 / 2;
      justify-self: flex-end;
    }
  }

  .filter-footer {
    .close-btn {
      background-color: transparent;
      border: 0px;
      font-size: 16px;
    }
  }
}
</style>
