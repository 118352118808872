import { defineStore } from 'pinia';
import { getGroupById } from '../api';

export const useUserStore = defineStore('UserStore', {
  state: () => {
    return {
      user: {},
    };
  },
  getters: {
    canUserFlag: (state) => state.user.canUserFlag,
    canUserWrite: (state) => state.user.canUserWrite,
    getName: (state) => `${state.user.firstName} ${state.user.lastName}`,
    getCode: (state) => state.user.personCode,
    getId: (state) => state.user.userId,
    getRegion: (state) => state.user.region || state.user.country,
    isAuthorized: (state) => state.user.authorized,
    isInternal: (state) => state.user.internal,
    vipPermission: (state) => state.user.vipPermission,
    isExternal: (state) => !state.user.internal,
    isEndCustomer: (state) => state.user.endCustomer,
    isSuperAdmin: (state) => state.user.isSuperAdmin,
    isAdmin: (state) => state.user.isAdmin,
    isReseller: (state) => state.user.isReseller,
    getUserType: (state) => {
      if (state.isInternal) return 'INTERNAL';
      else if (state.isReseller) return 'RESELLER';
      else if (state.isEndCustomer) return 'END_CUSTOMER';
      else return 'EXTERNAL';
    },
  },
  actions: {
    async setUser() {
      const topbar = document.querySelector('mymunters-topbar');
      const user = await topbar.getUserInfo();
      const group = await getGroupById(user.groupId);

      const internal = group.type === 'internal';
      const endCustomer = group.subtype === 'END_CUSTOMER';
      const isReseller = group.subtype === 'RESELLER';

      const hasPermissions = (...permissions) =>
        user.permissions.some((p) =>
          permissions.reduce(
            (oldVal, newVal) => oldVal || p.match(newVal),
            false
          )
        );

      this.user = {
        ...user,
        canUserFlag: hasPermissions('eta:flag'),
        canUserWrite: hasPermissions('eta:write'),
        isSuperAdmin: hasPermissions('iam:super_admin'),
        isAdmin: hasPermissions('iam:admin'),
        authorized: hasPermissions('eta:read', 'eta:write'),
        vipPermission: hasPermissions('eta:vip'),
        endCustomer,
        internal,
        isReseller,
      };
    },
  },
});
