import { cloneDeep } from './utils';

/**
 * Removes the query param from the given route and returns
 * a new path with the updated query parameters. \
 * ***NOTE: Will mutate the existing router!***
 * @param {Router} router Router
 * @param {string[]} keys Key to delete
 * @example deleteQueryParam(to, 'noFetch')
 */
export function deleteQueryParam(router, ...keys) {
  const params = new URLSearchParams(router.query);
  keys.forEach((key) => {
    params.delete(key);

    if (router.query[key]) router.query[key] = undefined;
  });
  const prepend = params.size > 0 ? '?' : '';
  router.fullPath = router.path + prepend + params.toString();
}

/**
 * Removes the query param from the given route and returns
 * a new path with the updated query parameters. \
 * ***NOTE: Will mutate the existing router!***
 * @param {Route} router Router
 * @param {string} key Key to replace value
 * @param {string} replaceWith new value to replace the existing value
 * @example replaceQueryParam(to, 'personal', 'true')
 */
export function replaceQueryParam(router, key, replaceWith) {
  const params = new URLSearchParams(router.query);
  params.set(key, replaceWith);
  if (router.query[key]) router.query[key] = replaceWith;
  if (params.size > 0) router.fullPath = router.path + '?' + params.toString();
}

/**
 * Remove all query parameters and replaces it with the newly passed `params` object \
 * ***NOTE: Will mutate the existing router!***
 * @param {Router} router Base router
 * @param {Object} params optional parameters to set
 */
export function setQueryParams(router, params = {}) {
  const clonedParams = new URLSearchParams(cloneDeep(params));

  router.query = {};
  router.fullPath = router.path;

  const prepend = clonedParams.size > 0 ? '?' : '';
  router.query = Object.fromEntries(clonedParams);
  router.fullPath = router.path + prepend + clonedParams.toString();
}
