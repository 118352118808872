export const trackingUrls = {
  dhl: 'https://dhl.com/se-en/home/tracking/tracking-global-forwarding.html?submit=1&tracking-id=',
  dhlexpress:
    'https://dhl.com/se-en/home/tracking/tracking-global-forwarding.html?submit=1&tracking-id=',
  dhlfreight:
    'https://dhl.com/se-en/home/tracking/tracking-global-forwarding.html?submit=1&tracking-id=',
  dhlglobal:
    'https://dhl.com/se-en/home/tracking/tracking-global-forwarding.html?submit=1&tracking-id=',
  fedex: 'https://www.fedex.com/fedextrack/?trknbr=',
  schenker: 'https://eschenker.dbschenker.com/app/tracking-public/?refNumber=',
  dbschenker:
    'https://eschenker.dbschenker.com/app/tracking-public/?refType=Stt&refNumber=',
  postnord: 'https://www.postnord.se/en/our-tools/track-and-trace?shipmentId=',
  postnordlogistics:
    'https://www.postnord.se/en/our-tools/track-and-trace?shipmentId=',
  tnt: 'https://www.tnt.com/express/en_us/site/tracking.html?searchType=con&cons=',
  undefined: '',
};

export function getTrackingUrl(vchainOrder) {
  if (!vchainOrder) return '';

  const carrierName = vchainOrder.carrier_data?.carrier_name
    ?.toLowerCase()
    .replace(' ', '');

  const orderId = vchainOrder.consignment_no;
  if (!carrierName || !orderId) return '';

  if (carrierName === 'self transport') return '';

  const company = carrierName.split(' ')?.[0];
  const url = trackingUrls[company];

  return url ? url + orderId : '';
}

export const demoTrackingUrls = {
  fedex: 'https://www.fedex.com/en-us/tracking.html',
  tnt: 'https://www.tnt.com/express/en_us/site/tracking.html?searchType=con&cons=',
  postnord: 'https://www.postnord.se/en/our-tools/track-and-trace',
  postnordlogistics: 'https://www.postnord.se/en/our-tools/track-and-trace',
  undefined: '',
};

export function getDemoTrackingLinks(vchainOrder) {
  if (!vchainOrder) return '';

  const carrierName = vchainOrder.carrier_data?.carrier_name
    ?.toLowerCase()
    .replace(' ', '');
  const orderId = vchainOrder.consignment_no;
  if (!carrierName || !orderId) return '';

  if (carrierName === 'self transport') return '';

  const company = carrierName.split(' ')?.[0];
  const url = demoTrackingUrls[company];

  return url;
}

export function hasTrackingNo(vchainOrders) {
  let trackingNo = false;
  if (!vchainOrders || !vchainOrders.length) return trackingNo;
  for (let i = 0; i < vchainOrders.length; i++) {
    if (vchainOrders[i].consignment_no) trackingNo = true;
  }
  return trackingNo;
}

export function getCourier(courier) {
  if (!courier) return 'Not available';
  const self = 'egen transportör';

  if (courier?.toLowerCase() === self) return 'Self transport';
  else return courier;
}

export function getTracking(vchains) {
  if (vchains.length < 2) return getTrackingUrl(vchains?.[0]);

  if (!vchains?.[0]?.status === 'CANCELLED')
    return getTrackingUrl(vchains?.[0]);

  return getTrackingUrl(vchains?.[1]);
}

export function getSplitShipment(vchains) {
  if (vchains?.length < 2) return 0;

  const active = vchains?.filter((v) => v?.status !== 'CANCELLED');

  return active?.length > 1 ? active?.length : 0;
}
