<template>
  <mm-modal
    ref="deliveryCostInformationModal"
    class="delivery-cost-info-modal"
    button-alignment="right"
    close-on-outside-click="false"
    header-text="Delivery cost information"
    modal-margin="0 5%"
    prevent-body-scroll
    use-custom-buttons
    :show-close-icon="!isMobile">
    <div slot="content" class="delivery-cost-info-modal__content modal-content">
      <article v-if="showTruckDeliveryCost">
        <header>Truck delivery cost</header>
        <main>
          In some cases, ETA can only provide a single delivery cost for an
          entire truck that contains multiple orders. As a result, we will
          display the truck's delivery cost as the total delivery cost for all
          orders that will be delivered using that truck. Unfortunately, in
          these cases, we are unable to provide an individual delivery cost for
          each order.
        </main>
      </article>
      <article>
        <header>Split shipment</header>
        <main>
          When an order is divided into multiple shipments, each shipment will
          have its own delivery cost. As soon as a carrier is booked, the
          delivery cost for that particular shipment is added to the total
          delivery cost shown in ETA. Therefore, it's possible that the price
          displayed in ETA only covers a portion of the order's total delivery
          cost.
        </main>
      </article>
      <article>
        <header>Any questions?</header>
        <main>
          If you have any questions regarding the delivery costs of your order,
          please feel free to contact us at
          <a href="mailto:support.mymunters@munters.com">
            support.mymunters@munters.com.
          </a>
        </main>
      </article>
    </div>
    <div
      slot="custom-buttons"
      class="delivery-cost-info-modal__custom-buttons custom-buttons">
      <mymunters-button
        class="close-btn"
        button-type="button-secondary"
        @click.stop="close">
        Close
      </mymunters-button>
    </div>
  </mm-modal>
</template>

<script>
export default {
  name: 'DeliveryCostInformationModal',
  inject: ['isMobile'],
  data() {
    return {
      showTruckDeliveryCost: true,
    };
  },
  computed: {
    getModal() {
      return this.$refs.deliveryCostInformationModal;
    },
  },
  methods: {
    close() {
      this.getModal.close();
    },
    open(showTruckDeliveryCost = true) {
      this.showTruckDeliveryCost = showTruckDeliveryCost;
      this.getModal.open();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/main.scss';

:global(
    .delivery-cost-info-modal__custom-buttons mymunters-button.close-btn .button
  ) {
  display: flex;
  align-items: center;
  padding: 16px;
}

.delivery-cost-info-modal {
  &::part(content) {
    padding: 24px;
  }
}

.modal-content {
  display: flex;
  flex-direction: column;
  gap: 16px;

  article > header {
    font-size: 16px;
    font-weight: 450;
  }

  a {
    color: #007fad;
    text-decoration: none;
  }
}

@media screen and (max-width: $bp-sm) {
  :global(
      .delivery-cost-info-modal__custom-buttons
        mymunters-button.close-btn
        .button
    ) {
    justify-content: center;
  }

  .delivery-cost-info-modal {
    &::part(header) {
      justify-content: center;
      border-bottom: none;
    }

    &::part(content) {
      padding: 0 16px;
    }

    &::part(footer) {
      border-top: none;
    }
  }

  .custom-buttons {
    width: 100%;
  }
}
</style>
