<template>
  <svg
    width="14"
    height="18"
    viewBox="0 0 14 18"
    fill="inherit"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.125 4.78125V0H1.09375C0.626172 0 0.25 0.376172 0.25 0.84375V17.1562C0.25 17.6238 0.626172 18 1.09375 18H12.9062C13.3738 18 13.75 17.6238 13.75 17.1562V5.625H8.96875C8.50469 5.625 8.125 5.24531 8.125 4.78125ZM10.2379 8.52539L8.125 11.8125L10.2379 15.0996C10.4172 15.3809 10.2168 15.75 9.88281 15.75H8.65586C8.50117 15.75 8.35703 15.6656 8.2832 15.5285C7.59414 14.2559 7 13.1133 7 13.1133C6.775 13.6336 6.64844 13.8164 5.71328 15.532C5.63945 15.6691 5.49883 15.7535 5.34414 15.7535H4.11719C3.7832 15.7535 3.58281 15.3844 3.76211 15.1031L5.88203 11.816L3.76211 8.52891C3.5793 8.24766 3.7832 7.87852 4.11719 7.87852H5.34063C5.49531 7.87852 5.63945 7.96289 5.71328 8.1C6.63086 9.81563 6.41641 9.28125 7 10.5082C7 10.5082 7.21445 10.0969 8.28672 8.1C8.36055 7.96289 8.50469 7.87852 8.65938 7.87852H9.88281C10.2168 7.875 10.4172 8.24414 10.2379 8.52539ZM13.75 4.28555V4.5H9.25V0H9.46445C9.68945 0 9.90391 0.0878906 10.0621 0.246094L13.5039 3.69141C13.6621 3.84961 13.75 4.06406 13.75 4.28555Z"
      fill="inherit" />
  </svg>
</template>
<script>
export default {
  name: 'ExcelIcon',
};
</script>
