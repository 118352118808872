export const customerTypeFilters = [
  {
    title: 'VIP Customers',
    value: 'true',
  },
  {
    title: 'Non VIP Customers',
    value: 'false',
  },
];

export const orderFilterLabels = {
  CHANGED_DATE: 'Changed date',
  COORDINATOR: 'Coordinators',
  CUSTOMER: 'Customer',
  CUSTOMER_COUNTRY: 'Customer country',
  DATE_FROM: 'From',
  DATE_TO: 'To',
  DATE_TYPE: 'Date type',
  DESTINATION: 'Destination',
  ORDER_PROCESS: 'Process',
  ORDER_STATUS: 'Status',
  PRODUCT: 'Products',
  SALESMAN: 'Salesman',
  VIP: 'Customer type',
};
