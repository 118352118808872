import { getSplitShipment } from './tracking';
import { getOrders } from '@/api/orders';
import { getDateDifference, formatDate, dateIntervals } from './datetime';
import { cloneDeep } from './utils';

export const ORDER_PROCESS = {
  AWAITING_FACTORY: 'AWAITING_FACTORY',
  AWAITING_CONFIRMATION: 'AWAITING_CONFIRMATION',
  UNDER_PRODUCTION: 'UNDER_PRODUCTION',
  PRE_TRANSPORT: 'PRE_TRANSPORT',
  IN_TRANSIT: 'IN_TRANSIT',
  PARTIALLY_DELIVERED: 'PARTIALLY_DELIVERED',
  MULTIPLE_DELIVERIES: 'MULTIPLE_DELIVERIES',
  DELIVERED: 'DELIVERED',
  CANCELLED: 'CANCELLED',
  DELIVERY_CANCELLED: 'DELIVERY_CANCELLED',
};

export const ORDER_STATUS = {
  PENDING: 'PENDING',
  ATTENTION: 'ATTENTION',
  DELAYED: 'DELAYED',
  ON_TIME: 'ON_TIME',
  DELIVERED: 'DELIVERED',
  CANCELLED: 'CANCELLED',
};

export const DELAYED_TAB_STATUS = `${ORDER_STATUS.DELAYED} ${ORDER_STATUS.ATTENTION}`;

export const CHANGED_DATE_LABELS = {
  PLANNED_DELIVERY_DATE: 'Factory ETA',
  WANTED_DELIVERY_DATE: 'Desired Delivery',
};

export function getChangeMessage(updates, orderNo) {
  const updated = [];
  const intro = `Order ${orderNo} updated: `;
  let message = '';
  for (const diff of updates) {
    if (diff === 'COMMENTS') message = 'Comments have been updated.\n';
    if (diff === 'SHIPPING_IS_BOOKED') message = 'Shipping has been booked.\n';

    if (diff === 'ORDER_STATUS') updated.push('Status');

    if (diff === 'PROCESS') updated.push('Process');
    if (diff === 'ORDER_LINES') updated.push(`IFS order line`);
    if (diff === 'PLANNED_DELIVERY_DATE') updated.push('Factory ETA');
    if (diff === 'WANTED_DELIVERY_DATE') updated.push('Desired delivery date');
    if (diff === 'PLANNED_SHIP_DATE') updated.push('Planned ship date');
    if (diff === 'VCHAIN') updated.push('Shipment information');
    if (diff === 'FLAGGED') updated.push('Flag status');
  }

  if (updated.length) message += updated.join(', ');

  if (!message) return null;
  return intro + message;
}

export function isOrderValueRecentlyUpdated(order, attributeKey) {
  const includePendingKeys = ['FLAGGED'];

  // skip pending orders
  if (
    !includePendingKeys.includes(attributeKey) &&
    order.ORDER_STATUS === ORDER_STATUS.PENDING
  )
    return false;

  const { LAST_UPDATED_DETAILS } = cloneDeep(order);

  if (!LAST_UPDATED_DETAILS) return false;

  const { NEW_VALUE, OLD_VALUE, UPDATED_AT } =
    getOrderUpdatedValueByKey(cloneDeep(order), attributeKey) || {};

  // Skip if old and new values are both undefined/null, or the updated date is not defined
  if ((!NEW_VALUE && !OLD_VALUE) || !UPDATED_AT) return false;

  const isValueChanged = isOrderValueChanged(
    NEW_VALUE,
    OLD_VALUE,
    attributeKey
  );
  if (!isValueChanged) return false;

  const ORDER_UPDATE_INDICATOR = {
    TTL_IN_DAYS: 1,
  };

  const now = new Date();

  const diff = getDateDifference(now, UPDATED_AT);

  // Max config to show the updated values
  const maxDaysToShowUpdatedValue = ORDER_UPDATE_INDICATOR.TTL_IN_DAYS;

  const hoursInDay = 24;
  const msInSecond = 1000;
  const secondsInMinute = 60;
  const minutesInHour = 60;

  const dayInMs = msInSecond * secondsInMinute * minutesInHour * hoursInDay;

  const differenceInDays = parseInt(diff / dayInMs, 10);

  // Show the changes if it hasn't reached the max config
  return differenceInDays < maxDaysToShowUpdatedValue;
}

export function getOrderUpdatedValueByKey(order, key) {
  const { LAST_UPDATED_DETAILS } = order;

  if (!LAST_UPDATED_DETAILS) return {};

  return LAST_UPDATED_DETAILS[key];
}

/**
 * Will format the date values first before comparing.
 * If field to compare is not a date field, will just compare it right away.
 */
export function isOrderValueChanged(newValue, oldValue, key) {
  const DATE_FIELDS = [
    'WANTED_DELIVERY_DATE',
    'VCHAIN_DELIVERY_DATE',
    'PLANNED_DELIVERY_DATE',
    'PLANNED_SHIP_DATE',
  ];

  if (DATE_FIELDS.includes(key)) {
    const oldDate = oldValue?.substring(0, 10);
    const newDate = newValue?.substring(0, 10);

    return newDate !== oldDate;
  } else {
    return newValue !== oldValue;
  }
}

export function getCurrencyText(currency, price, quantity) {
  if (!currency || currency === 'N/A' || !+price) return 'Unavailable';
  const locale = navigator.language ?? 'en-GB';
  const text = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency !== 'Unavailable' ? currency : 'SEK',
  }).format(price * (quantity ?? 1));
  return text;
}

export function getVChainCosts(vchains) {
  if (!vchains || !vchains.length) return false;
  let amount = 0;
  for (let i = 0; i < vchains.length; i++) {
    if (vchains[i].status !== 'CANCELLED') {
      amount += vchains[i]?.price_data?.amount || 0;
    }
  }
  return amount !== 0 ? amount : false;
}

export function getTotalSalesPrice(order) {
  return +order.TOTAL_SALES_PRICE;
}

export function getTotalCosts(order, currencyRate) {
  const currency = order.ORDER_LINES?.[0].SALES?.CURRENCY_CODE;
  const deliveryCostsSEK = getVChainCosts(order.VCHAIN) || 0;
  const deliveryCosts = deliveryCostsSEK * currencyRate || 0;

  const amount = getTotalSalesPrice(order);
  const total = Number(amount) + Number(deliveryCosts);

  return getCurrencyText(currency, total);
}

export function getZipAndCity(address) {
  const zip = address?.ZIP || address?.STATE || null;
  const city = address?.CITY ?? null;
  if (!zip && !city) return 'Unavailable';
  else if (!zip && city) return city;
  else if (zip && !city) return zip;
  return zip + ' ' + city;
}

export function getQuantity(factory, sales) {
  if (sales?.SALES_QTY && sales?.SALES_QTY > 1)
    return `${sales?.SALES_QTY}${sales?.SALES_UNIT_MEAS}`;
  else if (sales?.SALES_QTY && sales?.SALES_QTY === '1')
    return `${sales?.SALES_QTY}${
      sales?.SALES_UNIT_MEAS === 'pcs' ? 'pc' : sales?.SALES_UNIT_MEAS
    }`;
  else if (factory?.SALES_QTY && factory?.SALES_QTY === '1')
    return `${factory?.SALES_QTY}${
      factory?.SALES_UNIT_MEAS === 'pcs' ? 'pc' : factory?.SALES_UNIT_MEAS
    }`;
  else if (factory?.SALES_QTY && factory?.SALES_QTY > 1)
    return `${factory?.SALES_QTY}${factory?.SALES_UNIT_MEAS}`;
}

export function getDeliveryCosts(order, currencyRate) {
  const currency = order?.ORDER_LINES?.[0]?.SALES?.CURRENCY_CODE;
  const amount = getVChainCosts(order?.VCHAIN);
  if (!amount) return false;
  if (currency === 'SEK' || currency === 'N/A')
    return getCurrencyText(
      'SEK',
      Math.round((amount + Number.EPSILON) * 100) / 100
    );
  const rateAmount = amount * currencyRate;
  return getCurrencyText(
    currency,
    Math.round((rateAmount + Number.EPSILON) * 100) / 100
  );
}

export function getCurrency(orderLines) {
  const currency = orderLines?.[0]?.SALES?.CURRENCY_CODE;
  if (!currency) return 'N/A';
  return currency ?? 'N/A';
}

/**
 * Fetches the appropriate headears for the different users
 * @param { { internal, endCustomer, reseller } } payload list of available permissions from the user
 * @returns {{
 *  enabled: Boolean,
 *  default: Boolean
 *  sortable: Boolean,
 *  sortKey: String,
 *  title: String,
 *  width: String
 * }} Returns the generated headers
 */
export const getHeaders = ({ internal, endCustomer, reseller } = {}) => [
  {
    enabled: true,
    sortKey: 'GAP',
    width: '70px',
    sortable: false,
    default: true,
    exportable: false,
  },
  {
    enabled: internal,
    sortKey: 'VIP',
    width: '25px',
    sortable: false,
    default: true,
    exportable: true,
  },
  {
    enabled: internal,
    title: 'Customer',
    sortKey: 'CUSTOMER_NAME',
    width: '200px',
    minWidth: '110px',
    sortable: true,
    default: true,
    resizable: true,
    exportable: true,
  },
  {
    enabled: internal,
    title: 'Customer ID',
    sortKey: 'CUSTOMER_NO',
    width: '125px',
    minWidth: '125px',
    sortable: true,
    default: true,
    resizable: true,
    exportable: true,
  },
  {
    enabled: internal || reseller,
    title: 'Order number',
    tooltip: 'This is the purchase order number from sales and factory.',
    sortKey: 'ORDER_NO',
    width: '155px',
    minWidth: '155px',
    sortable: true,
    default: false,
    resizable: true,
    exportable: true,
  },
  {
    enabled: internal || reseller || endCustomer,
    title: internal || reseller ? 'Sales order number' : 'Order number',
    sortKey: 'SALES_ORDER_NO',
    width: internal || reseller ? '165px' : '155px',
    minWidth: internal || reseller ? '165px' : '155px',
    sortable: true,
    default: true,
    resizable: true,
    exportable: true,
  },
  {
    enabled: internal || reseller,
    title: 'Desired delivery',
    tooltip:
      'The date the sales and customer has agreed upon for the delivery.',
    sortKey: 'WANTED_DELIVERY_DATE',
    width: '165px',
    minWidth: '165px',
    sortable: true,
    default: true,
    resizable: true,
    exportable: true,
  },
  {
    enabled: internal || reseller,
    title: 'Factory ETA',
    tooltip:
      'When factory estimates that the order should arrive to the customer. This date is estimated before the production has started. The exact delivery date is set by the carrier.',
    sortKey: 'PLANNED_DELIVERY_DATE',
    width: '140px',
    minWidth: '140px',
    sortable: true,
    default: true,
    resizable: true,
    exportable: true,
  },
  {
    enabled: internal || reseller,
    title: 'Planned shipping',
    tooltip:
      'When factory estimates that the order should be picked up by carrier.',
    sortKey: 'PLANNED_SHIP_DATE',
    width: '170px',
    minWidth: '170px',
    sortable: true,
    default: false,
    resizable: true,
    exportable: true,
  },
  {
    enabled: internal || reseller,
    title: 'Courier ETA',
    tooltip:
      'The carriers estimation of when the order should arrive. The hyphen (-) means that the courier is not yet booked by factory.',
    sortKey: 'VCHAIN_DELIVERY_DATE',
    width: '140px',
    minWidth: '140px',
    sortable: true,
    default: true,
    resizable: true,
    exportable: true,
  },
  {
    enabled: internal || reseller,
    title: 'Process',
    tooltip: 'Specified process state of the order',
    sortKey: 'PROCESS',
    width: '115px',
    minWidth: '115px',
    sortable: true,
    default: true,
    resizable: true,
    exportable: true,
  },
  {
    enabled: true,
    title: endCustomer ? 'Message' : 'Status',
    tooltip: endCustomer
      ? "Current status of order given the orders ETA's"
      : undefined,
    sortKey: 'ORDER_STATUS',
    width: endCustomer ? '365px' : '120px',
    minWidth: endCustomer ? '365px' : '120px',
    sortable: true,
    default: true,
    resizable: true,
    exportable: true,
  },
  {
    enabled: internal || reseller,
    title: 'Customer PO number',
    sortKey: 'CUSTOMER_PO_NO',
    width: '175px',
    minWidth: '175px',
    sortable: true,
    default: reseller,
    resizable: true,
    exportable: true,
  },
  {
    enabled: internal,
    title: 'Total Sales Price',
    sortKey: 'TOTAL_SALES_PRICE',
    width: '150px',
    minWidth: '150px',
    sortable: true,
    default: true,
    resizable: true,
    exportable: true,
  },
  {
    enabled: true,
    title: 'Last updated',
    sortKey: 'LAST_UPDATED',
    width: '130px',
    minWidth: '130px',
    sortable: true,
    default: true,
    resizable: true,
    exportable: true,
  },
  {
    enabled: internal,
    title: 'Action',
    width: '200px',
    sortKey: 'ACTION',
    sortable: true,
    default: true,
    exportable: false,
  },
  // This column will stretch and take up extra space so that table is aligned to the left
  {
    enabled: true,
    width: '100%',
    sortKey: 'STRETCH',
    sortable: false,
    default: true,
    exportable: false,
  },
];

/**
 * Transform columns as dropdown objects
 * @param  {ReturnType<getHeaders>} columns
 * @returns { {title: String, value: String}[] }
 */
export function transformColumnsAsOptions(...columns) {
  return columns
    .filter((item) => item.title)
    .map((v) => ({ title: v.title, value: v.sortKey }));
}

/**
 * Fetch the appropriate background color for mobile based on the status passed
 * @param {string} status Order Status
 */
export function getBackgroundColorFromStatus(status) {
  switch (status) {
    case 'ATTENTION':
    case 'DELAYED':
      return '#fff4d6';
    case 'CANCELLED':
      return '#ffede5';
    case 'DELIVERED':
      return '#e4f1e9';
    case 'ON_TIME':
      return '#ebfaff';
    case 'PENDING':
      return '#ececec';
    default:
      throw new Error('Status is invalid.');
  }
}

export function isGermanOrder(order) {
  const germanContracts = ['H1000', 'H1100'];
  return germanContracts.includes(order?.CONTRACT);
}

export function hasSplitShipment(order) {
  return !!getSplitShipment(order?.VCHAIN);
}

export function transformSavedOrderFilters(filter) {
  const getInterval = (selectedInterval) =>
    dateIntervals.find((interval) => interval.key === selectedInterval).value;
  const currentFormattedDate = formatDate(new Date(), 'YYYY-MMo-DDo');

  const transformed = {
    countries: filter.CUSTOMER_COUNTRY || [],
    destination: filter.DESTINATION || [],
    dateFrom: filter.DATE_INTERVAL
      ? getInterval(filter.DATE_INTERVAL)
      : filter.DATE_FROM || '',
    dateTo: filter.DATE_INTERVAL ? currentFormattedDate : filter.DATE_TO || '',
    dateType: filter.DATE_TYPE || '',
    orderProcess: filter.ORDER_PROCESS || '',
    status: filter.ORDER_STATUS || '',
    currentDateInterval: filter.DATE_INTERVAL || '',
    search: filter.SEARCH || '',
    currentPage: 1,
    salesman: filter.SALESMAN || [],
    coordinator: filter.COORDINATOR || [],
    customers: filter.CUSTOMER || [],
    VIP: filter.VIP || undefined,
    products: filter.PRODUCT || [],
    updatedETA: filter.CHANGED_DATE || [],
  };

  return transformed;
}

export async function fetchOrderLimits(dateType) {
  // Fetch limits for the selected date type
  const { limits } = await getOrders({
    show: 0,
    dateType,
  });
  return limits;
}
