<template>
  <div class="nav-wrapper">
    <div
      v-if="!isNavbarHidden || isMobileStyle"
      class="nav-container"
      :class="{ open: dropdownOpen, 'scrollbar-on-hover': !isMobileStyle }">
      <div
        v-if="isMobileStyle"
        ref="mobileRouterLink"
        class="mobile-router-link-block"
        @click="toggleDropdown">
        <span>{{ currentMobileRoute }}</span>
        <Dropdown
          v-if="!isDashboard"
          class="dropdown-icon"
          :class="{ 'dropdown-icon--open': dropdownOpen }"></Dropdown>
      </div>
      <div v-if="dropdownOpen" class="dropdown-break-line"></div>

      <aside
        v-if="isInternal"
        v-show="!isMobileStyle || dropdownOpen"
        v-click-outside="closeDropdown"
        class="nav-container__router-links-container"
        @click="toggleClickability">
        <router-link to="/orders" @click="dropdownOpen = false">
          <AllOrdersIcon class="nav-icon" />
          <span>{{ INTERNAL_USER_ALL_ORDERS_LABEL }}</span>
        </router-link>

        <router-link to="/flagged" @click="dropdownOpen = false">
          <FlaggedIcon class="nav-icon" style="margin-bottom: 0" />
          <span>Flagged orders</span>
        </router-link>

        <router-link to="/monitored" @click="dropdownOpen = false">
          <MonitoredIcon class="nav-icon" />
          <span>Monitored orders</span>
        </router-link>

        <router-link to="/archived" @click="dropdownOpen = false">
          <ArchiveIcon class="nav-icon" />
          <span>Archived orders</span>
        </router-link>
      </aside>
      <aside
        v-else
        v-show="!isMobileStyle || dropdownOpen"
        v-click-outside="closeDropdown"
        class="nav-container__router-links-container">
        <router-link to="/orders" @click="dropdownOpen = false">
          <AllOrdersIcon class="nav-icon" />
          <span>My orders</span>
        </router-link>
        <router-link to="/archived" @click="dropdownOpen = false">
          <ArchiveIcon class="nav-icon" />
          <span>Archived orders</span>
        </router-link>
      </aside>

      <!-- Orders Filter -->
      <div
        v-if="isMobileStyle && !isDashboard"
        class="nav-container__search-filter-container">
        <button class="icon-button" @click.stop="toggleSearch">
          <SearchIcon />
        </button>
        <button
          v-if="!isArchivedOrdersTab && !isMonitoredOrdersTab && !isEndCustomer"
          class="icon-button"
          :active="isFilterActive"
          @click.stop="toggleFilter">
          <FilterIcon />
        </button>
        <OrderListTabletColumnsSelector
          v-if="
            isTablet &&
            !isArchivedOrdersTab &&
            !isMonitoredOrdersTab &&
            !isEndCustomer
          " />
      </div>
      <!-- Dashboard Filter -->
      <div v-else-if="isMobileStyle && code">
        <button
          class="icon-button"
          :active="isPersonalStatistics"
          @click.stop="toggleFilter">
          <FilterIcon />
        </button>
      </div>
    </div>
    <div v-show="isMobileStyle && searchOpen" class="search-wrapper">
      <mymunters-search-bar
        ref="searchBar"
        class="search-bar"
        :search-value="searchValue"
        placeholder="I'm looking for..."
        @input-changed="onSearch" />
    </div>
  </div>
  <DashboardFilter
    v-if="isDashboard"
    v-show="!isMobileStyle || showDashboardFilter"
    @on-navigate-back="toggleFilter"
    @on-switch-value-change="togglePersonalStatistics" />
</template>

<script>
import { mapActions, mapState, mapWritableState } from 'pinia';
import { defineComponent } from 'vue';

import { INTERNAL_USER_ALL_ORDERS_LABEL, ROUTE_NAMES } from '@/router';
import { useUserStore } from '@/store/userStore';
import { useUiStore } from '@/store/uiStore';
import { useStatisticsStore } from '@/store/statisticsStore';

import MonitoredIcon from '@/components/shared/Icons/MonitoredIcon.vue';
import FlaggedIcon from '@/components/shared/Icons/FlaggedIcon.vue';
import Dropdown from '@/components/shared/Icons/Dropdown.vue';
import FilterIcon from '@/components/shared/Icons/FilterIcon.vue';
import SearchIcon from '@/components/shared/Icons/SearchIcon.vue';
import AllOrdersIcon from '@/components/shared/Icons/AllOrdersIcon.vue';
import ArchiveIcon from '@/components/shared/Icons/ArchiveIcon.vue';
import DashboardFilter from '@/components/Dashboard/DashboardFilter/DashboardFilter.vue';
import OrderListTabletColumnsSelector from '@/components/NavBar/OrderListTabletColumnsSelector.vue';

export default defineComponent({
  name: 'NavBar',
  components: {
    MonitoredIcon,
    FlaggedIcon,
    Dropdown,
    FilterIcon,
    SearchIcon,
    AllOrdersIcon,
    ArchiveIcon,
    DashboardFilter,
    OrderListTabletColumnsSelector,
  },
  inject: [
    'isMobile',
    'isTablet',
    'isMidLaptop',
    'isDashboard',
    'navMenuWidth',
    'containerWidth',
    'isMonitoredOrdersTab',
    'isArchivedOrdersTab',
    'emitter',
  ],
  emits: ['search'],
  data() {
    return {
      monitoredSelected: false,
      selected: false,
      dropdownOpen: false,
      selectedRoute: ROUTE_NAMES.DASHBOARD,
      routeTo: '/dashboard',
      showDashboardFilter: false,
      INTERNAL_USER_ALL_ORDERS_LABEL,
      isNavbarHidden: false,
    };
  },

  computed: {
    ...mapState(useUserStore, {
      isEndCustomer: 'isEndCustomer',
      isInternal: 'isInternal',
      code: 'getCode',
    }),
    ...mapState(useUiStore, {
      isFilterActive: 'isFilterActive',
      searchValue: (state) => state.filters.search,
    }),
    ...mapWritableState(useUiStore, ['searchOpen']),
    ...mapWritableState(useStatisticsStore, ['isPersonalStatistics']),

    getContainerWidthInPixel() {
      return this.containerWidth + 'px';
    },
    getNavMenuWidthInPixel() {
      return this.navMenuWidth + 'px';
    },
    isMobileStyle() {
      return this.isMobile || this.isTablet;
    },
    mobileStyleAttributes() {
      return `${this.isMobile}|${this.isTablet}`;
    },
    currentMobileRoute() {
      if (this.$route.name === ROUTE_NAMES.ALL_ORDERS && this.isInternal) {
        return this.INTERNAL_USER_ALL_ORDERS_LABEL;
      }

      return this.$route.name;
    },
  },
  watch: {
    mobileStyleAttributes() {
      if (!this.isMobileStyle) {
        this.showDashboardFilter = false;
      }
    },
    $route() {
      this.isNavbarHidden = this.$route.path.includes('/dashboard');
    },
  },
  created() {
    const currentPathName = new URL(window.location.href).pathname;
    this.isNavbarHidden = !currentPathName.includes('/orders');
  },
  methods: {
    ...mapActions(useStatisticsStore, {
      rerenderStatistics: 'init',
    }),
    ...mapActions(useUiStore, ['toggleFilterState']),
    toggleClickability() {
      this.toggleClickabilityOff();
      setTimeout(() => this.toggleClickabilityOn(), 500);
    },
    toggleClickabilityOff() {
      const childs = document.getElementsByClassName(
        'nav-container__router-links-container'
      )[0].children;
      for (let i = 0; i < childs.length; i++) {
        childs[i].style.pointerEvents = 'none';
      }
    },
    toggleClickabilityOn() {
      const childs = document.getElementsByClassName(
        'nav-container__router-links-container'
      )[0].children;
      for (let i = 0; i < childs.length; i++) {
        childs[i].style.pointerEvents = 'auto';
      }
    },

    toggleFilter() {
      if (this.isDashboard) {
        this.showDashboardFilter = !this.showDashboardFilter;
        this.toggleFilterState();
        return;
      }

      if (this.searchOpen) this.searchOpen = false;
      if (this.dropdownOpen) this.dropdownOpen = false;
      this.toggleFilterState();
    },
    toggleDropdown() {
      if (!this.isMobileStyle || this.isDashboard) return;
      if (this.searchOpen) this.searchOpen = false;
      this.toggleFilterState(false);
      this.dropdownOpen = !this.dropdownOpen;
    },
    closeDropdown(e) {
      if (!e.composedPath()?.some((p) => p === this.$refs.mobileRouterLink)) {
        this.dropdownOpen = false;
      }
    },
    toggleSearch() {
      if (this.dropdownOpen) this.dropdownOpen = false;
      this.toggleFilterState(false);
      this.searchOpen = !this.searchOpen;
    },

    onSearch(event) {
      this.emitter.emit('onSearch', event);
    },

    // Dashboard
    async updateQueryParams(query, replace = true) {
      if (replace) await this.$router.replace({ query });
      else await this.$router.push({ query });
    },

    async togglePersonalStatistics(checked) {
      this.isPersonalStatistics = checked;

      await this.updateQueryParams({ personal: checked });
      await this.rerenderStatistics();
    },
  },
});
</script>

<style lang="scss">
@import '../../styles/main.scss';

.nav-container {
  position: sticky;
  left: v-bind(getNavMenuWidthInPixel);
  display: flex;
  align-items: center;
  width: 100%;
  max-width: v-bind(getContainerWidthInPixel);
  height: 80px;
  background: white;
  padding-left: 2.5rem;
  padding-right: 5rem;
  font-size: 1.25rem;
  border-bottom: 2px solid #ececec;

  a {
    display: none;
    position: relative;
    display: grid;
    grid-template-columns: auto 1fr;
    justify-content: center;
    align-items: center;
    margin: auto 1px;
    gap: 10px;
    text-decoration: none;
    color: #8a8a8a;
    transition: all $trans-fast;
    white-space: nowrap;

    &::after {
      content: '';
      width: 100%;
      height: 5px;
      position: absolute;
      bottom: -8px;
      left: 0;
      background: transparent;
      transition: all $trans-fast;
      pointer-events: none;
    }

    &.router-link-active,
    &:hover {
      color: $text-dark;

      .nav-icon {
        fill: $text-dark;
      }

      &::after {
        background: $m-blue-lighter;
      }
    }

    .nav-icon {
      width: 22px;
      height: 22px;
      fill: #8a8a8a;
      transition: all $trans-fast;
      margin-bottom: 2px;
    }
  }

  &__router-links-container {
    display: flex;
    align-items: center;
    gap: 3rem;
  }
}

@media screen and (max-width: $bp-md) {
  .nav-container {
    height: 64px;
    padding: 0 23px 0 32px;
    z-index: 1;
    justify-content: space-between;
    position: unset;

    & > a::after {
      display: none;
    }

    &__router-links-container {
      position: absolute;
      top: 60px;
      left: 16px;
      display: flex;
      flex-direction: column;
      gap: 0;
      height: fit-content;
      width: 161px;
      background-color: $white;
      padding-block: 8px;
      font-size: 16px;
      border: 1px solid #ececec;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: $border-radius-md;
      z-index: 100;

      a {
        position: relative;
        display: flex;
        justify-content: flex-start;
        height: 40px;
        width: 100%;
        padding: 8px 12px;
        color: $black;

        &::after {
          transition: none;
        }

        &:hover::after {
          display: none;
        }
      }

      .router-link-active {
        font-weight: 450;

        &::after {
          content: '';
          width: 3px;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: $m-blue-lighter;
          pointer-events: none;
        }

        &:hover::after {
          display: block;
          background-color: $m-blue-lighter;
        }
      }

      .nav-icon {
        display: none;
      }
    }

    &__search-filter-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      gap: 16px;
    }

    .mobile-router-link-block {
      display: flex;
      flex-wrap: nowrap;
      align-items: baseline;
      gap: 0.56rem;
      min-width: fit-content;
      font-weight: 450;
      white-space: nowrap;
    }
  }
  .search-wrapper {
    margin: 0 0.5rem;
  }
}

//* Fix weird layout when transitioning from tablet to laptop
@media screen and (width: calc($bp-md + 1px)) {
  .nav-container {
    justify-content: space-between;
  }
}
</style>
