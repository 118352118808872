export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export const MONTHS_SHORT = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
export const DAYS_OF_THE_WEEK = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export function toISODate(date) {
  if (!(date instanceof Date)) {
    return;
  }

  return date.toISOString().substring(0, 10);
}

export function createDateAsUTC(date) {
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  );
}

export function getDateDifference(a, b) {
  return new Date(a).getTime() - new Date(b).getTime();
}

/**
 * Formats the date into the specified pattern
 * @param {Date} d
 * @param {String} pattern
 */
export function formatDate(d, pattern = 'ISO_DATE', ordinal = false) {
  const dateSelected = new Date(d);
  const addAppendedOrdinal = (date) => {
    let result = date.toString();

    if (ordinal) {
      if (result.endsWith('1') && !result.endsWith('11')) {
        result += 'st';
      } else if (result.endsWith('2') && !result.endsWith('12')) {
        result += 'nd';
      } else if (result.endsWith('3') && !result.endsWith('13')) {
        result += 'rd';
      } else {
        result += 'th';
      }
    }

    return result;
  };
  let formatted = pattern.replace(
    /\b(D{1,2})\b/,
    addAppendedOrdinal(dateSelected.getDate())
  ); // D or DD = 1..31
  formatted = formatted.replace(
    /\b(D{1,2}o)\b/,
    dateSelected.getDate() < 10
      ? '0' + dateSelected.getDate()
      : dateSelected.getDate()
  ); // Do or DDo = 01..31
  formatted = formatted.replace(
    /\b(d{4})\b/,
    DAYS_OF_THE_WEEK[dateSelected.getDay()]
  ); // dddd = Monday
  formatted = formatted.replace(/\b(Y{4})\b/, dateSelected.getFullYear()); // YYYY = 2022
  formatted = formatted.replace(
    /\b(d{3})\b/,
    DAYS_OF_THE_WEEK[dateSelected.getDay()]?.substring(0, 3)
  ); // ddd = Mon
  formatted = formatted.replace(/\b(M{1,2})\b/, dateSelected.getMonth() + 1); // M or MM = 1..12
  formatted = formatted.replace(/\b(M{1,2}o)\b/, () => {
    const month = dateSelected.getMonth() + 1;
    return month < 10 ? '0' + month : month;
  }); // Mo or MMo = 01..12
  formatted = formatted.replace(/\b(M{4})\b/, MONTHS[dateSelected.getMonth()]); // MMMM = January
  formatted = formatted.replace(
    /\b(M{3})\b/,
    MONTHS[dateSelected.getMonth()]?.substring(0, 3)
  ); // MMM = Jan
  formatted = formatted.replace(/\b(H{2})\b/, () => {
    const hours = dateSelected.getHours();
    const toRegularTime = hours > 12 ? hours % 12 : hours;
    return toRegularTime < 10 ? '0' + toRegularTime : toRegularTime;
  }); // HH = hours 1-12
  formatted = formatted.replace(/\b(AMPM)\b/, () => {
    const hours = dateSelected.getHours();
    return hours / 12 > 1 ? 'PM' : 'AM';
  }); // AMPM = AM/PM
  formatted = formatted.replace(/\b(h{2})\b/, () => {
    const hours = dateSelected.getHours();
    return hours < 10 ? '0' + hours : hours;
  }); // hh = hours 1-24
  formatted = formatted.replace(/\b(m{2})\b/, () => {
    const minutes = dateSelected.getMinutes();
    return minutes < 10 ? '0' + minutes : minutes;
  }); // mm = minutes
  formatted = formatted.replace(/\b(ISO_DATE)\b/, () => {
    const month = dateSelected.getMonth() + 1;
    const date = dateSelected.getDate();
    return `${dateSelected.getFullYear()}-${month < 10 ? '0' + month : month}-${
      date < 10 ? '0' + date : date
    }T00:00:00`;
  }); // ISO_DATE = 2022-08-22T00:00:00. Disregards the time
  formatted = formatted.replace(/\b(Qo)\b/, getQuarter(dateSelected)); // Qo = 1st..4th
  return formatted;
}

/**
 * Determines the quarter of the selected date.
 * @param {Date} d date to identify
 * @return 1st-4th
 */
export function getQuarter(d) {
  const today = new Date(d);
  const quarter = Math.floor((today.getMonth() + 3) / 3);

  if (quarter === 1) {
    return quarter + 'st';
  } else if (quarter === 2) {
    return quarter + 'nd';
  } else if (quarter === 3) {
    return quarter + 'rd';
  } else {
    return quarter + 'th';
  }
}

/**
 * Gets the first day of a iso week of the selected date
 * ISO week ranges Monday-Sunday
 * @param {Date} d Date of origin
 * @returns {Date} Returns the first day of the week (Monday)
 */
export function getStartOfISOWeek(d) {
  const date = new Date(d);
  const day = date.getDay(),
    diff = date.getDate() - day + (day === 0 ? -6 : 1);
  return new Date(date.setDate(diff));
}

/**
 * Gets the last day of a iso week of the selected date
 * ISO week ranges Monday-Sunday
 * @param {Date} d Date of origin
 * @returns {Date} Returns the last day of the week (Sunday)
 */
export function getEndOfISOWeek(d) {
  const date = new Date(d);
  const day = date.getDay(),
    diff = date.getDate() - day + (day == 0 ? 0 : 7);
  return new Date(date.setDate(diff));
}

/**
 * Gets the first day of the year of the selected date
 * @param d Date of origin
 * @returns Returns the first day of the year
 */
export function getStartOfYear(d) {
  return new Date(new Date(d).getFullYear(), 0, 1);
}

/**
 * Gets the last day of the year of the selected date
 * @param d Date of origin
 * @returns Returns the last day of the year
 */
export function getEndOfYear(d) {
  return new Date(new Date(d).getFullYear(), 11, 31);
}

/**
 * Gets the first day of the month of the selected date
 * @param d Date of origin
 * @returns Returns the first day of the month
 */
export function getStartOfMonth(d) {
  const dateSelected = new Date(d);
  return new Date(dateSelected.getFullYear(), dateSelected.getMonth(), 1);
}

/**
 * Gets the last day of the month of the selected date
 * @param d Date of origin
 * @returns Returns the last day of the month
 */
export function getEndOfMonth(d) {
  const dateSelected = new Date(d);
  return new Date(dateSelected.getFullYear(), dateSelected.getMonth() + 1, 0);
}

/**
 * Gets the first day of the quarter of the selected date
 * @param d Date of origin
 * @returns Returns the first day of the selected date quarter
 */
export function getStartOfQuarter(d) {
  const dateSelected = new Date(d);
  const quarter = Math.floor(dateSelected.getMonth() / 3);
  return new Date(dateSelected.getFullYear(), quarter * 3, 1);
}

/**
 * Gets the last day of the quarter of the selected date
 * @param d Date of origin
 * @returns Returns the last day of the selected date quarter
 */
export function getEndOfQuarter(d) {
  const dateSelected = new Date(d);
  const quarter = Math.floor(dateSelected.getMonth() / 3);
  const firstDate = new Date(dateSelected.getFullYear(), quarter * 3, 1);
  return new Date(firstDate.getFullYear(), firstDate.getMonth() + 3, 0);
}

/**
 * Identifies if the date is within similar iso week with the dayOfWeek
 * @param {Date} d Date to identify
 * @param {Date} dayOfWeek Date to compare with
 * @returns {Boolean} Returns whether the dates have similar iso week.
 */
export function isWithinISOWeek(date, dayOfWeek) {
  const startOfWeek = getStartOfISOWeek(dayOfWeek);
  const endOfWeek = getEndOfISOWeek(dayOfWeek);

  return (
    compareDates(date, startOfWeek) >= 0 && compareDates(date, endOfWeek) <= 0
  );
}

/**
 * Identifies if the date is within similar month with the dayOfMonth
 * @param {Date} d Date to identify
 * @param {Date} dayOfMonth Date to compare with
 * @returns {Boolean} Returns whether the dates have similar iso week.
 */
export function isWithinISOMonth(date, dayOfMonth) {
  const startOfMonth = getStartOfMonth(dayOfMonth);
  const endOfMonth = getEndOfMonth(dayOfMonth);

  return (
    compareDates(date, startOfMonth) >= 0 && compareDates(date, endOfMonth) <= 0
  );
}

/**
 * Compares 2 dates and determine if they are equal, greater, or lesser
 * @param {Date} a Date 1
 * @param {Date} b Date 2
 * @returns Returns 0 for equality, 1 when a > b, -1 when a < b
 */
export function compareDates(a, b) {
  const d1 = new Date(a);
  const d2 = new Date(b);

  if (d1.getTime() > d2.getTime()) return 1;
  else if (d1.getTime() < d2.getTime()) return -1;
  else return 0;
}

/**
 * Gets the range of dates from `dateFrom` to `dateTo`.
 * @param {Date} dateFrom date to start count
 * @param {Date} dateTo date to end count
 * @param {string} increment increment days by "DAY", "MONTH", "YEAR"
 * @returns {number[]}
 * @example (dateFrom: '2022-08-30', dateTo: '2022-09-5', increment: "DAY") => [30, 31, 1, 2 ,3, 4, 5]
 */
export function getRangeOfDates(dateFrom, dateTo, increment = 'DAY') {
  const result = [];
  for (let pointer = new Date(dateFrom); compareDates(pointer, dateTo) <= 0; ) {
    if (increment === 'DAY') {
      result.push(pointer.getDate());
      pointer.setDate(pointer.getDate() + 1);
    } else if (increment === 'MONTH') {
      result.push(pointer.getMonth());
      pointer.setMonth(pointer.getMonth() + 1);
    } else if (increment === 'YEAR') {
      result.push(pointer.getFullYear());
      pointer.setFullYear(pointer.setFullYear() + 1);
    }
  }

  return result;
}

/**
 * Get date in format of "Feb 16, 09:26"
 * @param {Number} t Timestamp
 * @returns {String} Formatted date as string
 */
export function getMonthDayHourMinute(t) {
  const date = new Date(t * 1000);
  const month = MONTHS_SHORT[date.getMonth()];
  const day = date.getDate();
  const dayWithLeadingZero = day < 10 ? `0${day}` : day;
  const hour = date.getHours();
  const hourWithLeadingZero = hour < 10 ? `0${hour}` : hour;
  const minute = date.getMinutes();
  const minuteWithLeadingZero = minute < 10 ? `0${minute}` : minute;
  return `${month} ${dayWithLeadingZero}, ${hourWithLeadingZero}:${minuteWithLeadingZero}`;
}

/**
 * Get date in "2023-08-16, 16:17" format
 * @param {String} ISOString date string in ISO format
 * @returns {String} date formatted suitable for tooltips
 */
export function tooltipDateFormat(ISOString) {
  const date = new Date(ISOString);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return `${year}-${month}-${day}, ${hours}:${minutes}`;
}

const today = new Date();
const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);
const week = new Date();
week.setDate(week.getDate() - 7);
const month = new Date();
month.setMonth(month.getMonth() - 1);
const months = new Date();
months.setMonth(months.getMonth() - 3);
const year = new Date();
year.setFullYear(year.getFullYear() - 1);

export const dateIntervals = [
  { title: 'Today', key: 'TODAY', value: toISODate(today) },
  { title: 'Yesterday', key: 'YESTERDAY', value: toISODate(yesterday) },
  { title: 'Last 7 days', key: 'LAST_SEVEN_DAYS', value: toISODate(week) },
  { title: 'Last 30 days', key: 'LAST_THIRTY_DAYS', value: toISODate(month) },
  {
    title: 'Last 3 months',
    key: 'LAST_THREE_MONTHS',
    value: toISODate(months),
  },
  {
    title: 'Last 12 months',
    key: 'LAST_TWELVE_MONTHS',
    value: toISODate(year),
  },
];
