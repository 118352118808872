import { defineStore } from 'pinia';

import {
  getMonitoredOrdersByUser,
  getOrders,
  setMonitoredOrder,
  postFlagged,
  editTheComment,
} from '@/api/orders';
import { deleteTheComment, fetchJSON } from '@/api';

import { countryMap } from '@/utils/locale';
import { patchArray } from '@/utils/utils';

import {
  transformCountriesIntoDropdownItems,
  transformDestinationIntoDropdownItems,
} from '@/store/utils';
import { useStatisticsStore } from '@/store/statisticsStore';
import { useUserStore } from '@/store/userStore';
import { useCustomerDashboardStore } from './customers/customerDashboardStore';
import { useUiStore } from './uiStore';
import { fetchOrderLimits } from '@/utils/orders';
import { getPersonMapping } from '@/api/getPersonMapping';

export const useOrdersStore = defineStore('OrdersStore', {
  state: () => {
    return {
      orders: [],
      hits: {},
      limits: undefined,
      archivedOrders: [],
      numberOfFlagged: 0,
      personMappings: {},

      // Country and Destination
      defaultCountries: [],
      defaultDestination: [],
      currencyRates: {},
      countries: [],
      destination: [],

      // Monitored
      monitored: [],
      monitoredCountries: [],
      monitoredDestination: [],

      // Loading
      loading: true,
      loadingMonitored: true,
      isLoadingArchivedOrders: false,
      isFilterLoading: true,
      isLoadingMoreOrders: false,
      isFinishedInitialize: false,
      ongoingPersonMappingInit: '',
    };
  },
  getters: {
    //#region Filters
    getCountries() {
      return this.countries;
    },
    getMonitoredCountries() {
      return this.monitoredCountries;
    },
    getDestinations() {
      return this.destination;
    },
    getMonitoredDestinations() {
      return this.monitoredDestination;
    },
    //#endregion
  },
  actions: {
    //#region Complete Order Helpers
    /**
     * Updates a specific order within the system and patches it using the updater function
     * @param {String} key - used to determine the order to update
     * @param {(o: FormattedCompleteOrder) => FormattedCompleteOrder} updateFn
     */
    updateOrder({ key, updateFn, comparator = 'ORDER_NO' }) {
      const statsStore = useStatisticsStore();
      const customerDashboardStore = useCustomerDashboardStore();
      let ordersClone = [
        ...this.orders,
        ...this.monitored,
        ...statsStore.newestOrders,
        ...statsStore.selectedCalendarDateOrders,
        ...statsStore.flaggedOrders,
        ...statsStore.lastUpdateOrders,
        ...statsStore.latestCommentedOrders,
        ...customerDashboardStore.ongoingOrders.orders,
        ...customerDashboardStore.plannedDeliveries.orders,
        ...customerDashboardStore.latestCommentedOrders.orders,
        ...statsStore.plannedDeliveries,
      ];

      const selectedItem = ordersClone.find((o) => o[comparator] === key);

      const patchArrayComparator = 'ORDER_NO';

      const updatedItem = updateFn(selectedItem);

      this.orders = patchArray(this.orders, patchArrayComparator, updatedItem);
      statsStore.newestOrders = patchArray(
        statsStore.newestOrders,
        patchArrayComparator,
        updatedItem
      );
      statsStore.lastUpdateOrders = patchArray(
        statsStore.lastUpdateOrders,
        patchArrayComparator,
        updatedItem
      );
      statsStore.plannedDeliveries = patchArray(
        statsStore.plannedDeliveries,
        patchArrayComparator,
        updatedItem
      );
      statsStore.selectedCalendarDateOrders = patchArray(
        statsStore.selectedCalendarDateOrders,
        patchArrayComparator,
        updatedItem
      );
      statsStore.flaggedOrders = patchArray(
        statsStore.flaggedOrders,
        patchArrayComparator,
        updatedItem
      );
      statsStore.latestCommentedOrders = patchArray(
        statsStore.latestCommentedOrders,
        patchArrayComparator,
        updatedItem
      );
      customerDashboardStore.ongoingOrders.orders = patchArray(
        customerDashboardStore.ongoingOrders.orders,
        patchArrayComparator,
        updatedItem
      );
      customerDashboardStore.plannedDeliveries.orders = patchArray(
        customerDashboardStore.plannedDeliveries.orders,
        patchArrayComparator,
        updatedItem
      );
      customerDashboardStore.latestCommentedOrders.orders = patchArray(
        customerDashboardStore.latestCommentedOrders.orders,
        patchArrayComparator,
        updatedItem
      );
      this.monitored = patchArray(
        this.monitored,
        patchArrayComparator,
        updatedItem
      );

      return updatedItem;
    },

    async fillOrders(params) {
      this.loading = true;
      this.isFinishedInitialize = false;

      try {
        params = {
          'fill[commented-users]': true,
          'fill[monitored]': true,
          emptyFirst: params?.sortOrder === 'desc',
          ...params,
        };
        const response = await getOrders(params);

        await this.initializeCountryAndDestinationOptions(params, response);

        this.orders = response.orders ?? [];

        this.hits = response.hits ?? {};
        this.currencyRates = response.currencyRates;

        if (!params.status) {
          this.numberOfFlagged = response.hits.flagged ?? 0;
        }

        this.setCountryOptions(params);
        this.setDestinationOptions(params);
      } catch (ex) {
        console.error(ex);
      }

      this.loading = false;
      this.isFinishedInitialize = true;

      try {
        const codes = this.getPersonCodes(...this.orders);
        await this.initalizePersonMappings(...codes);
      } catch (ex) {
        console.error(ex);
      }
    },

    async loadMoreOrders(params) {
      try {
        this.isLoadingMoreOrders = true;
        const response = await getOrders({
          'fill[commented-users]': true,
          'fill[monitored]': true,
          emptyFirst: params?.sortOrder === 'desc' ? true : false,
          ...params,
        });

        this.orders.push(...response.orders);
      } catch (ex) {
        console.error(ex);
      } finally {
        this.isLoadingMoreOrders = false;
      }

      try {
        const codes = this.getPersonCodes(...this.orders);
        await this.initalizePersonMappings(...codes);
      } catch (ex) {
        console.error(ex);
      }
    },

    async setOrderLimits(dateType) {
      // Fetch limits for the selected order type
      const limits = await fetchOrderLimits(dateType);
      this.limits = limits;
      return limits;
    },
    //#endregion

    //#region Person Mappings
    async initalizePersonMappings(...codes) {
      if (this.ongoingPersonMappingInit === JSON.stringify(codes)) return;
      this.ongoingPersonMappingInit = JSON.stringify(codes);

      const promises = [];
      for (const code of codes) {
        const promise = getPersonMapping(code).then(
          (name) => (this.personMappings[code] = name)
        );
        promises.push(promise);
      }
      await Promise.all(promises);

      this.ongoingPersonMappingInit = '';
    },
    getPersonCodes(...orders) {
      const notes = orders.flatMap(
        (order) => order.ORDER_LINES?.[0].SALES?.NOTE_TEXTS?.NOTE_TEXT
      );
      if (!Array.isArray(notes)) return [];

      const codes = notes.map((notes) => notes?.MODIFIED_BY).filter(Boolean);
      const uniqueCodes = Array.from(new Set(codes));
      return uniqueCodes;
    },
    //#endregion

    //#region VIP Customers Handler
    async toggleVipCustomer(customerNo, newVipStatus) {
      this.updateOrder({
        key: customerNo,
        updateFn: (o) => ({
          ...o,
          IS_VIP: newVipStatus,
        }),
        comparator: 'CUSTOMER_NO',
      });
    },
    //#endregion

    //#region Monitor Orders Handler
    async toggleMonitor(orderNo) {
      await setMonitoredOrder(orderNo);

      // Update orders
      const updatedOrder = this.updateOrder({
        key: orderNo,
        updateFn: (o) => ({
          ...o,
          MONITORED: !o.MONITORED,
        }),
        comparator: 'ORDER_NO',
      });

      const isOrderExist = !!this.monitored?.find(
        (o) => o?.ORDER_NO === orderNo
      );
      if (isOrderExist) {
        const query = this.$route?.query;

        const { countries, destination } = await getMonitoredOrdersByUser(
          query
        );
        this.monitoredCountries = countries;
        this.monitoredDestination = destination;
        this.monitored = this.monitored.filter((o) => o.ORDER_NO !== orderNo);
      } else {
        this.monitored.push(updatedOrder);
      }
    },

    async fillMonitoredOrders(query = {}, fillCommentedUsers = true) {
      this.loading = true;
      this.loadingMonitored = true;

      const monitored = await getMonitoredOrdersByUser(
        {
          ...query,
          emptyFirst: query?.sortOrder === 'desc' ? true : false,
        },
        fillCommentedUsers
      );

      const monitoredOrders = monitored?.orders?.map((o) => ({
        ...o,
        MONITORED: true,
      }));
      this.monitored = monitoredOrders || [];

      const countries =
        monitored.countries?.map((c) => ({
          title: countryMap[c],
          value: c,
          checked: false,
        })) ?? [];

      const destination =
        monitored.destination?.map((c) => ({
          title: countryMap[c],
          value: c,
          checked: false,
        })) ?? [];

      this.monitoredCountries = countries;
      this.monitoredDestination = destination;
      this.loadingMonitored = false;
      this.loading = false;

      const codes = this.getPersonCodes(...this.monitored);
      await this.initalizePersonMappings(...codes);
    },
    //#endregion

    //#region Flag Orders Handler
    async toggleFlagged(order) {
      try {
        await postFlagged(
          order.CONTRACT,
          order.ORDER_NO,
          !order.FLAGGED?.FLAGGED
        );

        const userStore = useUserStore();
        const updatedOrder = this.updateOrder({
          key: order.ORDER_NO,
          updateFn: (o) => {
            const FLAGGED = {
              FLAGGED: !o.FLAGGED?.FLAGGED,
              LAST_UPDATED: new Date().toISOString(),
              LAST_UPDATED_BY: userStore.getName,
              USER_ID: userStore.getId,
            };

            return {
              ...o,
              FLAGGED,
              LAST_UPDATED_DETAILS: {
                ...o.LAST_UPDATED_DETAILS,
                FLAGGED: {
                  NEW_VALUE: FLAGGED,
                  OLD_VALUE: { ...o.FLAGGED },
                  UPDATED_AT: FLAGGED.LAST_UPDATED,
                },
              },
            };
          },
          comparator: 'ORDER_NO',
        });

        const previousFlagValue = updatedOrder?.FLAGGED?.FLAGGED || false;
        if (previousFlagValue) {
          this.numberOfFlagged -= 1;
        } else {
          this.numberOfFlagged += 1;
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    //#endregion

    //#region Country and Destination Helpers
    /**
     * Initialize default countries and destination options using unfiltered order params.
     */
    async initializeCountryAndDestinationOptions(orderParams, response) {
      if (!this.defaultCountries?.length || !this.defaultDestination?.length) {
        const uiStore = useUiStore();
        const nonFilterKeys = ['ascending', 'currentPage', 'show', 'sortKey'];
        const filterParamKeys = Object.keys(uiStore.filters).filter(
          (key) => !nonFilterKeys.includes(key)
        );

        const paramKeys = Object.keys(orderParams || {});

        const hasFilterParam = paramKeys.find((key) =>
          filterParamKeys.includes(key)
        );

        if (hasFilterParam) {
          await this.setDefaultCountriesAndDestinations();
        } else {
          this.defaultCountries = response?.countries;
          this.defaultDestination = response?.destination;
        }
      }
    },

    async setDefaultCountriesAndDestinations() {
      const response = await getOrders({
        show: 0,
      });
      this.defaultCountries = response?.countries;
      this.defaultDestination = response?.destination;
    },

    setCountryOptions(params) {
      const checkedCountries = params.countries?.split(',');
      const countries = transformCountriesIntoDropdownItems(
        this.defaultCountries,
        checkedCountries || []
      );

      this.countries = countries;
    },

    setDestinationOptions(params) {
      const checkedDestination = params.destination?.split(',');
      const destination = transformDestinationIntoDropdownItems(
        this.defaultDestination,
        checkedDestination || []
      );

      this.destination = destination;
    },

    clearCountries() {
      this.countries = this.defaultCountries;
    },

    clearDestination() {
      this.destination = this.defaultDestination;
    },
    //#endregion

    //#region Comments Handler
    async postComment({ message, order, userInfo }) {
      const input = {
        message: message,
        writer: userInfo?.firstName + ' ' + userInfo?.lastName,
        ORDER_NO: order.ORDER_NO,
        CONTRACT: order.CONTRACT,
        post: true,
      };

      const send = JSON.stringify(input);
      const url = new URL(process.env.VUE_APP_API_URL + '/comment');
      url.searchParams.set('fill[commented-users]', true);
      url.searchParams.set('fill[monitored]', true);

      try {
        const result = await fetchJSON(url, 'post', send);
        if (result) {
          this.updateOrder({ key: order.ORDER_NO, updateFn: () => result });

          const { updateDashboardLatestComments } = useStatisticsStore();
          updateDashboardLatestComments();
        }
      } catch (err) {
        console.error(err.message);
        throw err;
      }
    },

    async deleteComment({ commentID, orderNo, contract }) {
      await deleteTheComment(commentID, orderNo, contract);

      this.updateOrder({
        key: orderNo,
        updateFn: (order) => {
          const comments = order.COMMENTS?.filter(
            (c) => c.COMMENT_ID !== commentID
          );
          return {
            ...order,
            COMMENTS: comments,
          };
        },
      });

      const { updateDashboardLatestComments } = useStatisticsStore();
      updateDashboardLatestComments();
    },

    async editComment({ comment, item, newMessage }) {
      const updatedOrder = await editTheComment(comment, newMessage, item);

      this.updateOrder({
        key: item.ORDER_NO,
        updateFn: (order) => {
          const oldCommentIndex = order.COMMENTS.findIndex(
            (c) => c.COMMENT_ID === comment.COMMENT_ID
          );
          const updatedComment = updatedOrder.COMMENTS.find(
            (c) => c.COMMENT_ID === comment.COMMENT_ID
          );
          order.COMMENTS[oldCommentIndex] = updatedComment;

          const { updateDashboardLatestComments } = useStatisticsStore();
          updateDashboardLatestComments();

          return {
            ...order,
          };
        },
      });
    },

    //#endregion
  },
});
