<template>
  <div class="download-container">
    <mymunters-button
      ref="downloadButton"
      button-type="button-secondary"
      @click="downloadClicked()">
      <div class="download-button">
        <template v-if="!isMobile">
          <DownloadIcon fill="black" />&nbsp;Download
        </template>
        <template v-else>
          <DownloadIcon
            style="height: 18px; width: 18px; fill: #1a1a1a" />&nbsp;Download
          IFS data as PDF
        </template>
      </div>
    </mymunters-button>

    <div
      v-if="!isMobile && isOptionsOpen"
      v-click-outside="closeOptions"
      v-smart-positioning="{ y: 0.91 }"
      class="download-drop-up">
      <span @click="printPDF()"><PDFIcon /> PDF</span>
      <span @click="downloadExcel()"><ExcelIcon /> Excel</span>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import { exportOrderToExcel } from '@/utils/excel/order';
import { printIFSOrderPDF } from '@/components/Orders/OrdersSummary/IFSOrder/ifsOrderUtils';

import DownloadIcon from '@/components/shared/Icons/DownloadIcon.vue';
import PDFIcon from '@/components/shared/Icons/PDFIcon.vue';
import ExcelIcon from '@/components/shared/Icons/ExcelIcon.vue';

export default defineComponent({
  name: 'IFSOrderDownload',
  components: { DownloadIcon, PDFIcon, ExcelIcon },
  inject: ['isMobile'],
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isOptionsOpen: false,
    };
  },
  methods: {
    toggleDownloadOptions() {
      this.isOptionsOpen = !this.isOptionsOpen;
    },
    downloadClicked() {
      if (this.isMobile) return this.printPDF();

      this.toggleDownloadOptions();
    },
    closeOptions(el) {
      if (!this.isOptionsOpen) return;
      if (!el.composedPath()?.some((p) => p === this.$refs.downloadButton)) {
        this.isOptionsOpen = false;
      }
    },
    async printPDF() {
      if (!this.isMobile) this.toggleDownloadOptions();

      return printIFSOrderPDF(this.order);
    },
    async downloadExcel() {
      this.toggleDownloadOptions();
      await exportOrderToExcel(this.order);
    },
  },
});
</script>

<style lang="scss" scoped>
@import '../../../../styles/variables';

.download-container {
  position: relative;
  height: 100%;

  .download-button {
    display: flex;
    align-items: center;
    width: 90px;
  }

  .download-drop-up {
    display: none;
    display: flex;
    position: absolute;
    background: #fff;
    flex-direction: column;
    width: 153px;
    border: 1px solid $blue;
    border-radius: $border-radius-md;
    overflow: hidden;
    fill: black;
  }

  .download-drop-up > span {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    height: 40px;
  }

  mymunters-modal {
    &::part(header) {
      font-size: 20px;
    }
  }
  .download-pop-up {
    width: 50vw;
    display: flex;
    flex-direction: column;

    span {
      width: 100%;
    }
  }

  span {
    padding: 0.5rem;
    cursor: pointer;

    &:hover {
      background: #ececec;
    }
  }
}

@media screen and (max-width: $bp-sm) {
  .download-container {
    .download-button {
      width: 100%;
      justify-content: center;
    }
  }
}
</style>
