export const createAnchor = (href, fileName) => {
  const anchor = document.createElement('a');
  anchor.style.display = 'none';
  anchor.href = href;
  anchor.download = fileName;

  document.body.appendChild(anchor);
  return anchor;
};

export const downloadExcelFile = async (filename, workbook) => {
  // File in base64 format
  const xls64 = await workbook.xlsx.writeBuffer({ base64: true });

  const blob = new Blob([xls64], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });

  const href = URL.createObjectURL(blob);

  const anchor = createAnchor(href, filename);
  anchor.click();

  URL.revokeObjectURL(href);
  document.body.removeChild(anchor);
};

/**
 * Complete paper sizes in https://github.com/exceljs/exceljs - Example Paper Sizes
 */
export const PAPER_SIZES = {
  LETTER: undefined,
  LEGAL: 5,
  EXECUTIVE: 7,
  A3: 8,
  A4: 9,
  A5: 11,
  B5: 13,
};

export const setupWorkSheet = (
  sheet,
  {
    orientation = 'portrait',
    fitToPage = false,
    scalePercentage,
    numOfPagesInWidth,
    numOfPagesInHeight,
    paperSize = PAPER_SIZES.LETTER,
  }
) => {
  sheet.pageSetup.orientation = orientation;
  sheet.pageSetup.fitToPage =
    fitToPage || numOfPagesInWidth || numOfPagesInHeight ? true : false;
  sheet.pageSetup.scale = scalePercentage;
  sheet.pageSetup.fitToWidth = numOfPagesInWidth;
  sheet.pageSetup.fitToHeight = numOfPagesInHeight;
  sheet.pageSetup.paperSize = paperSize;
};
