<template>
  <div>
    <aside
      v-if="!isMobileView"
      class="sidebar"
      @mouseenter="expandSidebar"
      @mouseleave="collapseSidebar">
      <div class="logo-button">
        <a :href="homeUrl"> <MuntersBlue /> </a>
      </div>
      <div class="navigation">
        <div v-for="(item, index) in props.navigationItems" :key="index">
          <NavBarItem
            v-if="item.access !== false"
            :title="item.title"
            :href="item.href"
            :is-active="currentPath === item.href"
            :icon="item.icon"
            :is-open="isOpen" />
        </div>
      </div>
    </aside>
    <aside v-if="isMobileView || openMobileMenu" class="sidebar-mobile">
      <div class="logo-button">
        <!-- Your logo button content here -->
        <IconButton :icon="Close" @click="closeMobileMenu" />
      </div>
      <div class="navigation">
        <div v-for="(item, index) in props.navigationItems" :key="index">
          <NavBarItem
            v-if="item.access !== false"
            :title="item.title"
            :href="item.href"
            :is-active="currentPath === item.href"
            :icon="item.icon"
            :is-open="isOpen"
            @click="closeMobileMenu" />
        </div>
        <div class="function-divider">
          <div class="divider-line" />
        </div>
        <div v-for="(item, index) in props.functionItems" :key="index">
          <NavBarItem
            v-if="item.access !== false"
            :title="item.title"
            :icon="item.icon"
            :is-open="isOpen"
            :is-action="true"
            @click="item.onClick" />
        </div>
      </div>
    </aside>
    <div v-if="isMobileView && !openMobileMenu" class="mobile-menu-toggle">
      <IconButton :icon="Hamburger" @click="openMobileMenu = true" />
    </div>
    <div v-if="isOpen && !isMobileView" :style="overlayStyle" class="overlay" />
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router';
import { defineProps, ref, computed, onMounted, watch, onUnmounted } from 'vue';
import MuntersBlue from './icons/MuntersBlue.vue';
import Close from './icons/Close.vue';
import Hamburger from './icons/Hamburger.vue';
import IconButton from './IconButton.vue';
import NavBarItem from './AirCNavBarItem.vue';
import { openMobileMenu } from './navigationHandler';

const isProd = process.env.VUE_APP_STAGE === 'prod';

const props = defineProps({
  navigationItems: Array,
  functionItems: Array,
  openMobileView: Boolean,
});
const route = useRoute(); //TODO implement path logic
const currentPath = ref(route?.path); // Array of objects with 'icon' and 'title'
const mobileMenuStatus = ref(props.openMobileView);
const isMobileView = ref(false);
const isOpen = ref(false);
const mobileViewWidth = ref('0%');
const sidebarWidth = ref('70px');
const expandTimer = ref(null);
const collapseTimer = ref(null);
const homeUrl = ref(`https://airc${!isProd ? '.test' : ''}.mymunters.com/#/`);

//const mmTopbar = ref(null);

onMounted(() => {
  sizeWhenMounted();
  //openMobileMenu.value = true;
});

onUnmounted(() => {
  // Clear timers when component is unmounted
  clearTimeout(expandTimer.value);
  clearTimeout(collapseTimer.value);
});

watch(
  () => props.openMobileView,
  (newVal) => {
    mobileMenuStatus.value = newVal;
    if (mobileMenuStatus.value === false) {
      mobileViewWidth.value = '0%';
    } else {
      mobileViewWidth.value = '100%';
    }
  }
);

watch(
  () => openMobileMenu.value,
  (newVal) => {
    if (newVal) {
      mobileViewWidth.value = '100%';
    } else {
      mobileViewWidth.value = '0%';
    }
  }
);

watch(
  () => route?.path,
  (val) => {
    currentPath.value = val;
  }
);

function closeMobileMenu() {
  // Toggle the menu state
  openMobileMenu.value = false;
  mobileViewWidth.value = '0%';
}

function sizeWhenMounted() {
  const checkView = debounce(() => {
    if (window.innerWidth <= 1008) {
      isMobileView.value = true;
      isOpen.value = true;
    } else if (window.innerWidth > 1008) {
      isMobileView.value = false;
      isOpen.value = false;
      openMobileMenu.value = false;
    }
  }, 50);

  window.addEventListener('resize', checkView);
  checkView();
}

// debounce to reduce calls
function debounce(func, wait) {
  let timeout;
  return function () {
    clearTimeout(timeout);
    timeout = setTimeout(func, wait);
  };
}

function expandSidebar() {
  clearTimeout(collapseTimer.value);

  // Set a timer to expand the sidebar
  expandTimer.value = setTimeout(() => {
    isOpen.value = true;
    sidebarWidth.value = '330px';
  }, 500); // 300ms delay
}

function collapseSidebar() {
  clearTimeout(expandTimer.value);
  // Set a timer to collapse the sidebar
  isOpen.value = false;
  sidebarWidth.value = '70px';
}

const overlayStyle = computed(() => ({
  backgroundColor: isOpen.value ? 'rgba(0, 0, 0, 0.1)' : 'transparent',
  zIndex: 200,
  width: '100vw',
  height: '100vh',
  position: 'fixed',
  top: 0,
  left: 0,
  pointerEvents: 'none',
}));
</script>

<style scoped lang="scss">
.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  width: v-bind(sidebarWidth);
  height: 100%;
  z-index: 1002;
  background-color: #1c1c1c;
  color: #fff;
  transition: width 0.5s ease-in-out;

  .logo-button {
    max-width: 70px !important;
    min-width: 70px;
    display: flex;
    justify-content: center;
    margin-top: 1.25em;
    padding: 6px;
    /* Add your logo button styles here */
  }

  .navigation {
    margin-top: 5em;
    list-style: none;
    padding: 0;

    li {
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      height: 60px;
      font-size: 16px;
      transition: background-color 0.2s;

      &:hover {
        border-left: 2px solid #2b89ed;
        background-color: #1c1c1c;
      }

      .icon {
        margin-right: 10px;
      }
    }
  }
}

.title {
  display: flex !important;
  flex-direction: column !important;
  justify-content: left !important;
}

.sidebar-mobile {
  position: fixed;
  left: 0;
  top: 0;
  width: v-bind(mobileViewWidth);
  height: 100%;
  z-index: 9999;
  background-color: #1c1c1c;
  color: #fff;
  transition: width 0.5s ease-in-out;

  .logo-button {
    display: flex;
    justify-content: right;
    margin-top: 5em;
    margin-right: 1.75em;
    /* Add your logo button styles here */
  }

  .navigation {
    margin-top: 1.75em;
    list-style: none;
    padding: 0;

    li {
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      height: 60px;
      font-size: 16px;
      transition: background-color 0.2s;

      &:hover {
        border-left: 2px solid #2b89ed;
        background-color: #1c1c1c;
      }

      .icon {
        margin-right: 10px;
      }
    }
  }
}

.mobile-menu-toggle {
  position: fixed;
  top: 14px;
  left: 12px;
  z-index: 1002;
}

.function-divider {
  display: flex;
  align-items: center;
  height: 60px;
  margin: 0em 1.25em;
}
.divider-line {
  width: 100%;
  height: 1px;
  background: #525252;
}
</style>
