import { computed, ref } from 'vue';

const navMenuDimensions = ref({
  width: 0,
  height: 0,
});

const topBarDimensions = ref({
  width: 0,
  height: 0,
});

const containerDimensions = ref({
  width: 0,
  height: 0,
});

export default {
  install(app) {
    const siteLayoutElement = document.querySelector('mymunters-site-layout');
    if (siteLayoutElement?.hasLoaded) {
      initializeDimensions();
    } else {
      document.addEventListener('mmSiteLayoutLoaded', initializeDimensions);
    }

    app.provide(
      'navMenuWidth',
      computed(() => navMenuDimensions.value.width)
    );
    app.provide(
      'navMenuHeight',
      computed(() => navMenuDimensions.value.height)
    );
    app.provide(
      'topBarWidth',
      computed(() => topBarDimensions.value.width)
    );
    app.provide(
      'topBarHeight',
      computed(() => topBarDimensions.value.height)
    );
    app.provide(
      'containerWidth',
      computed(() => containerDimensions.value.width)
    );
    app.provide(
      'containerHeight',
      computed(() => containerDimensions.value.height)
    );
  },
};

async function initializeDimensions() {
  const mmNavMenu = document.querySelector('mymunters-navigation-menu');
  const mmTopbar = document.querySelector('mymunters-topbar');

  const calculateInitialSizes = () => {
    const { width: navMenuWidth, height: navMenuHeight } =
      mmNavMenu?.getBoundingClientRect() || {};
    const { width: topBarWidth, height: topBarHeight } =
      mmTopbar?.getBoundingClientRect() || {};

    topBarDimensions.value = {
      width: topBarWidth,
      height: topBarHeight,
    };
    navMenuDimensions.value = {
      width: navMenuWidth,
      height: navMenuHeight,
    };

    const fullWidth = window.innerWidth;
    const fullHeight = window.innerHeight;
    containerDimensions.value = {
      width: fullWidth - navMenuWidth,
      height: fullHeight - topBarHeight,
    };
  };

  calculateInitialSizes();

  // Check for changes in sideMenu size
  const navMenuObserver = new ResizeObserver(([entry]) => {
    const { width, height } = entry?.contentRect || {};

    navMenuDimensions.value = {
      width,
      height,
    };

    containerDimensions.value = {
      ...containerDimensions.value,
      width: window.innerWidth - width,
    };
  });
  navMenuObserver.observe(mmNavMenu, { box: 'border-box' });

  // Check for changes in navBar size
  const topBarObserver = new ResizeObserver(([entry]) => {
    const { width, height } = entry?.contentRect || {};

    topBarDimensions.value = {
      width,
      height,
    };

    containerDimensions.value = {
      ...containerDimensions.value,
      height: window.innerHeight - height,
    };
  });
  topBarObserver.observe(mmTopbar, { box: 'border-box' });

  // On Resize - Recalculate
  window.addEventListener('resize', calculateInitialSizes);
}
