<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none">
    <g>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M7.59375 0.48645H10.4062C10.8738 0.48645 11.25 0.862622 11.25 1.3302V7.23645H14.3332C14.959 7.23645 15.2719 7.99231 14.8289 8.43528L9.48164 13.7861C9.21797 14.0497 8.78555 14.0497 8.52187 13.7861L3.16758 8.43528C2.72461 7.99231 3.0375 7.23645 3.66328 7.23645H6.75V1.3302C6.75 0.862622 7.12617 0.48645 7.59375 0.48645ZM18 13.7052V17.6427C18 18.1103 17.6238 18.4865 17.1562 18.4865H0.84375C0.376172 18.4865 0 18.1103 0 17.6427V13.7052C0 13.2376 0.376172 12.8615 0.84375 12.8615H6.00117L7.72383 14.5841C8.43047 15.2907 9.56953 15.2907 10.2762 14.5841L11.9988 12.8615H17.1562C17.6238 12.8615 18 13.2376 18 13.7052ZM13.6406 16.799C13.6406 16.4122 13.3242 16.0958 12.9375 16.0958C12.5508 16.0958 12.2344 16.4122 12.2344 16.799C12.2344 17.1857 12.5508 17.5021 12.9375 17.5021C13.3242 17.5021 13.6406 17.1857 13.6406 16.799ZM15.8906 16.799C15.8906 16.4122 15.5742 16.0958 15.1875 16.0958C14.8008 16.0958 14.4844 16.4122 14.4844 16.799C14.4844 17.1857 14.8008 17.5021 15.1875 17.5021C15.5742 17.5021 15.8906 17.1857 15.8906 16.799Z" />
    </g>
  </svg>
</template>
