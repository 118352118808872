import { defineStore } from 'pinia';
import {
  compareDates,
  formatDate,
  getEndOfISOWeek,
  getEndOfMonth,
  getStartOfISOWeek,
  getStartOfMonth,
} from '../utils/datetime';
import { getStatisticsAsync } from '@/api/statistics';
import { getAllOrders, getOrders } from '@/api/orders';
import { getPersonalStatisticsAsync } from '../api/statistics';
import { useUserStore } from './userStore';
import { getOrder } from '../api/orders';

export const useStatisticsStore = defineStore('StatisticsStore', {
  state: () => ({
    dailyStatistics: {},
    weeklyStatistics: {},
    dynamicStatistics: {},
    lastUpdatedDate: null,
    plannedDeliveries: [],
    selectedCalendarDateOrders: [],
    lastUpdateOrders: [],
    newestOrders: [],
    latestCommentedOrders: [],
    flaggedOrders: [],
    isPlannedDeliveriesLoading: false,
    isFinishInitialize: false,
    isFetchingStatistics: false,
    isPersonalStatistics: false,
    isLatestCommentsLoading: false,
  }),
  getters: {
    // Last updated orders
    getLatestUpdatedOrders:
      (state) =>
      (asc = true) =>
        [...state.lastUpdateOrders].sort((a, b) =>
          asc
            ? compareDates(a.LAST_UPDATED, b.LAST_UPDATED)
            : compareDates(b.LAST_UPDATED, a.LAST_UPDATED)
        ),

    // Get newest orders
    getNewestOrders:
      (state) =>
      (asc = true) =>
        [...state.newestOrders].sort((a, b) =>
          asc
            ? compareDates(a.DATE_ENTERED, b.DATE_ENTERED)
            : compareDates(b.DATE_ENTERED, a.DATE_ENTERED)
        ),

    // Get flagged orders
    getFlaggedOrders:
      (state) =>
      (asc = true) =>
        [...state.flaggedOrders].sort((a, b) =>
          asc
            ? compareDates(a.DATE_ENTERED, b.DATE_ENTERED)
            : compareDates(b.DATE_ENTERED, a.DATE_ENTERED)
        ),
  },
  actions: {
    async getUserCode() {
      if (this.isPersonalStatistics) {
        const userStore = useUserStore();

        if (!userStore.getCode) {
          await userStore.setUser();
        }

        return userStore.getCode;
      }
    },
    async init() {
      this.isFinishInitialize = false;
      this.isFetchingStatistics = true;

      try {
        await Promise.all([this.initOrders(), this.initStatistics()]);
      } catch (error) {
        console.error(error);
      } finally {
        this.isFinishInitialize = true;
        this.isFetchingStatistics = false;
      }
    },
    async initStatistics() {
      this.dailyStatistics = {};
      this.weeklyStatistics = {};
      this.dynamicStatistics = {};

      const getStatisticsFunction = this.isPersonalStatistics
        ? getPersonalStatisticsAsync
        : getStatisticsAsync;

      const code = await this.getUserCode();

      const dateToday = new Date();
      const yesterday = new Date(
        dateToday.getFullYear(),
        dateToday.getMonth(),
        dateToday.getDate() - 1
      );

      const startMonth = getStartOfMonth(dateToday);
      const endMonth = getEndOfMonth(dateToday);

      const startWeek = getStartOfISOWeek(dateToday);
      const endWeek = getEndOfISOWeek(dateToday);
      const todayStatisticsPromise = getStatisticsFunction({ code });
      const weekStatisticsPromise = getStatisticsFunction({
        dateFrom: formatDate(startWeek),
        dateTo: formatDate(endWeek),
        code,
      });

      const statisticsPromise = getStatisticsFunction({
        dateFrom: formatDate(startMonth),
        dateTo: formatDate(endMonth),
        code,
      });

      const [todayStatistics, weekStatistics, dynamicStatistics] =
        await Promise.allSettled([
          todayStatisticsPromise,
          weekStatisticsPromise,
          statisticsPromise,
        ]);

      if (todayStatistics.status === 'rejected') {
        const yesterdayStatistics = await getStatisticsFunction({
          dateFrom: formatDate(yesterday),
          dateTo: formatDate(dateToday),
          code,
        });
        this.dailyStatistics = yesterdayStatistics;
      } else {
        this.dailyStatistics = todayStatistics?.value;
      }

      if (weekStatistics.status === 'rejected') {
        const startLastWeek = getStartOfISOWeek(yesterday);
        const lastWeekStatistics = await getStatisticsFunction({
          dateFrom: formatDate(startLastWeek),
          dateTo: formatDate(dateToday),
          code,
        });
        this.weeklyStatistics = lastWeekStatistics;
      } else {
        this.weeklyStatistics = weekStatistics?.value;
      }

      if (dynamicStatistics.status === 'rejected') {
        const startLastMonth = getStartOfMonth(yesterday);
        const endLastMonth = getEndOfMonth(yesterday);

        const lastMonthStatistics = await getStatisticsFunction({
          dateFrom: formatDate(startLastMonth),
          dateTo: formatDate(endLastMonth),
          code,
        });
        this.dynamicStatistics = lastMonthStatistics;
      } else {
        this.dynamicStatistics = dynamicStatistics?.value || [];
      }

      this.lastUpdatedDate =
        this.todayStatistics?.LAST_UPDATED ||
        this.weekStatistics?.LAST_UPDATED ||
        this.dynamicStatistics?.LAST_UPDATED;
    },
    async initOrders() {
      const personalCode = await this.getUserCode();

      try {
        // Create promises
        const getLastUpdateOrdersPromise = getOrders({
          coordinator: personalCode,
          salesman: personalCode,
          'fill[monitored]': true,
          'fill[commented-users]': true,
          sortKey: 'LAST_UPDATED',
          sortOrder: 'desc',
          show: 10,
        });
        const getNewestOrdersPromise = getOrders({
          coordinator: personalCode,
          salesman: personalCode,
          'fill[monitored]': true,
          'fill[commented-users]': true,
          sortKey: 'DATE_ENTERED',
          sortOrder: 'desc',
          show: 10,
        });
        const getFlaggedOrdersPromise = getOrders({
          coordinator: personalCode,
          salesman: personalCode,
          flagged: true,
          sortOrder: 'desc',
          show: 4,
        });

        // Trigger api calls asynchronusly
        const [lastUpdatedOrders, newestOrders, flaggedOrders] =
          await Promise.allSettled([
            getLastUpdateOrdersPromise,
            getNewestOrdersPromise,
            getFlaggedOrdersPromise,
            this.getLatestCommentedOrders(),
          ]);

        this.lastUpdateOrders = lastUpdatedOrders?.value.orders || [];
        this.newestOrders = newestOrders?.value.orders || [];
        this.flaggedOrders = flaggedOrders?.value.orders || [];
      } catch (error) {
        console.error(error);
      }
    },
    async getLatestCommentedOrders() {
      this.isLatestCommentsLoading = true;

      const personalCode = await this.getUserCode();

      const result = await getOrders({
        coordinator: personalCode,
        salesman: personalCode,
        'fill[monitored]': true,
        'fill[commented-users]': true,
        latestUpdatedComments: true,
        show: 9,
      });

      this.latestCommentedOrders =
        result?.orders?.filter(({ COMMENTS }) => COMMENTS?.length > 0) || [];

      this.isLatestCommentsLoading = false;
    },
    async updateDynamicStatistics(params) {
      try {
        this.isFetchingStatistics = true;

        const code = await this.getUserCode();

        try {
          const completeParams = {
            ...params,
            dateFrom: formatDate(params?.dateFrom),
            dateTo: formatDate(params?.dateTo),
            code,
          };

          const statistics = code
            ? await getPersonalStatisticsAsync(completeParams)
            : await getStatisticsAsync(completeParams);
          this.dynamicStatistics = statistics;
        } catch (err) {
          this.dynamicStatistics = [];
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.isFetchingStatistics = false;
      }
    },
    async fillPlannedDeliveries(params) {
      this.isPlannedDeliveriesLoading = true;

      try {
        const includedFields = [
          'ORDER_NO',
          'CONTRACT',
          'ORDER_STATUS',
          'PLANNED_DELIVERY_DATE',
          'CUSTOMER_NO',
          'CUSTOMER_NAME',
          'FLAGGED',
          'COMMENTS',
          'LAST_UPDATED_DETAILS',
          'MONITORED',
        ];

        params.includedFields = includedFields.join(',');

        const personalCode = await this.getUserCode();

        if (personalCode) {
          params.coordinator = personalCode;
          params.salesman = personalCode;
        }

        const { orders } = await getAllOrders({
          'fill[monitored]': true,
          dateType: 'PLANNED_DELIVERY_DATE',
          ...params,
        });

        if (!orders?.length) {
          this.plannedDeliveries = [];
          return;
        }

        this.plannedDeliveries = orders;
      } catch (error) {
        this.plannedDeliveries = [];
        console.error(error);
        throw error;
      } finally {
        this.isPlannedDeliveriesLoading = false;
      }
    },
    async loadPlannedDeliveryOrder(orderNo) {
      if (!orderNo) return;
      const order = await getOrder(orderNo, { 'fill[commented-users]': true });

      this.plannedDeliveries = this.plannedDeliveries?.map(
        (plannedDelivery) => {
          if (plannedDelivery.ORDER_NO === order.ORDER_NO) {
            return Object.assign(plannedDelivery, order);
          }

          return plannedDelivery;
        }
      );

      return order;
    },
    async updateDashboardLatestComments() {
      setTimeout(async () => {
        await this.getLatestCommentedOrders();
      }, 700);
    },
  },
});
