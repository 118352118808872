<template>
  <button class="icon-btn">
    <component :is="props.icon" class="iconSize" />
  </button>
</template>

<script setup>
import { defineProps, ref, watch, toRef } from 'vue';

const paddingSize = ref('6px');
const props = defineProps({
  icon: Object,
  size: String,
  backGroundColor: String,
});
const iconSize = ref(props.size);
const backGroundColor = toRef(props, 'backGroundColor');

watch(
  () => props.size,
  (val) => {
    switch (val) {
      case 'S':
        iconSize.value = '1em';
        paddingSize.value = '0.5em';
        break;
      case 'M':
        iconSize.value = '1.5em';
        paddingSize.value = '0.5em';
        break;
      case 'L':
        iconSize.value = '2em';
        paddingSize.value = '0.5em';
        break;
      default:
        iconSize.value = '1.5em';
        paddingSize.value = '0.5em';
        break;
    }
  }
);
</script>

<style lang="scss" scoped>
@import '~@/styles/_variables.scss';

.icon-btn {
  cursor: pointer;
  background-color: v-bind(backGroundColor);
  outline: none;
  border: none;
  display: flex;
  justify-self: center;
  align-content: center;
  padding: v-bind(paddingSize);
  border-radius: 9999px;
}

.icon-btn:hover {
  // background-color: #0000003a;
  opacity: 0.9;
}

.iconSize {
  height: v-bind(iconSize);
  width: v-bind(iconSize);
}

.icon-btn:disabled {
  cursor: default;
}
</style>
