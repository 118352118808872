<template>
  <a v-if="!props.isAction" class="navbar-item" :href="props?.href">
    <div :class="{ active: props.isActive }" />
    <div class="icon">
      <component :is="props.icon" v-once :key="props.icon" />
    </div>
    <span
      :style="{
        opacity: isOpen ? '1' : '0',
        visibility: isOpen ? 'visible' : 'hidden',
      }"
      >{{ props?.title }}</span
    >
  </a>
  <div v-else class="navbar-item">
    <div :class="{ active: props.isActive }" />
    <div class="icon">
      <component :is="props.icon" v-once :key="props.icon" />
    </div>
    <span
      :style="{
        opacity: isOpen ? '1' : '0',
        visibility: isOpen ? 'visible' : 'hidden',
      }"
      >{{ props?.title }}</span
    >
  </div>
</template>

<script setup>
import { defineProps, onMounted, ref, watch } from 'vue';

const props = defineProps({
  href: String,
  icon: Object,
  title: String,
  isActive: Boolean,
  isOpen: Boolean,
  isAction: Boolean,
});

const isOpen = ref(props.isOpen);

onMounted(() => {
  //
});

watch(
  () => props.isOpen,
  (val) => {
    isOpen.value = val;
  }
);
</script>

<style lang="scss" scoped>
.icon {
  max-width: 100% !important;
  min-width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    max-height: 24px;
    max-width: 24px;
  }
}
.navbar-item {
  text-decoration: none;
  overflow: hidden;
  position: relative;
  height: 3.75em;
  display: flex;
  cursor: pointer;
  align-items: center;
  background-color: #1c1c1c;
  transition: background-color 0.3s ease-in, width 0.5s;

  span {
    font-family: 'nexa';
    color: #ffffff;
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.2s ease-in;
  }
}

.navbar-item:hover {
  background-color: #292929;
}

.active {
  position: absolute;
  top: 0;
  left: 0;
  width: 0.1875em;
  height: 100%;
  background-color: #2b89ed;
}
</style>
