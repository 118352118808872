<template>
  <button class="icon-button" @click="openModal">
    <TableIcon width="18" height="18" />
  </button>
  <mm-modal
    ref="orderListColumnsModal"
    class="order-list-columns-modal"
    max-width="400px"
    header-text="Edit columns"
    close-on-esc-key
    prevent-body-scroll
    use-custom-buttons>
    <div slot="content" class="order-list-columns-modal__content">
      <label
        v-for="columnOption in getColumnsAsOptions"
        :key="columnOption.value">
        <mm-checkbox
          :checked="isOptionChecked(columnOption.value)"
          @mm-checkbox-value-changed="
            (e) => optionToggled(e, columnOption.value)
          " />
        {{ columnOption.title }}
      </label>
    </div>
    <div slot="custom-buttons" class="order-list-columns-modal__footer">
      <button
        class="mm-button"
        :disabled="isDefaultColumns"
        @click="resetDisplayedColumns">
        Reset default
      </button>
      <button class="mm-button success" @click="closeModal">Close</button>
    </div>
  </mm-modal>
</template>

<script>
import { defineComponent } from 'vue';
import { mapActions, mapState } from 'pinia';

import { useUserStore } from '@/store/userStore';
import { useUiStore } from '@/store/uiStore';

import TableIcon from '@/components/shared/Icons/TableIcon.vue';

export default defineComponent({
  name: 'OrderListTabletColumnsSelector',
  components: {
    TableIcon,
  },
  inject: ['isTablet'],
  computed: {
    ...mapState(useUserStore, ['isInternal', 'isSuperAdmin']),
    ...mapState(useUiStore, [
      'isDefaultColumns',
      'getColumnsAsOptions',
      'displayedColumns',
    ]),
    modal() {
      return this.$refs?.orderListColumnsModal;
    },
  },
  watch: {
    isTablet() {
      if (!this.isTablet) {
        this.modal?.close();
      }
    },
  },
  methods: {
    ...mapActions(useUiStore, ['updateOrderColumns', 'resetDisplayedColumns']),
    openModal() {
      this.modal?.open();
    },
    closeModal() {
      this.modal?.close();
    },
    resetDefaultOptions() {
      this.resetOrderColumns();
    },
    isOptionChecked(value) {
      return !!this.displayedColumns?.find((col) => col.sortKey === value);
    },
    optionToggled({ detail: selected }, value) {
      const columns = this.displayedColumns.map((col) => col.sortKey);

      if (selected && !columns.includes(value)) {
        columns.push(value);
      } else if (!selected) {
        columns.splice(columns.indexOf(value), 1);
      }

      this.updateOrderColumns({ detail: columns });
    },
  },
});
</script>

<style lang="scss" scoped>
@import '../../styles/main.scss';
@import '../../styles/variables';

.order-list-columns-modal {
  &::part(modal-wrapper) {
    min-width: 400px;
  }
  &::part(content) {
    padding: 24px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    label {
      padding: 0.5rem;
      display: flex;
      align-items: center;
      gap: 0.75rem;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
</style>
