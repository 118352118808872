import { computed, ref } from 'vue';

const bp = {
  sm: 816,
  md: 1008,
  midLaptop: 1264,
  lg: 1680,
  xl: 2800,
};

export const mobileMediaWatcher = window.matchMedia(`(width <= ${bp.sm}px)`);
export const tabletMediaWatcher = window.matchMedia(
  `(width > ${bp.sm}px) and (width <= ${bp.md}px)`
);
export const midLaptopMediaWatcher = window.matchMedia(
  `(width > ${bp.md}px) and (width <= ${bp.midLaptop}px)`
);
export const laptopMediaWatcher = window.matchMedia(
  `(width > ${bp.midLaptop}px) and (width <= ${bp.lg}px)`
);
export const desktopMediaWatcher = window.matchMedia(
  `(width > ${bp.lg}px) and (width <= ${bp.xl}px)`
);
export const desktopXLMediaWatcher = window.matchMedia(`(width > ${bp.xl}px)`);

const values = ref({
  isMobile: mobileMediaWatcher.matches,
  isTablet: tabletMediaWatcher.matches,
  isMidLaptop: midLaptopMediaWatcher.matches,
  isLaptop: laptopMediaWatcher.matches,
  isDesktop: desktopMediaWatcher.matches,
  isDesktopXL: desktopXLMediaWatcher.matches,
});

export default {
  install(app) {
    const mediaHandler = (propertyName) => (e) => {
      values.value[propertyName] = e.matches;
    };
    mobileMediaWatcher.addEventListener('change', mediaHandler('isMobile'));
    tabletMediaWatcher.addEventListener('change', mediaHandler('isTablet'));
    midLaptopMediaWatcher.addEventListener(
      'change',
      mediaHandler('isMidLaptop')
    );
    laptopMediaWatcher.addEventListener('change', mediaHandler('isLaptop'));
    desktopMediaWatcher.addEventListener('change', mediaHandler('isDesktop'));
    desktopXLMediaWatcher.addEventListener(
      'change',
      mediaHandler('isDesktopXL')
    );

    // Trigger initial calculations
    const provider = (propertyName) =>
      app.provide(
        propertyName,
        computed(() => values.value[propertyName])
      );

    provider('isMobile');
    provider('isTablet');
    provider('isMidLaptop');
    provider('isLaptop');
    provider('isDesktop');
    provider('isDesktopXL');
  },
};
