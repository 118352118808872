<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.5886 15.5988L13.3804 11.3906C14.3374 9.96995 14.8095 8.19491 14.5568 6.3035C14.1257 3.08636 11.489 0.468269 8.26871 0.0605621C3.48078 -0.545286 -0.545314 3.48081 0.0605687 8.26874C0.468381 11.4905 3.08682 14.1293 6.30432 14.5582C8.19573 14.8109 9.97112 14.339 11.3914 13.3819L15.5996 17.5901C16.1488 18.1392 17.0393 18.1392 17.5884 17.5901C18.1371 17.0402 18.1371 16.1473 17.5886 15.5988ZM2.78082 7.31249C2.78082 4.83116 4.79949 2.81249 7.28082 2.81249C9.76215 2.81249 11.7808 4.83116 11.7808 7.31249C11.7808 9.79382 9.76215 11.8125 7.28082 11.8125C4.79949 11.8125 2.78082 9.79452 2.78082 7.31249Z"
      fill="inherit" />
  </svg>
</template>

<script>
export default {
  name: 'SearchIcon',
};
</script>
