class InMemoryCache {
  cache;

  constructor() {
    this.cache = {};
  }

  /**
   *
   * @param maxAge Seconds until item expires from cache. I.e 300 if the item should expire in 5 minutes (Optional)
   * @param expirationTime Epoch ms timestamp when the item expires from the cache. maxAge trumps this value
   */
  set(key, value, maxAge, expirationTime = undefined) {
    this.cache[key] = {
      value,
      expires: maxAge ? maxAge * 1000 + Date.now() : expirationTime ?? null,
    };
  }

  get(key) {
    const item = this.cache[key];
    if (!item || (item.expires && item.expires < Date.now())) {
      item && delete this.cache[key];
      return null;
    }
    return item.value;
  }

  clear(key = '') {
    if (key) {
      delete this.cache[key];
    } else {
      this.cache = {};
    }
  }
}

const _cacheInstance = new InMemoryCache();

export const inMemoryCache = _cacheInstance;
