<template>
  <template v-if="direction === 'down'">
    <svg
      width="17"
      height="10"
      viewBox="0 0 17 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.8921 1.44464C16.8921 1.73707 16.7805 2.02964 16.5573 2.25249L9.70013 9.10964C9.2537 9.55607 8.53048 9.55607 8.08405 9.10964L1.22691 2.25249C0.780483 1.80607 0.780483 1.08285 1.22691 0.636423C1.67334 0.189994 2.39655 0.189994 2.84298 0.636423L8.89209 6.68571L14.9421 0.635709C15.3885 0.18928 16.1117 0.18928 16.5582 0.635709C16.7814 0.858923 16.8921 1.15178 16.8921 1.44464Z"
        fill="#1A1A1A" />
    </svg>
  </template>
  <template v-else-if="direction === 'left'">
    <svg
      width="11"
      height="18"
      viewBox="0 0 11 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.32133 18C8.99235 18 8.66321 17.8744 8.41249 17.6233L0.698207 9.90904C0.195975 9.40681 0.195975 8.59319 0.698207 8.09096L8.41249 0.376674C8.91473 -0.125558 9.72834 -0.125558 10.2306 0.376674C10.7328 0.878906 10.7328 1.69252 10.2306 2.19475L3.42513 9L10.2314 15.8062C10.7336 16.3085 10.7336 17.1221 10.2314 17.6243C9.98026 17.8754 9.6508 18 9.32133 18Z"
        fill="#1A1A1A" />
    </svg>
  </template>
</template>

<script>
export default {
  name: 'Dropdown',
  props: {
    direction: {
      type: String,
      default: 'down',
    },
  },
};
</script>
