import { getOrder } from '../api/orders';
import { countryMap } from '../utils/locale';
import { notifyMonitored } from '../utils/monitored';

const MONITORED_ORDERS_KEY = 'monitoredOrders';

export function getCurrencyText(currency, amount) {
  if (!currency || !amount) return 'N/A';
  const locale = navigator.language ?? 'en-GB';
  const text = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
  }).format(amount);
  return text;
}

/** Helper to get monitored orders from local storage */
export function getMonitoredOrders() {
  return JSON.parse(localStorage.getItem(MONITORED_ORDERS_KEY) || '[]')
    ?.flat()
    ?.filter(Boolean);
}

/** Helper to set monitored orders in local storage */
export function setMonitoredOrders(orders) {
  localStorage.setItem(MONITORED_ORDERS_KEY, JSON.stringify(orders.flat()));
}

export async function updateMonitoredOrders() {
  const monitoredOrders = getMonitoredOrders();
  if (!monitoredOrders?.length) return [];

  const result = [];
  for (const order of monitoredOrders) {
    const response = await getOrder(order?.ORDER_NO);
    if (response?.ORDER_NO) result.push(response);

    notifyMonitored(order, response);
  }

  setMonitoredOrders(result);
  return result;
}

export function transformCountriesIntoDropdownItems(
  countries,
  checkedCountries = []
) {
  return (
    countries?.map((c) => {
      const title = countryMap[c];
      const isChecked = checkedCountries.some((checked) => checked === c);
      return { title, value: c, checked: isChecked };
    }) ?? []
  );
}

export function transformDestinationIntoDropdownItems(
  countries,
  checkedCountries = []
) {
  return (
    countries?.map((c) => {
      const isChecked = checkedCountries.some((checked) => checked === c);
      return { title: c, value: c, checked: isChecked };
    }) ?? []
  );
}

export function findOrderIndex(orders, orderToFind) {
  return orders.findIndex((o) => {
    return o.ORDER_NO == orderToFind.ORDER_NO;
  });
}

/**
 * Manual RTFString parser using replace functions.
 * @param {String} rtf rtf string
 * @returns {String} unparsed string
 */
export function parseRTFString(rtf) {
  let text = rtf.replace(/\\par[d]?/g, ''); // Remove RTF paragraph control words
  text = text.replace(/\{\*?\\[^{}]+}/g, ''); // Remove RTF groups
  text = text.replace(/\\'([0-9a-f]{2})/gi, (_, hex) => {
    // Convert hexadecimal to Uint8Array
    const bytes = [];
    for (let i = 0; i < hex.length; i += 2) {
      bytes.push(parseInt(hex.substr(i, 2), 16));
    }
    const uint8Array = new Uint8Array(bytes);

    // Create TextDecoder for Windows-1250 encoding
    const textDecoder = new TextDecoder('windows-1250');

    // Decode the Uint8Array to string
    const decodedString = textDecoder.decode(uint8Array);

    return decodedString;
  }); // Replace RTF hex codes with the corresponding characters
  text = text.replace(/\\([a-z]{1,32})(-?\d{0,10})?[ ]?/gi, ''); // Remove RTF control words
  text = text.replace(/[\r\n]+$/, ''); // Trim trailing newline characters

  // Split text into different lines
  const lines = text.split('\n');
  if (lines[0].startsWith('{')) {
    lines.splice(0, 1); // Remove the first line
    lines.splice(lines.length - 1, 1); // Remove the last line
    text = lines.join('\n');
    text = text.replace(/[\r\n]+$/, ''); // Trim trailing newline characters
  }

  return text;
}

export function sortByProp(prop) {
  return function (a, b) {
    if (a[prop] == b[prop]) return 0;
    return a[prop] > b[prop] ? -1 : 1;
  };
}
