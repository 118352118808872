<template>
  <article class="ifs-order-line-list-item">
    <header
      class="ifs-order-line-list-item__header ol-item-header"
      :aria-expanded="isExpanded"
      @click="expandItem">
      <span class="ellipsis">{{ getCatalogDescription }}</span>
      <Dropdown class="dropdown-icon" direction="down" />
    </header>
    <main
      v-show="isExpanded"
      class="ifs-order-line-list-item__content ifs-item-content">
      <IFSOrderLineTable
        title="Factory"
        :is-empty="!getFactoryOrderLine"
        :sections="getFactoryOrderLineSections" />
      <IFSOrderLineTable
        title="Sales"
        :is-empty="!getSalesOrderLine"
        :sections="getSalesOrderLineSections" />
    </main>
  </article>
</template>
<script>
import IFSOrderLineTable from '@/components/Orders/OrdersSummary/IFSOrder/IFSOrderLineTable';
import Dropdown from '@/components/shared/Icons/Dropdown.vue';
import { transformOrderLineToSections } from '@/utils/ifs';

export default {
  name: 'IFSOrderLineItem',
  components: { Dropdown, IFSOrderLineTable },
  inject: ['isMobile'],
  props: {
    orderLine: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  computed: {
    getSalesOrderLine() {
      return this.orderLine?.SALES;
    },
    getFactoryOrderLine() {
      return this.orderLine?.FACTORY;
    },
    getCatalogDescription() {
      return (
        this.getSalesOrderLine?.CATALOG_DESC ||
        this.getFactoryOrderLine?.CATALOG_DESC
      );
    },
    getFactoryOrderLineSections() {
      return transformOrderLineToSections(this.getFactoryOrderLine);
    },
    getSalesOrderLineSections() {
      return transformOrderLineToSections(this.getSalesOrderLine);
    },
  },
  methods: {
    expandItem(override = !this.isExpanded) {
      if (this.isMobile) return;

      if (typeof override instanceof Boolean) this.isExpanded = override;
      else this.isExpanded = !this.isExpanded;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../../../../styles/main.scss';

.ifs-order-line-list-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding-block-end: 8px;

  &__header {
    height: 48px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    color: #1a1a1a;
    font-size: 20px;
    font-style: normal;
    font-weight: 450;
    line-height: 24px;
    border-radius: 8px;
    border: 1px solid #ececec;
    cursor: pointer;
  }

  &__header[aria-expanded='true'] {
    // Reset bottom radius
    border-block-end: unset;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    .dropdown-icon {
      rotate: 180deg;
    }
  }
}

.ol-item-header {
  overflow: hidden;
}

.ifs-item-content {
  display: flex;
  gap: 16px;
  width: 100%;
  padding-block-start: 6px;
  padding-inline: 16px;
  padding: 6px 16px 16px 16px;
  border-radius: 8px;
  border: 1px solid #ececec;

  // Reset top radius
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-block-start: unset;
}

@media screen and (max-width: $bp-sm) {
  .ifs-order-line-list-item {
    background-color: white;
    border-radius: 8px;
    height: 54px;
    padding-block-end: 0;

    &__header {
      padding: 8px 12px 8px 16px;
      border: unset;
      height: 100%;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .dropdown-icon {
    rotate: -90deg;
  }
}
</style>
