<template>
  <div class="snack-bar-stack">
    <TransitionGroup name="fade" appear>
      <section v-for="text in messages" :key="text">
        <div class="snack-bar">
          {{ text }}
        </div>
      </section>
    </TransitionGroup>
  </div>
</template>
<script>
export default {
  name: 'SnackBar',
  data() {
    return {
      messages: [],
    };
  },
  methods: {
    showMessage(message) {
      this.messages.unshift(message);

      setTimeout(() => {
        this.messages.pop();
      }, 3000);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../../../styles/variables';

.snack-bar-stack {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column-reverse;
  gap: 1rem;
  z-index: 99999;
}

.snack-bar {
  display: grid;
  place-items: center;
  min-width: 22rem;
  padding: 1rem 3rem;
  background-color: rgba($color: $dark-grey, $alpha: 0.9);
  border-radius: $border-radius-sm;
  color: $bg-light;
  filter: $drop-shadow-lg;
  z-index: 99999;

  &:hover {
    background-color: rgba($color: $dark-grey, $alpha: 1);
  }
}

@media (max-width: $bp-md) {
  .snack-bar {
    padding: 1rem;
    font-size: 0.75rem;
    flex-wrap: nowrap;
    height: fit-content;
  }
}
</style>
