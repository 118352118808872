<template>
  <mm-modal
    ref="customerContractsModal"
    class="customer-contracts-modal"
    header-text="Contracts"
    max-width="358px"
    prevent-body-scroll
    no-footer
    :show-close-icon="false"
    @cancel-clicked="$emit('closeCustomerContractsModal')">
    <div slot="content" class="customer-contrats-modal__wrapper modal-wrapper">
      <article class="modal-wrapper__content modal-content">
        <div
          v-for="contract of contracts"
          :key="contract"
          class="modal-content__contract">
          {{ contract }}
        </div>
      </article>
      <mymunters-button
        class="modal-wrapper__footer modal-footer ml-auto"
        button-type="outline"
        @click="close">
        Close
      </mymunters-button>
    </div>
  </mm-modal>
</template>
<script>
export default {
  name: 'CustomerContractsModal',
  emits: ['closeCustomerContractsModal'],
  data() {
    return {
      contracts: [],
    };
  },
  methods: {
    open(contracts) {
      this.contracts = contracts;
      this.$refs.customerContractsModal.open();
    },
    close() {
      this.$refs.customerContractsModal.close();
    },
  },
};
</script>
<style lang="scss" scoped>
.customer-contracts-modal {
  &::part(header) {
    padding: 1rem 1rem 0 1rem;
    border-bottom: 0;
  }

  &::part(content) {
    padding-block: 0;
  }
}

.modal-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-block: 1rem;
}

.modal-content {
  max-height: 160px;
  overflow: auto;

  &__contract:nth-child(odd) {
    background-color: #f8f8f8;
  }

  &__contract {
    display: flex;
    align-items: center;
    height: 32px;
  }
}

.modal-footer {
  display: flex;
  place-content: center;
}
</style>
