/* eslint-disable no-unused-vars */
export default {
  mounted: function (el, binding) {
    const threshold = {
      x: binding.value?.x || 0.5,
      y: binding.value?.y || 0.5,
    };
    const handler = () => {
      const posOffset = getPositionOffset(el);
      const { top, bottom, left, right, transform } = menuPositionStyle(
        posOffset,
        threshold
      );
      el.style.top = top;
      el.style.bottom = bottom;
      el.style.left = left;
      el.style.right = right;
      el.style.transform = transform;
    };
    handler();
    el.__smartPosHandler__ = handler;
    document.body.addEventListener('scroll', el.__smartPosHandler__);
  },
  unmounted: function (el) {
    document.body.removeEventListener('scroll', el.__smartPosHandler__);
  },
};

function getPositionOffset(e) {
  const { top, left } = e.getBoundingClientRect();
  return {
    y: top <= 0 ? 0 : Math.round((top / window.innerHeight) * 100),
    x: left <= 0 ? 0 : Math.round((left / window.innerWidth) * 100),
  };
}

function menuPositionStyle(posOffset, { x: hThreshold, y: vThreshold }) {
  let positionStyling = {};
  const verticalThreshold = vThreshold * 100;
  const horizontalThreshold = hThreshold * 100;

  if (window.matchMedia('(max-width: 816px)').matches) {
    return {
      bottom: '0',
    };
  }

  // Calculate Y-Position
  if (posOffset.y > verticalThreshold) {
    positionStyling = {
      transform: `translateY(-100%)`,
      top: '-8px',
      bottom: 'unset',
    };
  } else {
    positionStyling = {
      bottom: 'unset',
      top: 'calc(100% + 8px)',
      transform: 'unset',
    };
  }

  // Calculate X-Position
  if (posOffset.x > horizontalThreshold) {
    positionStyling = {
      ...positionStyling,
      right: '0',
      left: 'unset',
    };
  } else {
    positionStyling = {
      ...positionStyling,
      left: '0',
      right: 'unset',
    };
  }

  return positionStyling;
}
