import { fetchJSON } from '.';
import { clearEmptyProperties, isEmptyObject } from '../utils/utils';

export async function getProducts(params) {
  const url = new URL(process.env.VUE_APP_API_URL + '/order-items');
  if (params) {
    params = clearEmptyProperties(params);
    if (!isEmptyObject(params)) url.search = new URLSearchParams(params);
  }

  try {
    const result = await fetchJSON(url, 'get');
    return result;
  } catch (ex) {
    console.error(ex.message);
  }

  return { orders: [], hits: {} };
}
